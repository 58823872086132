import { useComponent, useElement } from '../../../hooks'
import { useTaxClassList } from './hooks'

export const TaxClassList = () => {
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedTaxClasse,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    } = useTaxClassList()

    return <div id="content-box" className="content">
        <h1 className="title flex mb50">
            {
                trashAction ? 'Trashed' : 'All'
            } TaxClasses
            {
                !trashAction && <>
                <Link className="btn btn-primary btn-small ml20" href="/admin/taxClass/add">Add</Link>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button onClick={() => {
                            setisMultipleDelete(true)
                            setshowTrashConfirm(true)
                        }} className="btn btn-outline btn-small">Delete</Button>
                    }
                </>
            }
        </h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():deleteSelectedTaxClasse()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
