import { useContext, useEffect, useState } from "react"
import { config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useNotifications = () => {
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const {
        fetchAll, remove
    } = useService('Data', 'notification')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        getFormattedTime,
    } = useService('Misc')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState('')

    useEffect(() => {
        setTitle('Notifications', 'admin')
        getNotificationsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getNotificationsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            settableData(tableData)
            setfilteredList(tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No notification added yet!' })
        } else showAlert({ type: 'error', msg: 'No notification added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectNotification = () => {
        deleteNotifications(activeItem.uid)
    }
    
    const deleteNotifications = async uid => {
        const result = await remove({ uid })
        if (result.status) {
            getNotificationsData()
            showAlert({ type: 'success', msg: 'Notification deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete notification!' })
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            deleteNotifications(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                title: item.title,
                body: item.body,
                timing: getFormattedTime(item.timing),
                publishedDate: getFormattedTime(item.timing),
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.content.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Body',
                    key: 'body'
                },
                {
                    label: 'Time',
                    key: 'time'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectNotification,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}