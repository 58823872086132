import { useElement } from '../../../hooks'
import { useNotification } from './hooks'
import './style.scss'
export const Notifications = () => {
    const Img = useElement('Img')
    const {
        notificationList,
    } = useNotification()
    const renderNotificationsList = () => {
        return notificationList.map(( item, index ) => {
            return <div key={index} className="form-box">
                <div className="notification">
                    <div className="notification-image-box">
                        <Img src={JSON.parse(item.image)} alt={item.title} />
                    </div>
                    <div className="notification-content">
                        <h1>{item.title}</h1>
                        <p>{item.body}</p>
                    </div>
                </div>
            </div>
        })
    }

    return <div className="right-side-profile-content">
        <h1>Notifications</h1>
        <div className="profile-sec wrapper notification">
            <div className="form-editable-section">
                {renderNotificationsList()}
            </div>
        </div>
    </div>
}