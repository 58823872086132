export const RecommendationModel = [
    {
        key: 'carDetails',
        dataType: Object
    },
    {
        key: 'comments',
        dataType: String
    },
    {
        key: 'carImages',
        dataType: Array
    },
    {
        key: 'recommendData',
        dataType: Array
    },
    {
        key: 'ownerId',
        dataType: Array
    },
    {
        key: 'uid',
        dataType: String
    }
]