import Select from 'react-select'
import { useJobsList } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const JobsList = () => {
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedJob,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    } = useJobsList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex mb50">
            {
                trashAction ? 'Trashed' : 'All'
            } Jobs
            {
                !trashAction &&
                <>
                <Link className="btn btn-primary btn-small ml20" href="/admin/job/add">Add</Link>
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button onClick={() => {
                        setisMultipleDelete(true)
                        setshowTrashConfirm(true)
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
                </>
            }
        </h1>
        <div className="filter-box">
            <div className="filters relative">
            <label className="filter-label-add">Status: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i)
                    }}
                    options={filterTags}
                />
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():deleteSelectedJob()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
