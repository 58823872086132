export const GarageModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'slug',
        dataType: String
    },
    {
        key: 'businessinformation',
        dataType: String
    },
    {
        key: 'css',
        dataType: String
    },
    {
        key: 'js',
        dataType: String
    },
    {
        key: 'canonical',
        dataType: String
    },
    {
        key: 'robots',
        dataType: String
    },
    {
        key: 'metaDescription',
        dataType: String
    },
    {
        key: 'metaKeyword',
        dataType: String
    },
    // {
    //     key: 'author',
    //     dataType: String
    // },
    {
        key: 'yearEstablished',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'category',
        dataType: String
    },
    {
        key: 'tag',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'address',
        dataType: String
    },
    {
        key: 'phoneNumber',
        dataType: String
    },
    {
        key: 'emailaddress',
        dataType: String
    },
    {
        key: 'hoursofOperation',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'modeofpayment',
        dataType: String
    },
    {
        key: 'modeofpayment',
        dataType: String
    },
    {
        key: 'faq',
        dataType: String
    },
    {
        key: 'website',
        dataType: String
    }
]