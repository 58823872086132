import Select from 'react-select'
import { useComplaint } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const ComplaintAdmin = () => {
    const {getFormattedDate} = useService('Misc')
    const Button = useElement('Button')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        tableContent,
        activeShownTotal,
        trashComplaint,
        trashCancel,
        showTrashConfirm,
        closeComplaintModal,
        orderId,
        uid,
        name,
        number,
        email,
        complaintType,
        productName,
        message,
        activeItem,
        addNote,
        activeStatus,
        setstatus,
        complaintOptions,
        note,
        setnote,
    } = useComplaint()
    
    const renderNotes = complaintNotes => {
        return complaintNotes.map(( item, index ) => {
            return <li rel="102" className="note system-note"key={index}>
                <div className="note-content">
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">{item.status}</p>
            </li>
        })
    }
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex mb50">Complaints</h1>
        <div className="filter-box">
            <div className="filters relative">
            <label className="filter-label-add">Status: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i, tableData)
                    }}
                    options={filterTags}
                />
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashComplaint} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal complaint-modal" id="complaint-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={closeComplaintModal}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="complaint-modal-header">
                        <div className="modal-header">
                            <div className="heading">View Complaint</div>
                            <div className="form-group">
                                <label>Order Id</label>
                                <p>{orderId}</p>
                            </div>
                            <div className="form-group">
                                <label>Complaint Id</label>
                                <p>#{uid}</p>
                            </div>
                            <div className="form-group">
                                <label>Name</label>
                                <p>{name}</p>
                            </div>
                            <div className="form-group">
                                <label>Mobile Number</label>
                                <p>{number}</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <p>{email}</p>
                            </div>
                            <div className="form-group">
                                <label>Complaint Type</label>
                                <p>{complaintType}</p>
                            </div>
                            <div className="form-group">
                                <label>Product Name</label>
                                <p>{productName}</p>
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <p>{message}</p>
                            </div>
                            {
                                activeItem.complaintNotes &&
                                <Box title='Notes'>
                                    <ul className="notes">
                                        { renderNotes(activeItem.complaintNotes||[]) }
                                    </ul>
                                </Box>
                            }
                            <Box title='Add Notes'>
                                <form onSubmit={addNote}>
                                    <Select
                                        value={activeStatus}
                                        defaultValue={activeStatus}
                                        onChange={e => setstatus(e.value)}
                                        options={complaintOptions}
                                    />
                                    <textarea className="mv20" placeholder="Type the note here" required value={note} name="content" onChange={e => setnote(e.target.value)}></textarea>
                                    <Button type="submit" className="btn btn-primary">Add</Button>
                                </form>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
