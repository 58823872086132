import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useDashboard = () => {
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {getUserRecommendation} = useService('Order')
    const user = getLoggedInUserData()
    const [activeService, setactiveService] = useState(null)
    const [userData, setuserData] = useState(user)
    const [recommendData, setrecommendData] = useState([])

    useEffect(() => {
        setTitle('Dashboard')
        getRecommendation()
    }, [])

    const getRecommendation = async () => {
        let query = {
            uid: user.uid
        }
        const result = await getUserRecommendation(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setrecommendData(null)
            } else {
                setrecommendData(result.data)
            }
        }
    }
    return {
        activeService,
        user,
        userData,
        recommendData,
    }
}