import { useContext } from 'react'
import { NavigatorContext } from '../../../context'
import { useComponent, useElement, useService } from '../../../hooks'
import { useDashboard } from './hooks'

export const DashBoard = () => {
    const {getActiveSubscription} = useService('User')
    const {navigateTo} = useContext(NavigatorContext)
    const {
        isModuleEnabled,
        openModal,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Html = useElement('Html')
    const SubscriptionData = useComponent('SubscriptionData')
    const {
        activeService,
        user,
        userData,
        recommendData,
    } = useDashboard()
    
    const renderServiceList = serviceFiles => {
        return serviceFiles.map(( item, index ) => {
            if (item.type === 'service' || item.type === 'microService') {
                return item.status === 'publish' && <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                        </div>
                        <div className="title-box">
                            <div className="title flex">
                                <div className="title-item"><h1>{item.title}</h1></div>
                                {item.serviceTime && <div className="hrsTaken"><i className="hi-clock1"></i> Service Time<br />{item.serviceTime} Hrs</div>}
                            </div>
                            <div className="about">
                                <Html content={item.userNote} />
                            </div>
                            <Html className="points" content={item.excerpt} />
                            <div className="addToCartbtn-service-page">
                                <Button className="btn btn-primary mrauto" onClick= {() => {
                                    if (item.type === 'service') {
                                        navigateTo({route: '/service/'+item.slug})
                                    } else {
                                        navigateTo({route: '/microservice/'+item.slug})
                                    }
                                }}><i className="hi-eye"></i> Explore</Button>
                                {
                                    isModuleEnabled(['Subscription']) && getActiveSubscription() === null &&
                                    <p className="get-off" onClick={() => openModal("get-subscription-modal")}>Get 10% off <i className="hi-info"></i> </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            } else if (item.type === 'product') {
                return <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                        </div>
                        <div className="title-box">
                            <div className="title flex">
                                <div className="title-item"><h1>{item.title}</h1></div>
                            </div>
                            <div className="about">
                                <Html content={item.userNote} />
                            </div>
                            <div className="addToCartbtn-service-page">
                                <Button className="btn btn-primary" onClick= {() => navigateTo({route: '/product/'+item.slug})}><i className='hi-eye'></i> Explore</Button>
                            </div>
                        </div>
                    </div>
                </div>
            } else if (item.type === 'tag') {
                return <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <h2>
                                <span>
                                    {item.label}
                                </span>
                            </h2>
                        </div>
                        <div className="title-box">
                            <div className="addToCartbtn-service-page">
                                <Button className="btn btn-primary" onClick= {() => navigateTo({route: '/accessories/tag/'+item.value})}><i className='hi-eye'></i> Explore</Button>
                            </div>
                        </div>
                    </div>
                </div>
            } else return true
        })
    }

    return <>
        <div className="right-side-profile-content">
            <h1>Dashboard</h1>
            <div className="profile-sec wrapper">
                <div className="profile-name-section">
                    <div className="form-box">
                        <div className="profile-avatar">
                            <div className="profile-image">
                                <Img src={JSON.parse(user.image)[0]} alt={userData.name} />
                            </div>
                        </div>
                        <div className="name-sec">
                            <h4>{userData.name}</h4>
                            <p>{userData.email}</p>
                        </div>
                        {
                            getActiveSubscription() !== null &&
                            <div className="subscription-dashbord">
                                <p className="subs-heading">Active Subscription: </p>
                                <span className="pointer" onClick={() => openModal("get-subscription-modal")}>{getActiveSubscription()}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="form-editable-section">
                    <div className="form-box" id="recommended-service">
                        <div className="service">
                            { renderServiceList(recommendData || []) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal" id="get-subscription-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <SubscriptionData activeSubscription={getActiveSubscription()} /> 
                </div>
            </div>
        </div>
    </>
}
