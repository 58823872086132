import { useElement, useService } from '../../../hooks'
import { useAdminHeader } from './hooks'

export const AdminHeader = () => {
    const {cacheClean} = useService('Router')
    const {isUserLoggedIn} = useService('User')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const {
        toggleNav,
        toggleThemeMode,
        franchiseData,
        userProfileImage,
        user,
        toggleUserNav,
        showUserNav,
        logout,
    } = useAdminHeader()

    return <div className="header">
        <Button onClick={toggleNav} className="nav-btn" title="Toggle Nav">
            <i className="hi-nav"></i>
        </Button>
        <Button onClick={toggleThemeMode} className="theme-btn" title="Toggle Theme">
            <i className="hi-bulb"></i>
        </Button>
        <Button onClick={cacheClean} className="cache-btn" title="Clear cache & Reload">
            <i className="hi-refresh"></i>
        </Button>
        {franchiseData?.title && <i className='franchise-title'>{franchiseData?.title}</i>}
        <nav>
            { isUserLoggedIn() && <div className="profile-box" onClick={toggleUserNav}>
                {
                    userProfileImage ?
                    <i className="hi-user1"></i>:
                    <Img className="user-profile-picture" src={JSON.parse(user?.image)[0]} alt={user?.name} />
                }
                <span title={user?.name}>{user?.name}</span>
            </div>}
            {
                showUserNav &&
                <ul>
                    <li title="Profile">
                        <Link href="/admin/account">
                            <i className='hi-user1'></i>
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li title="Visit Site">
                        <Link href="/">
                            <i className='hi-network'></i>
                            <span>Visit Site</span>
                        </Link>
                    </li>
                    <li title="Logout">
                        <i onClick={logout}>
                            <i className='hi-logout'></i>
                            <span>Logout</span>
                        </i>
                    </li>
                </ul>
            }
        </nav>
    </div>
}