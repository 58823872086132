import { useElement } from "../../hooks"
import {Box} from "../Box"
import { useInputBox } from "./hooks"

export const InputBox = ({
    type = 'text',
    value = '',
    setvalue = '',
    title = '',
    key = '',
    multi = false,
    className = '',
    placeholder = '',
    required = false
}) => {
    const Input = useElement('Input')
    const { setInputValue } = useInputBox({setvalue})
    return <Box title={title} required>
        {
            type === 'text' ?
                multi ?
                    <textarea className={className} placeholder={placeholder} required={required} name="css" value={value} onChange={setInputValue}></textarea>
                    :<Input required name={key} value={value} onChange={setInputValue} />
            :<></>
        }
    </Box>
}
