import { useEffect, useState } from "react"
import { complaintTypeOption } from "../../../../constant"
import { useService } from "../../../../hooks"

export const useComplaint = () => {
    const {save} = useService('Data', 'complaint')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {isValidMobile} = useService('Validation')
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [orderId, setorderId] = useState('')
    const [number, setnumber] = useState('')
    const [productName, setproductName] = useState('')
    const [date, setdate] = useState('')
    const [uid, setuid] = useState('')
    const [complaintType, setcomplaintType] = useState('product')

    useEffect(() => {
        setTitle('Complaint')
    }, [])

    const renderComplaintTypes = () => {
        return complaintTypeOption.map((e, i) => {
            return <span onClick={() => setcomplaintType(e.value)}>
                {
                    complaintType === e.value ?
                    <i className="orange hi-check_circle"></i>:
                    <i className="hi-circle"></i>
                }
                <label>{e.label}</label>
            </span>
        })
    }

    const sendComplaintRequest = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = {
            name,
            email,
            message,
            orderId,
            number,
            productName,
            complaintType,
            date: new Date(),
            uid: generateUID(),
            status: 'raised'
        }
        if (name === '') {
            errorMsg = 'Please enter your Full Name'
            error = true
        } else if (email === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        } else if (number === '' ) {
            errorMsg = 'Please enter your Email Address'
            error = true
        } else if (!isValidMobile(number)) {
            errorMsg = 'Please enter a valid Mobile Number'
            error = true
        } else if (message.length < 50) {
            errorMsg = 'Message length should be greated than 50 letters.'
            error = true
        }
        if (!error) {
            const result = await save(userObject)
            if (result.status) {
                showAlert({
                    type: 'info',
                    msg: 'Your contact request has been submitted. We\'ll contact you back in 24 hours.' })
            setname('')    
            setemail('')   
            setmessage('')
            setorderId('')
            setproductName('')
            setnumber('')  
            setdate('')    
            setuid('')
            } else showAlert({ type: 'error', msg: 'Unable to send contact request.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        setmessage,
        message,
        setproductName,
        productName,
        renderComplaintTypes,
        setorderId,
        orderId,
        setnumber,
        number,
        email,
        setemail,
        name,
        setname,
        sendComplaintRequest,
    }
}