export const microServiceRoutes = [
    {
        "pathName": "/microservice",
        "module": ["Ecommerce"],
        "component": "MicroServices",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/microservice",
        "module": ["Ecommerce"],
        "component": "MicroServices",
        "type": "public",
        "mode": "dynamic"
    }
]