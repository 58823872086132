export const ChatModel = [
    {
        key: 'question',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'answer',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]