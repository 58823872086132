import { useEffect, useState } from "react"
import { useService } from "../../../hooks"
import { brandInfo } from "../../../constant"

export const useDetailingServices = () => {
    const {getService} = useService('Service')
    const [serviceList, setserviceList] = useState([])
    const [activeMedia, setactiveMedia] = useState(null)
    const [activeService, setactiveService] = useState(null)
    
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const getServicesData = async () => {
        const result = await getService({status: 'publish'})
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = result.data
                setserviceList(serviceList.reverse())
            }
        }
    }
    
    const viewServiceVideo = (item) => {
        setactiveService(item)
        openModal('media-modal')
        setactiveMedia(item.featuredVideo ?? brandInfo.defaultVideo)
    }

    useEffect(() => {
        getServicesData()
    }, [])

    return {
        serviceList,
        viewServiceVideo,
        activeMedia,
        closeModal,
        activeService,
    }
}