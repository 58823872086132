import { useElement, useService } from '../../hooks'
import { useHomeSlider } from './hooks'
import './style.scss'

export const HomeSlider = () => {
    const Img = useElement('Img')
    const Slider = useElement('Slider')
    const {isModuleEnabled} = useService('Misc')
    const {
        serviceSliderList,
        subscriptionData,
        banners,
    } = useHomeSlider()
    const setting = {
        rows: 1,
        swipeToSlide: true,
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        pauseOnHover:false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000
    }
    const renderBanners = banners => {
        return banners.map(( item, index ) => {
            return <Img className="box-banner" key={index} src={item} alt={item} />
        })
    }

    return <div className="home-slider slider-items mb60-mbs40">
        {
            serviceSliderList !== null && subscriptionData !== null &&
            <Slider {...setting}>
                {isModuleEnabled(['Car']) && <div className="view-top-counterup" tabIndex="0">
                    <div className="counterup-wrap">
                        <div className="spinlines-first">
                            <p className="name1">Car Wash</p>
                            <p className="name2">Deodorization</p>
                            <p className="name3">Sanitization</p>
                            <p className="name4">Jump Start</p>
                            <p className="name5">Insect Removing</p>
                            <p className="name6">Battery Care</p>
                            <p className="name7">Clean & Non toxic</p>
                            <p className="name8">Car Vacuuming</p>
                        </div>
                        <div className="spinlines-second">
                            <p className="name9">Wheel Care</p>
                            <p className="name10">Interior Dusting</p>
                            <p className="name11">Odour Cleaning</p>
                            <p className="name12">Bacteria Free</p>
                            <p className="name14">Fluid Check</p>
                            <p className="name15">Sanitization</p>
                            <p className="name16">Deep Car Cleaning</p>
                            <p className="name17">Battery Care</p>
                        </div>
                    </div>
                </div>}
                { renderBanners(banners||[]) }
            </Slider>
        }
    </div>
}
