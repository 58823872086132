export const FranchiseModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'companyName',
        dataType: String
    },
    {
        key: 'contactPerson',
        dataType: String
    },
    {
        key: 'owner',
        dataType: String
    },
    {
        key: 'mobile',
        dataType: String
    },
    {
        key: 'email',
        dataType: String
    },
    {
        key: 'address',
        dataType: String
    },
    {
        key: 'addressDetail',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'content',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'socialLinks',
        dataType: String
    },
    {
        key: 'slug',
        dataType: String
    },
    {
        key: 'status',
        dataType: Boolean
    }
]