export const homeRoutes = [
    {
        "pathName": "/home",
        "redirectTo": "/",
        "mode": "static"
    },
    {
        "pathName": "",
        "component": "Home",
        "type": "public",
        "mode": "static"
    }
]