import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext } from '../../../../context'

export const useLogList = () => {
    const Badge = useElement('Badge')
    const {remove} = useService('Data', 'event')
    const {fetchAll} = useService('Data', 'log')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        getFormattedDate,
        getFormattedTime,
    } = useService('Misc')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [searchKey, setsearchKey] = useState('')
    const [tableContent, settableContent] = useState({})
    const [filteredList, setfilteredList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [tableDataUsers, settableDataUsers] = useState([])
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Activity Logs', 'admin')
        getLogsData()
        getUsersData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getLogsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            settableData(tableData)
            setfilteredList(tableData)
        }
    }
    
    const getUsersData = async () => {
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else
                settableDataUsers(result.data)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashEvent = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getLogsData()
            showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getTableData = filterData => {
        let data = [], name= '', role= ''
        filterData.forEach(( item, index ) => {
            tableDataUsers.forEach(e => {
                if (e.uid === item.userId) {
                    name = e.name
                    role = e.role
                }
            })
            let e = {
                srNo: index+1,
                log: item.log,
                timing: <>{getFormattedDate(item.timing)+' '+getFormattedTime(item.timing)}</>,
                name,
                role,
                logType: <Badge item={item.type} />
            }
            data.push(e)
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'S.No.',
                    key: 'srNo'
                },
                {
                    label: 'Log Detail',
                    key: 'logDetails'
                },
                {
                    label: 'Time',
                    key: 'time'
                },
                {
                    label: 'User',
                    key: 'user'
                },
                {
                    label: 'Role',
                    key: 'role'
                },
                {
                    label: 'Log Type',
                    key: 'logType'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList])
    return {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
    }
}