import { useEffect, useState } from "react"
import {WOW} from 'wowjs'
import { useService } from "../../../hooks"

export const useHomeSliderProduct = () => {
    const {getProducts} = useService('Product')
    const [productSliderList, setproductSliderList] = useState([])
    
    const getProductsData = async () => {
        const result = await getProducts()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(( e, i ) => {
                if (typeof e.trash === "undefined" && e.isFeatured && i<10) {
                    tableData.push(e)
                } else if (!e.trash && i<10) {
                    tableData.push(e)
                }
            })
            setproductSliderList(tableData)
        }
    }
    
    useEffect(() => {
        getProductsData()
        const wow = new WOW({
            offset: 100,
            mobile: false,
            live: true
        })
        wow.init()
    }, [])
    return {
        productSliderList,
    }
}