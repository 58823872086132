import { useElement, useService } from '../../hooks'
import { useProductBox } from "./hooks"
export const ProductBox = ({
    product
}) => {
    const {
        getLoggedInUserData,
        isUserLoggedIn,
    } = useService('User')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Rating = useElement('Rating')
    const Button = useElement('Button')
    const {
        toIndianCurrency,
        isEmpty,
    } = useService('Misc')
    const {
        openProductPage,
        addToCart,
    } = useProductBox()
    const user = getLoggedInUserData()
    return <div className="grid-list-column-item">
        {
            product.isAssured && <Img className="assuredImg" src={'assured.png'} alt={product.title} />
        }
        <div className="thumb">
            {
                isEmpty(product.image)?
                <Img src={'product-placeholder.png'} alt={product.title} />:
                <Img className="pointer" onClick={openProductPage} src={JSON.parse(product.image)[0]} alt={product.title} />
            }
        </div>
        <h5 className="title"><Link title={product.title} href={"/product/"+product.slug}>{product.title}</Link></h5>
        {
            product.averageRating && product.feedback ? <div className="common-rating-style">
                <Rating rating={product.averageRating} />
            </div>: ''
        }
        {
            isEmpty(product.saleprice) ?
            <div className="mrp">
                <span className="black">{toIndianCurrency(product.price)}</span>
            </div>:
            <div className="flex">
                <div className="mrp mrauto">
                    <span className="black">{toIndianCurrency(product.saleprice)}</span>
                </div>
                <div className="sale-price mlauto">
                    <span className="black">{toIndianCurrency(product.price)}</span>
                </div>
                <div className="per-off">{(( product.price - product.saleprice)/product.price * 100).toFixed(0)} % Off
                </div>
            </div>
        }
        {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <div className="main-btn-wrap pt20">
            {
                isEmpty(product.stock)?
                <Button disabled className="btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                <Button onClick={addToCart} className="btn btn-primary btn-block btn-small">Add to Cart<i className='hi-cart'></i></Button>
            }
        </div>}
    </div>
}