import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddMicroService = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchAll} = useService('Data', 'taxClass')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        isEmpty,
        generateUID,
        slugify,
    } = useService('Misc')
    const {
        getMicroService,
        addMicroService,
        updateMicroService,
        getMicroServiceCategory,
    } = useService('MicroService')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {getVehicleClassList} = useService('Car')
    const keywordOptions =  [],
    tagOptions = []
    const [companies, setcompanies] = useState([])
    const [company, setcompany] = useState(null)
    const [activeCar, setactiveCar] = useState(null)
    const [activePrice, setactivePrice] = useState('')
    const [saleprice, setsaleprice] = useState('')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(2)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [prices, setprices] = useState('[]')
    const [image, setimage] = useState('[]')
    const [icon, seticon] = useState('[]')
    const [categoryOptions, setcategoryOptions] = useState([])
    const [showCarListBox, setshowCarListBox] = useState(false)
    const [vehicleClassList, setvehicleClassList] = useState([])
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [author, setauthor] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState('')
    const [category, setcategory] = useState('')
    const [sku, setsku] = useState('')
    const [hsn, sethsn] = useState('')
    const [tag, settag] = useState('')
    const [tax, settax] = useState('18')
    const [userNote, setuserNote] = useState('')
    const [serviceTime, setserviceTime] = useState('')
    const [isFeatured, setisFeatured] = useState(false)
    const [isAutoApproved, setisAutoApproved] = useState(false)
    const [selectedCarList, setselectedCarList] = useState(true)
    const [inclusiveTax, setinclusiveTax] = useState(false)
    const [taxClassData, settaxClassData] = useState([])
    const [fuelPrices, setfuelPrices] = useState({
        cng: '',
        petrol: '',
        diesel: ''
    })

    const getTaxClassesListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            settaxClassData(taxClassData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No taxClass added yet!' })
        } else showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    
    const getVehicleClasses = async () => {
        const result = await getVehicleClassList()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No car manufacturers added yet!' })
            else
                setvehicleClassList(result.data)
        }
    }
    
    const getUsersData = async () => {
        let companies = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                setcompanies(companies)
            }
        }
    }
    
    const getServiceData = async (query) => {
        const result = await getMicroService(query)
        if (result.status) {
            const microServiceData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === microServiceData.robots))
            setactiveAuthorIndex(authorOption.findIndex(e => e.value === microServiceData.author))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === microServiceData.status))
            setmetaKeyword(isEmpty(microServiceData.metaKeyword) ? [] : microServiceData.metaKeyword)
            settag(isEmpty(microServiceData.tag) ? [] : microServiceData.tag)
            setcategory(isEmpty(microServiceData.category) ? [] : microServiceData.category)
            settitle(microServiceData.title)
            setmode('Edit')
            setslug(microServiceData.slug)
            setcontent(microServiceData.content)
            setexcerpt(microServiceData.excerpt)
            setcss(microServiceData.css)
            setjs(microServiceData.js)
            setcanonical(microServiceData.canonical)
            setrobots(microServiceData.robots)
            setmetaDescription(microServiceData.metaDescription)
            setauthor(microServiceData.author)
            setpublishDate(new Date(microServiceData.publishDate))
            setstatus(microServiceData.status)
            setserviceTime(microServiceData.serviceTime)
            setsku((microServiceData.sku)?microServiceData.sku: '')
            sethsn((microServiceData.hsn)?microServiceData.hsn: '')
            settax((microServiceData.tax)?microServiceData.tax: '')
            setisFeatured(microServiceData.isFeatured)
            setisAutoApproved(microServiceData.isAutoApproved ? microServiceData.isAutoApproved : false)
            setinclusiveTax(microServiceData.inclusiveTax)
            setuid(microServiceData.uid)
            setuserNote(microServiceData.userNote)
            setimage(microServiceData.image)
            seticon(microServiceData.icon ? microServiceData.icon : '[]')
            setprices(JSON.parse(microServiceData.prices))
            if (microServiceData.sku.includes("fuel-refill") && microServiceData.fuelPrices)
                setfuelPrices(JSON.parse(microServiceData.fuelPrices))
            if (microServiceData.status === 'private' && microServiceData.company)
                setcompany(JSON.parse(microServiceData.company))
        } else showAlert({ type: 'error', msg: 'No service added yet!' })
    }
    
    const addService = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        microserviceObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            sku,
            hsn,
            tax,
            userNote,
            serviceTime,
            isFeatured,
            isAutoApproved,
            inclusiveTax,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            prices: JSON.stringify(prices),
            image,
            icon
        }
        if (sku.includes("fuel-refill") && fuelPrices) {
            microserviceObject.fuelPrices = JSON.stringify(fuelPrices)
        }
        if (status === 'private' && company) {
            microserviceObject.company = JSON.stringify(company)
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (icon === '') {
            errorMsg = 'Please select icon'
            error = true
        } else if (tax === '') {
            errorMsg = 'Please enter the tax'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                microserviceObject.uid = generateUID()
                const result = await addMicroService(microserviceObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Micro-service added successfully!' })
                    navigateTo({route: '/admin/microservice/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add microService!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    microServiceData: JSON.stringify(microserviceObject)
                }
                const result = await updateMicroService(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Micro-service updated successfully!' })
                    navigateTo({route: '/admin/microservice/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update microService!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    const removePrice = item => {
        let newPrices = [],
        derivedPrices = JSON.parse(prices)
        derivedPrices.forEach(e => {
            if (e.uid !== item.uid)
                newPrices.push(e)
        })
        setprices(JSON.stringify(newPrices))
    }
    
    const getServiceCategoryData = async () => {
        let tableData = []
        const result = await getMicroServiceCategory()
        if (result.data?.length !== 0) {
            result.data.forEach(e => tableData.push({label: e.catName, value: e.catSlug}))
            setcategoryOptions(tableData)
        }
    }
    
    const showCarList = () => {
        setshowCarListBox(!showCarListBox)
    }
    
    const urlData = urlToSplitData(getLocationData().pathname)
    
    useEffect(() => {
        setTitle('Add Micro Service', 'admin')
        getServiceCategoryData()
        getTaxClassesListData()
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getServiceData({uid: urlData[4]})
        }
        getVehicleClasses()
        getUsersData()
    }, [])

    const addTitle = e => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)
    return {
        activeCar,
        companies,
        tag,
        settag,
        tagOptions,
        isAutoApproved,
        setstatus,
        status,
        activePrice,
        setactiveCar,
        setselectedCarList,
        setshowCarListBox,
        removePrice,
        mode,
        prices,
        activeRobotsIndex,
        activeStatusIndex,
        robots,
        canonical,
        setcanonical,
        urlData,
        slug,
        taxClassData,
        tax,
        addService,
        title,
        addTitle,
        setslug,
        sku,
        setsku,
        hsn,
        sethsn,
        content,
        setcontent,
        userNote,
        setuserNote,
        excerpt,
        setexcerpt,
        showCarList,
        showCarListBox,
        vehicleClassList,
        setactivePrice,
        saleprice,
        setsaleprice,
        setprices,
        fuelPrices,
        setfuelPrices,
        css,
        setcss,
        js,
        setjs,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        settax,
        serviceTime,
        setserviceTime,
        setpublishDate,
        publishDate,
        setinclusiveTax,
        inclusiveTax,
        setisFeatured,
        isFeatured,
        setisAutoApproved,
        company,
        setcompany,
        category,
        setcategory,
        categoryOptions,
        image,
        seticon,
    }
}