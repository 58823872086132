import { useWidget } from "../../hooks"

export const Public = component => {
    const Header = useWidget('Header')
    const Footer = useWidget('Footer')
    return <>
        <Header />
        {component}
        <Footer />
    </>
}