import { brandInfo } from '../../constant'
import { useElement } from '../../hooks'
import { useFranchiseCTA } from './hooks'
import './style.scss'

export const FranchiseCTA = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    const {
        franchiseTiles,
        activeFranchise,
        setactiveFranchise,
        navigateToStoreLocator,
    } = useFranchiseCTA()
    const renderFranchiseCtaTiles = () => {
        return franchiseTiles.slice(1).map((e, i) => {
            return <li key={i}>
                <Img src={e} onClick={() => setactiveFranchise(e)} />
                {i === franchiseTiles.length - 2 && <span onClick={navigateToStoreLocator}>view all</span>}
            </li>
        })
    }
    return <section className="franchise-cta-section mb60-mbs40">
        <div className="wrapper jumbo-container">
            <div className='franchise-cta-slider'>
                <ul className='franchise-cta-tiles'>
                    {renderFranchiseCtaTiles()}
                </ul>
                <div className='franchise-cta-frame'>
                    <Img src={activeFranchise} />
                </div>
            </div>
            <div className='franchise-cta-content'>
                <div className='logo-block'>
                    <Img className='franchise-logo' src="logo-black.png" title={brandInfo.name.uppercase} alt={brandInfo.name.uppercase} />
                    <span>Franchise</span>
                </div>
                <h2 className='head'>Best car detailing stores in India!</h2>
                <p>Are you someone who has a great passion for car detailing? Do you want to find a platform that can help you take your career to new heights? If you answered yes, then CarMitra is the perfect choice for you.</p>
                <p className='mb30'><b>Join CarMitra Franchise</b> now and become an entrepreneur in the automotive detailing industry.</p>
                <Link className='btn btn-primary btn-big' href="/franchise-with-us" title='Become Partner'>Apply</Link>
            </div>
        </div>
    </section>
}