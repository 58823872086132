export const franchiseRoutes = [
    {
        "pathName": "/franchise-with-us",
        "module": ["Franchise"],
        "component": "FranchiseWithUs",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/franchise-locator",
        "module": ["Franchise"],
        "component": "FranchiseLocator",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/franchise",
        "module": ["Franchise"],
        "component": "Franchise",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/store-locator",
        "module": ["Franchise"],
        "component": "StoreLocator",
        "type": "public",
        "mode": "static"
    }
]