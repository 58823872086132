import {brandInfo, mainUrl} from '../../constant'
import { useEvent } from './hooks'
import { useComponent, useElement, useService } from '../../hooks'
import './style.scss'

export const Event = () => {
    const {getFormattedDate} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const BlogSideBar = useComponent('BlogSideBar')
    const Share = useComponent('Share')
    const {
        category,
        title,
        publishDate,
        image,
        content,
        slug,
    } = useEvent()
    
    const renderImages = () => {
        return image?.slice(1).map(( item, index ) => {
            return <div className="img-item" key={index}>
                <Img hasZoom src={item} alt={title} />
            </div>
        })
    }

    let categoryList = ''
    category.forEach(e => {
        categoryList += e.label+', '
    })
    return <div className="blog-page event-page jumbo-container">
        <div className="blog-page-content pt120 pb100 mt40">
            <div className="wrapper">
                <div className="col-8">
                    <div className="blog-details-wrap">
                        <div className="blog-details-items">
                            <div className="content bg-none">
                                <h3 className="title">{title}</h3>
                                <div className="post-meta border-none pt20">
                                    <ul className="list-wrap">
                                        <li>
                                            <p className="author"><i className="hi-user1"></i>{brandInfo.name.capital}</p>
                                        </li>
                                        <li>
                                            <p className="author"><i className="hi-calendar1"></i> {getFormattedDate(publishDate)}</p>
                                        </li>
                                        <li>
                                            <p className="author"><i className="hi-folder1"></i>{categoryList}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="thumb mt20">
                                <Img src={image[0]} alt={title} />
                            </div>
                            <h2 className='mt40'>Gallery</h2>
                            <div className="gallery-items">
                                {renderImages()}
                            </div>
                            <div className="blog-details-inner">
                                <Html content={content} />
                            </div>
                        </div>
                        <Share shareUrl={mainUrl+"/event/"+slug} />
                    </div>
                </div>
                <div className="col-4">
                    <BlogSideBar />
                </div>
            </div>
        </div>
    </div>
}
