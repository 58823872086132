import {brandInfo} from '../../../constant'
import { useComponent, useService } from '../../../hooks'

export const Home = () => {
    const {setTitle} = useService('Meta')
    const {isModuleEnabled} = useService('Misc')
    const PremiumServices = useComponent('PremiumServices')
    const DetailingServices = useComponent('DetailingServices')
    const FeaturedProduct = useComponent('FeaturedProduct')
    const RecentServices = useComponent('RecentServices')
    const FormSlider = useComponent('FormSlider')
    const TestimonialWeb = useComponent('TestimonialWeb')
    const WelcomeTo = useComponent('WelcomeTo')
    const MobileApp = useComponent('MobileApp')
    const FranchiseCTA = useComponent('FranchiseCTA')
    const RecentPosts = useComponent('RecentPosts')
    const OurWorkingProcess = useComponent('OurWorkingProcess')
    const SecureSection = useComponent('SecureSection')

    setTitle(brandInfo.name.capital, 'home')

    return <div className="main-section">
        <FormSlider />
        {isModuleEnabled(['RecentServices']) && <RecentServices />}
        {isModuleEnabled(['PremiumServices']) && <PremiumServices />}
        {isModuleEnabled(['DetailingServices']) && <DetailingServices />}
        {isModuleEnabled(['Welcome']) && <WelcomeTo />}
        {isModuleEnabled(["Franchise"]) && <FranchiseCTA />}
        {isModuleEnabled(["WorkingProcess"]) && <OurWorkingProcess />}
        {isModuleEnabled(["Car"]) && <SecureSection />}
        {isModuleEnabled(["FeaturedProducts"]) && <FeaturedProduct />}
        <TestimonialWeb />
        {isModuleEnabled(["MobileApp"]) && <MobileApp />}
        {isModuleEnabled(["RecentPosts"]) && <RecentPosts />}
    </div>
}
