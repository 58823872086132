import {brandInfo, mainUrl} from '../../constant'
import { useProject } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../hooks'

export const Project = () => {
    const {getFormattedDate} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Banner = useWidget('Banner')
    const Share = useComponent('Share')
    const {
        title,
        releaseDate,
        image,
        slug,
        description,
    } = useProject()

    return <>
        <Banner title={title} image="white-car-banner.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt120 pb100">
                <div className="blog-details-wrap">
                    <div className="blog-details-items">
                        <div className="content bg-none">
                            <h3 className="title">{title}</h3>
                            <div className="project-meta border-none pt20">
                                <ul className="list-wrap">
                                    <li>
                                        <p className="author"><i className="hi-user1"></i>{brandInfo.name.capital}</p>
                                    </li>
                                    <li>
                                        <p className="author"><i className="hi-calendar1"></i> {getFormattedDate(releaseDate)}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="thumb mt20">
                            <Img src={JSON.parse(image)[0]} alt={title} />
                        </div>
                        <div className="blog-details-inner">
                            <Html content={description} />
                        </div>
                    </div>
                    <Share shareUrl={mainUrl+"/project/"+slug} />
                </div>
            </div>
        </div>
    </>
}
