import { useElement, useService } from '../../hooks'
import {Modal} from '../Modal'
import { useCarSelector } from './hooks'

export const CarSelector = ({
    activeCar,
    getNewCars,
    getSelectedCar,
}) => {
    const {isUserLoggedIn} = useService('User')
    const {isEmpty} = useService('Misc')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const {
        openCarList,
        showSelectedCar,
        deselectCar,
        search,
        hideCarSelectModal,
        hideCarListModal,
        selectCar,
        carSeleted,
        selectedCar,
        userCarList,
        carList,
        showCarSelectModal,
        searchCarKey,
        filteredManufacturerList,
        showCarListModal,
    } = useCarSelector({
        activeCar,
        getNewCars,
        getSelectedCar,
    })

    const renderCarManufactureList = carManufactureFiles => {
        return carManufactureFiles.map(( item, index ) => {
            return <li className="car-manufacturer-list" onClick={() => openCarList(item.name)} key={index}>
                <Img source='carCDN' src={item.image} alt={item.name} />
                <span>{item.name}</span>
            </li>
        })
    }
    
    const renderCarModelList = carModelList => {
        return carModelList.map(( item, index ) => {
            return <li className="car-manufacturer-list" onClick={() => {
                showSelectedCar(item, 'new')
                hideCarListModal()
            }} key={index}>
                <Img source='carCDN' src={item.image_path} alt={item.name} />
                <span>{item.name+' '+item.Segment}</span>
            </li>
        })
    }
    
    const renderCarListData = carModelList => {
        return carModelList.map(( item, index ) => {
            if (!isEmpty(item.carData)) {
                let carData = JSON.parse(item.carData)
                carData.registrationNo = item.registrationNo.toUpperCase()
                return <li className="car-manufacturer-list" onClick={() => showSelectedCar(carData)} key={index}>
                    <Img source='carCDN' src={carData.image_path} alt={carData.name} />
                    <span>{carData.name+' '+carData.Segment}<br /><small>{carData.registrationNo}</small></span>
                </li>
            } else return true
        })
    }

    return <>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            {
                carSeleted && selectedCar ?
                <li className="nav-item">
                    <span className="nav-link active selectedCar">
                        <Img source='carCDN' src={selectedCar.image_path} alt={selectedCar.name+' '+selectedCar.Segment} />
                        <span>{selectedCar.name+' '+selectedCar.Segment}</span>
                    </span>
                    <i title="Change Car" className="hi-refresh refresh-icon" onClick={deselectCar}></i>
                </li>:
                <li className="nav-item" onClick={selectCar} title="Select Car">
                    <span className="nav-link active selectCar">
                        <i className="hi-directions_car"></i>
                        <span>Select Your Car</span>
                    </span>
                </li>
            }
        </ul>
        <Modal id="car-select-modal" showCancel={false} showSuccess={false} onCancel={hideCarSelectModal} showModal={showCarSelectModal}>
            <div className="car-select-header">
                <div className="modal-header">
                    <div className="car-select-manufacture">
                        <i className="hi-directions_car"></i>
                        <span className="store-name">Select Manufactures</span>
                    </div>
                </div>
                <div className="down-arrow"></div>
            </div>
            <div className="select-manufacturer">
                <div className="search-form">
                    <div className="form-group">
                        <Input required name="searchCarKey" value={searchCarKey || ''} placeholder="Search Manufactures" onChange={search} className="input" />
                    </div>
                </div>
                <ul>
                    {renderCarManufactureList(filteredManufacturerList || [] )}
                </ul>
            </div>
        </Modal>
        <Modal id="car-list-modal" showCancel={false} showSuccess={false} onCancel={hideCarListModal} showModal={showCarListModal}>
            <div className="car-select-header">
                <div className="modal-header">
                    <div className="car-select-manufacture">
                        <i className="hi-directions_car"></i>
                        <span className="store-name">Select Car</span>
                    </div>
                </div>
                <div className="down-arrow"></div>
            </div>
            <div className="select-manufacturer">
                <ul>
                    {
                        isUserLoggedIn() && !getNewCars && userCarList.length!== 0?
                        renderCarListData(userCarList || []):
                        renderCarModelList(carList || [])
                    }
                </ul>
            </div>
        </Modal>
    </>
}
