import Select from 'react-select'
import { useFaq } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AdminFAQ = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashFaq,
        trashCancel,
        showTrashConfirm,
        setuid,
        mode,
        addFaq,
        query,
        setquery,
        category,
        setcategory,
        faqCats,
        ans,
        setans,
        closeModal,
        openModal,
    } = useFaq()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">FAQ <Button onClick={() => {
            setmode('Add')
            openModal("faq-modal")
        }} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashFaq} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal faq-modal" id="faq-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                    setquery('')
                    setans('')
                    setcategory('')
                    setuid('')
                    closeModal("faq-modal")
                }}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="faq-modal-header p20">
                        <div className="heading">{mode} Query</div>
                        <form noValidate onSubmit={addFaq}> 
                            <div className="form-group mb20">
                                <Input className="input" required name="query" value={query} onChange={e => setquery(e.target.value) } />
                                <label>Query</label>
                                <small className="msg text-danger">Please enter a Query</small>
                            </div>
                            <div className="form-group mb20">
                                <Select
                                    value={category}
                                    defaultValue={category}
                                    onChange={category => setcategory(category)}
                                    options={faqCats}
                                />
                                <label>Category</label>
                                <small className="msg text-danger">Please enter a Query</small>
                            </div>
                            <div className="form-group">
                                <textarea className="" placeholder="Type the notification here" required name="ans" value={ans} onChange={e => setans(e.target.value)}></textarea>
                                <label>Answer</label>
                                <small className="msg text-danger">Please enter related Answer</small>
                            </div>
                            <div className="buttons">
                                <Button type="submit" title="Click here to Add Faq" className="btn btn-primary btn-small">Submit</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
