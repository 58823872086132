import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { usageOption, base } from '../../../constant'
import { useMedia } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const Media = () => {
    const {
        hasImageExtension,
        closeModal,
        getFormattedDate,
    } = useService('Misc')
    const Button = useElement('Button')
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const {
        activeShownTotal,
        setactiveItem,
        settingOpen,
        trackScrolling,
        trashAction,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        setviewMode,
        searchKey,
        search,
        resetSearch,
        selectFiles,
        fileArray,
        uploadFiles,
        viewMode,
        filteredList,
        tableContent,
        activeImage,
        setactiveImage,
        activeUsage,
        setMediaUsage,
        trash,
        updateMedia,
    } = useMedia()

    const renderImagesGrid = imageFiles => {
        return imageFiles.slice(0, activeShownTotal).map(( item, index ) => {
            return <li key={index}>
                {
                    hasImageExtension(item.fileName) || item.type === "image/webp" || item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png" || item.type === "image/x-png" || item.type === "image/gif" || typeof item.type=== 'undefined' || item.type === '' ?
                    <Img alt={item.fileName} src={item.fileName} hasZoom />:
                    <video className="video-container video-container-overlay" autoPlay={false} loop>
                        <source src={base.imageBaseUrl+item.fileName} type={item.type} />
                    </video>
                }
                {
                    trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                        }} />
                        <Actor type='undo' onClick={() => {
                            setactiveItem(item)
                        }} />
                    </div>:
                    <>
                        <i className="hi-cog setting" onClick={() => settingOpen(item)}></i>
                        <i className="hi-trash1 trash" onClick={() => trash(item.uid)}></i>
                    </>
                }
                <p title={item.fileName}>{item.fileName}</p>
            </li>
        })
    }

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">
            Media
            {
                !trashAction &&
                <label htmlFor="choose-file" className="btn btn-primary btn-small ml20">Choose Files</label>
            }
        </h1>
        <div className="filter-box">
            <div className="filters">
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i, tableData)
                    }}
                    options={filterTags}
                />
            </div>
            <div className="filters">
                <ul className='view-tabs ml20' role='tab'>
                    <li onClick={() => setviewMode('grid')} className={viewMode === 'grid' ? 'active' : ''}><i className='hi-grid_view'></i></li>
                    <li onClick={() => setviewMode('list')} className={viewMode === 'list' ? 'active' : ''}><i className='hi-list'></i></li>
                </ul>
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <div className="form-group">
            <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/webp,image/gif,image/jpeg, video/mp4, video/mov, video/mkv, video/avi, video/webm," />
        </div>
        {
            fileArray.length !== 0 &&
            <div className="upload-box">
                <ul className="media-box">
                    {(fileArray || []).map((file, index ) => (
                        <li key={index}>
                            {
                                hasImageExtension(file.fileName) || file.type === "image/webp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/x-png" || file.type === "image/gif" || typeof file.type=== 'undefined' || file.type === '' ?
                                <Img source="upload" src={file.url} alt="..." />:
                                <video className="video-container video-container-overlay" autoPlay={false} loop>
                                    <source  src={file.url} type="video/mp4" />
                                </video>
                            }
                        </li>
                    ))}
                </ul>
                <Button className="btn-primary btn btn-small ml0" onClick={uploadFiles}>Upload</Button>
            </div>
        }
        <ul className={"media-box " + viewMode} id="list-wrapper">
            {
                viewMode === 'grid' ?
                renderImagesGrid(filteredList):
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            }
        </ul>
        <div className="modal media-modal" id="media-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("media-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        activeImage ?
                        <div className='flex p20'>
                            <div className='preview-box'>
                                <Img src={activeImage.fileName} />
                            </div>
                            <div className='setting-box'>
                                <div className=''>
                                    <p>Caption</p>
                                    <textarea onChange={e => setactiveImage({...activeImage, caption: e.target.value})} value={activeImage.caption ?? ''}></textarea>
                                </div>
                                <div className=''>
                                    <p>Description</p>
                                    <textarea onChange={e => setactiveImage({...activeImage, description: e.target.value})} value={activeImage.description ?? ''}></textarea>
                                </div>
                                <div className='mb20'>
                                    <p>Usage</p>
                                    <CreatableSelect
                                        value={activeUsage}
                                        onChange={setMediaUsage}
                                        options={usageOption}
                                    />
                                </div>
                                <div className=''>
                                    <p><b>URL:</b> {base.imageBaseUrl+activeImage.fileName}</p>
                                </div>
                                <div className=''>
                                    <p><b>Name: </b>{activeImage.fileName}</p>
                                </div>
                                <div className=''>
                                    <p><b>Type: </b>{activeImage.type}</p>
                                </div>
                                <div className=''>
                                    <p><b>Upload Date: </b>{getFormattedDate(activeImage.uid)}</p>
                                </div>
                            </div>
                        </div>:
                        ''
                    }
                </div>
                <div className="modal-footer">
                    <Button className="btn btn-outline" onClick={() => trash(activeImage.uid)}>Delete</Button>
                    <Button className="btn btn-primary" onClick={() => updateMedia(activeImage)}>Update</Button>
                </div>
            </div>
        </div>
    </div>
}
