import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useProductCategory = () => {
    const Actor = useElement('Actor')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        closeModal,
        showAlert,
        slugify,
    } = useService('Misc')
    const {
        getProductCategory,
        deleteProductCategory,
        addProductCategory: publishProductCategory,
    } = useService('Product')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [categoryList, setcategoryList] = useState([])
    const [catName, setcatName] = useState('')
    const [catSlug, setcatSlug] = useState('')
    const [parentCat, setparentCat] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [activeItem, setactiveItem] = useState('')
    const [uid, setuid] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [mode, setmode] = useState('Add')
    const [categoryOptions, setcategoryOptions] = useState([])
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Product Category', 'admin')
        getProductCategoryData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getProductCategoryData = async () => {
        const result = await getProductCategory()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No chats placed yet!' })
                setcategoryList([])
                setcategoryOptions([])
            } else {
                let categoryList = [],
                categoryOptions = []
                result.data.forEach(e => {
                    categoryList.push(e)
                    categoryOptions.push({label: e.catName, value: e.catSlug})
                })
                setcategoryList(categoryList)
                setcategoryOptions(categoryOptions)
            }
        }
    }
    
    const categoryCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashCategory = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await deleteProductCategory(query)
        if (result.status) {
            getProductCategoryData()
            showAlert({ type: 'success', msg: 'Category deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    
    const addProductCategory = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '', parentCategory = ''
        if (parentCat !== '')
            parentCategory = parentCat.label
        let categoryObject = {
            catName: catName,
            catSlug: catSlug,
            parentCat: parentCategory
            
        }
        if (catName === '' || catSlug === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            categoryObject.uid = generateUID()
            const result = await publishProductCategory(categoryObject)
            if (result.status)
                showAlert({ type: 'success', msg: 'Category added successfully!' })
            else showAlert({ type: 'error', msg: 'Unable to add chat!' })
            setcatName('')
            setcatSlug('')
            setparentCat('')
            setuid('')
            closeModal("category-modal")
            getProductCategoryData()
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                catName: <p className="query mb40">{item.catName}</p>,
                catSlug: <p className="ans mb40">{item.catSlug}</p>,
                parentCat: <p className="ans mb40">{item.parentCat}</p>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }

    const addCategoryName = e => {
        let tempTitle = e.target.value, tempSlug = catSlug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        setcatName(tempTitle)
        setcatSlug(tempSlug)
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Category Name',
                    key: 'categoryName'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Parent Category',
                    key: 'parentCategory'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(categoryList)
        })
    }, [categoryList])
    return {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashCategory,
        categoryCancel,
        showTrashConfirm,
        setcatName,
        setuid,
        addProductCategory,
        catName,
        addCategoryName,
        catSlug,
        setcatSlug,
        parentCat,
        setparentCat,
        categoryOptions,
    }
}