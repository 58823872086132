export const useBadge = ({
    onClick
}) => {
    const onBadgeClick = () => {
        if (typeof onClick === 'function') {
            onClick()
        }
    }
    return {
        onBadgeClick
    }
}