import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useProductBrands = () => {
    const Actor = useElement('Actor')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        closeModal,
        showAlert,
        slugify,
    } = useService('Misc')
    const {
        getProductBrand,
        deleteProductBrand,
        addProductBrand: publishProductBrand,
    } = useService('Product')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const[brandList, setbrandList] = useState([])
    const[brandName, setbrandName] = useState('')
    const[brandSlug, setbrandSlug] = useState('')
    const[renderSlug, setrenderSlug] = useState(true)
    const[activeItem, setactiveItem] = useState('')
    const[uid, setuid] = useState('')
    const[showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Product Brands', 'admin')
        getProductBrandData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getProductBrandData = async () => {
        const result = await getProductBrand()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No chats placed yet!' })
                setbrandList([])
            } else {
                let brandList = []
                result.data.forEach(e => {
                    brandList.push(e)
                })
                setbrandList(brandList)
            }
        }
    }
    
    const brandCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashBrand = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await deleteProductBrand(query)
        if (result.status) {
            getProductBrandData()
            showAlert({ type: 'success', msg: 'Brand deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    
    const addProductBrand = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        brandObject = {
            brandName: brandName,
            brandSlug: brandSlug
        }
        if (brandName === '' || brandSlug === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            brandObject.uid = generateUID()
            const result = await publishProductBrand(brandObject)
            if (result.status)
                showAlert({ type: 'success', msg: 'Brand added successfully!' })
            else showAlert({ type: 'error', msg: 'Unable to add chat!' })
            setbrandName('')
            setbrandSlug('')
            setuid('')
            closeModal("brand-modal")
            getProductBrandData()
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const addBrandName = e => {
        let tempTitle = e.target.value, tempSlug = brandSlug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        setbrandName(tempTitle)
        setbrandSlug(tempSlug)
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                uid: <p className="ans mb40">{item.uid}</p>,
                brandName: <p className="query mb40">{item.brandName}</p>,
                brandSlug: <p className="ans mb40">{item.brandSlug}</p>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Id',
                    key: 'id'
                },
                {
                    label: 'Brand Name',
                    key: 'brandName'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(brandList)
        })
    }, [brandList])
    return {
        trackScrolling,
        tableContent,
        activeShownTotal,
        trashBrand,
        brandCancel,
        showTrashConfirm,
        setbrandName,
        setuid,
        addProductBrand,
        brandName,
        addBrandName,
        brandSlug,
        setbrandSlug,
    }
}