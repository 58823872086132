import Select from 'react-select'
import {CSVLink} from 'react-csv'
import { useOrders } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const Orders = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const DatePicker = useElement('DatePicker')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        trashAction,
        filteredList,
        exportedData,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        setstartDate,
        startDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        worker,
        setworker,
        workers,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectOrder,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    } = useOrders()
    const exportedFileName = 'export_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="flex title">
            {
                trashAction ? 'Trashed' : 'All'
            } Orders
            {
                !trashAction &&
                <>
                {
                    filteredList?.length !== 0 &&
                    <span className="btn btn-primary btn-small ml20 export-btn white">
                        <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
                    </span>
                }
                </>
            }
            {
                (checkedList.length !== 0 || checkedAll) &&
                <Button onClick={() => {
                    setisMultipleDelete(true)
                    setshowTrashConfirm(true)
                }} className="btn btn-outline btn-small">Delete</Button>
            }
        </h1>
        <div className="filter-box">
            <div className="filter-box column">
                <div className="flex date-filter overflow-visible">
                    <div className="form-group">
                        <DatePicker
                            selected={startDate}
                            onChange={startDate => setstartDate(startDate)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="From:"
                        />
                    </div>
                    <div className="form-group">
                        <DatePicker
                            selected={endDate}
                            onChange={endDate => setendDate(endDate)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="To:"
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                    <div className="form-group ml10">
                        <label className="filter-label-add">Status: </label>
                        <Select
                            value={activeFilter}
                            defaultValue={activeFilter}
                            onChange={(e, i) => {
                                setactiveFilter(e)
                                filterItems(e.value, i, tableData)
                            }}
                            options={filterTags}
                        />
                    </div>
                </div>
            </div>
            <div className="worker-filter-box relative">
            <label className="filter-label-add">Assigned Worker: </label>
                <div className="body overflown">
                    {
                        workers.length !== 0 &&
                        <Select
                            value={worker}
                            defaultValue={worker}
                            onChange={worker => setworker(worker)}
                            options={workers}
                        />
                    }
                </div>
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():deleteSelectOrder()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
