import { useContext, useEffect, useState } from "react"
import { config, activeDateFilter as adf} from '../../../../constant'
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"
export const useCompanyWorkDashboard = () => {
    const Actor = useElement('Actor')
    const { commonPageSize } = config
    const activeShownTotal = commonPageSize
    const {fetchByAttr} = useService('Data', 'b2bDailyWorkData')
    const {fetchByAttr: fetchByAttrB2bDailyStockData} = useService('Data', 'b2bDailyStockData')
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {getPreviousDate} = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const user = getLoggedInUserData()
    const [totalData, settotalData] = useState({
        'Opening Stock': 0,
        'Stock In': 0,
        'Stock Out': 0,
        'Closing Stock': 0,
        'Inspection Done': 0
    })
    const [tableContent, settableContent] = useState(null)
    const [exportedData, setexportedData] = useState([])
    const [b2bDailyWorkData, setb2bDailyWorkData] = useState([])
    const [b2bDailyStockData, setb2bDailyStockData] = useState([])
    const [b2bTotalWorkData, setb2bTotalWorkData] = useState([])
    const [activeDateFilter, setactiveDateFilter] = useState(adf[4])
    const [branches, setbranches] = useState([])
    const [fromFilterDate, setfromFilterDate] = useState(getPreviousDate(2))
    const [toFilterDate, settoFilterDate] = useState(getPreviousDate(1))
    const [activeBranches, setactiveBranches] = useState([])

    useEffect(() => {
        setTitle('Daily Work Report')
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getB2bDailyWorkData()
            getB2bDailyStockData()
        }
    }, [])

    useEffect(() => {
        let btw = []
        let td = {
            'Opening Stock': 0,
            'Stock In': 0,
            'Stock Out': 0,
            'Closing Stock': 0,
            'Inspection Done': 0
        }
        activeBranches?.forEach( e => {
            btw.push({
                ownerId: e.value,
                'Yard Name': e.label,
                'Closing Stock': 0,
                'Non Running': 0,
                'Running': 0,
                'Battery Issue': 0,
                'Tyre Issue': 0,
                'Waterless cleaning': 0,
                'Gold Wash': 0,
                'Battery Charging': 0,
                'Puncture Repaired': 0
            })
        })
        btw.push({
            'Yard Name': 'Grand Total',
            'Closing Stock': 0,
            'Non Running': 0,
            'Running': 0,
            'Battery Issue': 0,
            'Tyre Issue': 0,
            'Waterless cleaning': 0,
            'Gold Wash': 0,
            'Battery Charging': 0,
            'Puncture Repaired': 0
        })
        b2bDailyStockData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                e['Inventory Status'] = e['Inventory Status'].trim()
                if (e['Inventory Status'] === 'Opening Stock') {
                    td['Opening Stock']++
                } else if (['Stock Out', 'Vehicle Out'].includes(e['Inventory Status'])) {
                    td['Stock Out']++
                } else if (['Stock In', 'Vehicle In'].includes(e['Inventory Status'])) {
                    td['Stock In']++
                }
                btw.forEach(f => {
                    if (e.ownerId === f.ownerId) {
                        if (e['Inventory Status'] === 'Opening Stock') {
                            f['Closing Stock']++
                            btw[btw.length - 1]['Closing Stock']++
                        } else if (['Stock Out', 'Vehicle Out'].includes(e['Inventory Status'])) {
                            f['Closing Stock']--
                            btw[btw.length - 1]['Closing Stock']--
                        } else if (['Stock In', 'Vehicle In'].includes(e['Inventory Status'])) {
                            f['Closing Stock']++
                            btw[btw.length - 1]['Closing Stock']++
                        }
                    }
                })
            }
        })
        td['Closing Stock'] = td['Opening Stock'] - td['Stock Out'] + td['Stock In']
        b2bDailyWorkData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                if (e['Vehicle Inspection Done'] === 'Yes') {
                    td['Inspection Done']++
                }
                btw.forEach(f => {
                    if (e.ownerId === f.ownerId) {
                        if(e['Vehicle Running Condition'] === 'Non Running') {
                            f['Non Running']++
                            btw[btw.length - 1]['Non Running']++
                        }
                        if(e['Cleaning Work Done'] === 'Gold Wash') {
                            f['Gold Wash']++
                            btw[btw.length - 1]['Gold Wash']++
                        } else if(e['Cleaning Work Done'] === 'Waterless Cleaning') {
                            f['Waterless cleaning']++
                            btw[btw.length - 1]['Waterless cleaning']++
                        }
                        if(e['Service Done ']['Battery Charging'] === 'Done') {
                            f['Battery Charging']++
                            btw[btw.length - 1]['Battery Charging']++
                        }
                        if(e['Service Done ']['Battery Charging'] === 'Need Replacement') {
                            f['Battery Issue']++
                            btw[btw.length - 1]['Battery Issue']++
                        }
                        if(e['Vehicle Tyre Status'] === 'Puncture') {
                            f['Tyre Issue']++
                            btw[btw.length - 1]['Tyre Issue']++
                        }
                        if(e['Service Done ']['Puncture Repair'] === 'Done') {
                            f['Puncture Repaired']++
                            btw[btw.length - 1]['Puncture Repaired']++
                        }
                    }
                })
            }
        })
        settotalData(td)
        btw.forEach(f => {
            f['Running'] = f['Closing Stock'] - f['Non Running']
        })
        btw[btw.length - 1]['Running'] = btw[btw.length - 1]['Closing Stock'] - btw[btw.length - 1]['Non Running'] 
        settableContent({
            headers: [
                {
                    label: 'Yard Name',
                    key: 'Yard Name'
                },
                {
                    label: 'Closing Stock',
                    key: 'Closing Stock'
                },
                {
                    label: 'Non Running',
                    key: 'Non Running'
                },
                {
                    label: 'Running',
                    key: 'Running'
                },
                {
                    label: 'Battery Issue',
                    key: 'Battery Issue'
                },
                {
                    label: 'Tyre Issue',
                    key: 'Tyre Issue'
                },
                {
                    label: 'Waterless cleaning',
                    key: 'Waterless cleaning'
                },
                {
                    label: 'Gold Wash',
                    key: 'Gold Wash'
                },
                {
                    label: 'Battery Charging',
                    key: 'Battery Charging'
                },
                {
                    label: 'Puncture Repaired',
                    key: 'Puncture Repaired'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(btw)
        })
        setb2bTotalWorkData(btw)
        setb2bDailyWorkData(b2bDailyWorkData)
        setexportedData(btw)
    }, [activeBranches, fromFilterDate, toFilterDate])

    const getBranches = async v => {
        let query = {
            parentId: v
        }, bs = [], activeDateFilter
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                bs.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : bs
            setbranches(bs)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches, true)
        }
    }
    
    const getDashboardData = (activeDateFilter, activeBranches, fetch = false) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
            b2bTotalWorkData.push({
                ownerId: e.value,
                'Yard Name': e.label,
                'Closing Stock': 0,
                'Non Running': 0,
                'Running': 0,
                'Battery Issue': 0,
                'Tyre Issue': 0,
                'Waterless cleaning': 0,
                'Gold Wash': 0,
                'Battery Charging': 0,
                'Puncture Repaired': 0
            })
        })
        b2bTotalWorkData.push({
            'Yard Name': 'Grand Total',
            'Closing Stock': 0,
            'Non Running': 0,
            'Running': 0,
            'Battery Issue': 0,
            'Tyre Issue': 0,
            'Waterless cleaning': 0,
            'Gold Wash': 0,
            'Battery Charging': 0,
            'Puncture Repaired': 0
        })
        setb2bTotalWorkData(b2bTotalWorkData)
        if (fetch) {
            getB2bDailyWorkData(activeBranchIds)
            getB2bDailyStockData(activeBranchIds)
        }
    }
    
    const selectCompany = e => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    const getB2bDailyStockData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttrB2bDailyStockData(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setb2bDailyStockData(b2bDailyStockData)
            } else {
                setb2bDailyStockData(result.data.reverse())
            }
        }
    }
    
    const getB2bDailyWorkData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setb2bDailyWorkData([])
            } else {
                if(!branches) {
                    setb2bTotalWorkData([
                        {
                            ownerId: user.uid,
                            'Yard Name': user.name,
                            'Closing Stock': 0,
                            'Non Running': 0,
                            'Running': 0,
                            'Battery Issue': 0,
                            'Tyre Issue': 0,
                            'Waterless cleaning': 0,
                            'Gold Wash': 0,
                            'Battery Charging': 0,
                            'Puncture Repaired': 0
                        }
                    ])
                }
                setb2bDailyWorkData(result.data.reverse())
            }
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(e => {
            let ownerId = e.ownerId ?? ''
            let el = {
                'Yard Name': e['Yard Name'],
                'Closing Stock': e['Closing Stock'],
                'Non Running': e['Non Running'],
                'Running': e['Running'],
                'Battery Issue': e['Battery Issue'],
                'Tyre Issue': e['Tyre Issue'],
                'Waterless cleaning': e['Waterless cleaning'],
                'Gold Wash': e['Gold Wash'],
                'Battery Charging': e['Battery Charging'],
                'Puncture Repaired': e['Puncture Repaired'],
                action: <div className="action-bar">
                    <Actor title="View Work Detail" type='view' onClick={() => navigateTo({route: '/company/dashboard/work/detail/'+ownerId, newTab: true})} />
                    <Actor title="View Stock Detail" type='car' onClick={() => navigateTo({route: '/company/dashboard/stock/detail/'+ownerId, newTab: true})} />
                </div>
            }
            data.push(el)
        })
        return data
    }
    return {
        user,
        exportedData,
        activeBranches,
        selectCompany,
        branches,
        setfromFilterDate,
        fromFilterDate,
        settoFilterDate,
        toFilterDate,
        totalData,
        tableContent,
        activeShownTotal,
    }
}