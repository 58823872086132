import { useContext } from "react"
import { NavigatorContext } from "../../../context"

export const useProductBox = ({
    product
}) => {
    const {navigateTo} = useContext(NavigatorContext)

    const addToCart = () => {
        console.log('addToCart', product)
    }

    const openProductPage = () => navigateTo({route: '/product/'+product.slug})
    return {
        openProductPage,
        addToCart,
    }
}