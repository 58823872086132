import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useGarage = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'garage')
    const {setTitle} = useService('Meta')
    const {isEmpty} = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [image, setimage] = useState('[]')
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState('')
    const [excerpt, setexcerpt] = useState('')
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [author, setauthor] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState('')
    const [category, setcategory] = useState([])
    const [tag, settag] = useState('')

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getGarageData({slug: urlData[2]})
    }, [slug, urlData])

    const getGarageData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'publish') {
                navigateTo({route: '/error404'})
            } else {
                const garageData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === garageData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === garageData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === garageData.status))
                settag(isEmpty(garageData.tag) ? [] : JSON.parse(garageData.tag))
                setcategory(isEmpty(garageData.category) ? [] : JSON.parse(garageData.category))
                setmetaKeyword(isEmpty(garageData.metaKeyword) ? [] : JSON.parse(garageData.metaKeyword))
                setTitle(garageData.title)
                settitle(garageData.title)
                setslug(garageData.slug)
                setcontent(garageData.content)
                setexcerpt(garageData.excerpt)
                setcss(garageData.css)
                setjs(garageData.js)
                setcanonical(garageData.canonical)
                setrobots(garageData.robots)
                setmetaDescription(garageData.metaDescription)
                setauthor(garageData.author)
                setpublishDate(new Date(garageData.publishDate))
                setstatus(garageData.status)
                setuid(garageData.uid)
                setimage(garageData.image)
            }
        }
    }
    return {
        category,
        title,
        publishDate,
        image,
        content,
        slug,
    }
}