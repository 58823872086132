import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useCarModelManufacturerList = () => {
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {save} = useService('Data', 'media')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        closeModal,
        getBase64,
        slugify,
    } = useService('Misc')
    const {
        getCarManufacturers,
        deleteCarManufacturer,
        addCarManufacturer: publishCarManufacturer,
    } = useService('Car')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [mode, setmode] = useState('Add')
    const [uploaded, setuploaded] = useState(false)
    const [list, setlist] = useState([])
    const [name, setname] = useState('')
    const [slug, setslug] = useState('')
    const [activeItem, setactiveItem] = useState('')
    const [image, setimage] = useState('')
    const [uid, setuid] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Car Manufacturers', 'admin')
        getListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }

    const uploadCall = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = ''
        if (!error) {
            image.uid = generateUID()
            const fileResult = await save(image)
            if (fileResult.status) {
                addCarManufacturer()
            } else showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getListData = async () => {
        const result = await getCarManufacturers()
        if (result.status) {
            let list = []
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No Car Manufacturers added yet!' })
            } else {
                list = result.data.reverse()
            }
            setlist(list)
        }
    }
    
    const manufacturerCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashOne = async () => {
        const result = await deleteCarManufacturer({uid: activeItem.uid})
        if (result.status) {
            getListData()
            showAlert({ type: 'success', msg: 'Car Manufacturer deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete Car Manufacturer!' })
    }
    
    const addCarManufacturer = async () => {
        let error = false,
        errorMsg = '',
        manufacturerObject = {
            name,
            slug,
            image: image.fileName
        }
        if (name === '' || slug === '') {
            errorMsg = 'Please enter Car Manufacturer content!'
            error = true
        }
        if (!error) {
            manufacturerObject.uid = generateUID()
            const result = await publishCarManufacturer(manufacturerObject)
            if (result.status)
                showAlert({ type: 'success', msg: 'Car Manufacturer added successfully!' })
            else showAlert({ type: 'error', msg: 'Unable to add Car Manufacturer!' })
            setimage('')
            setname('')
            setslug('')
            setuid('')
            closeModal("manufacturer-modal")
            getListData()
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                name: <p className="query mb40">{item.name}</p>,
                slug: <p className="query mb40">{item.slug}</p>,
                image: <Img src={item.image} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const selectFiles = async (e) => {
        let fileData = e.target.files[0]
        const result = await getBase64(fileData)
        let image = {
            url: URL.createObjectURL(fileData),
            data: result,
            type: fileData.type,
            fileName: fileData.name,
            size: fileData.size
        }
        if (fileData.size <= 4000000) {
            setimage(image)
            setuploaded(true)
        }
        else showAlert({ type: 'error', msg: 'File is too large.' })
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(list)
        })
    }, [list])

    const addCarManufacturerName = e => {
        let tempTitle = e.target.value, slug = slugify(tempTitle)
        setname(tempTitle)
        setslug(slug)
    }
    return {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashOne,
        manufacturerCancel,
        showTrashConfirm,
        setname,
        setuid,
        uploadCall,
        name,
        addCarManufacturerName,
        slug,
        setslug,
        uploaded,
        image,
        setimage,
        selectFiles,
    }
}