import { useEffect, useState } from "react"
import { useService } from "../../../../../hooks"

export const useAdminData = () => {
    const {getLoggedInUserData} = useService('User')
    const {
        openModal,
        closeModal,
        generateUID,
    } = useService('Misc')
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'setting')
    const user = getLoggedInUserData()
    const [widget, setwidget] = useState([])
    const [mode, setmode] = useState('edit')

    useEffect(() => {
        getDashboardData()
    }, [])

    const getDashboardData = async () => {
        const result = await fetchByAttr({
            key: 'admin-dashboard-widgets',
            userId: user.uid
        })
        if (result.status) {
            if (result.data?.length === 0) {
                setmode('add')
            } else {
                let widgetData = JSON.parse(result.data[0].value)
                setwidget(widgetData)
            }
        }
    }

    const openWidgetMonitor = () => {
        openModal('widget-modal')
    }

    const addWidget = (e) => {
        closeModal('widget-modal')
        let widgetData = [...widget, {...e, uid: generateUID()}]
        saveDashboardData(widgetData)
        setwidget(widgetData)
    }

    const saveDashboardData = async (widgetData) => {
        let settingObject = {
            key: 'admin-dashboard-widgets',
            userId: user.uid,
            value: JSON.stringify(widgetData)
        }
        if (mode === 'add') {
            settingObject.uid = generateUID()
            save(settingObject)
        } else {
            let data = {
                query: JSON.stringify({
                    key: 'admin-dashboard-widgets',
                    userId: user.uid
                }),
                settingData: JSON.stringify({value: settingObject.value})
            }
            update(data)
        }
    }
    const deleteWidget = (widgetId) => {
        let widgetData = []
        widget.forEach(e => {
            if (e.uid !== widgetId) {
                widgetData.push(e)
            }
        })
        saveDashboardData(widgetData)
        setwidget(widgetData)
    }

    const configureWidget = (widgetId) => {
        let widgetData = []
        widget.forEach(e => {
            if (e.uid === widgetId) {
                e.configure = true
            }
            widgetData.push(e)
        })
        setwidget(widgetData)
    }

    const updateWidget = (activeWidgetData) => {
        let widgetData = []
        widget.forEach(e => {
            if(e.uid === activeWidgetData.uid) {
                e = activeWidgetData
            }
            widgetData.push(e)
        })
        saveDashboardData(widgetData)
        setwidget(widgetData)
    }
    return {
        addWidget,
        widget,
        configureWidget,
        deleteWidget,
        updateWidget,
        openWidgetMonitor,
    }
}