export const ServiceWorkModel = [
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'billing',
        dataType: String
    },
    {
        key: 'tempUid',
        dataType: String
    },
    {
        key: 'startDate',
        dataType: String
    },
    {
        key: 'endDate',
        dataType: String
    },
    {
        key: 'activeCar',
        dataType: String
    },
    {
        key: 'workData',
        dataType: String
    }
]