import {AdminData} from './AdminData'
import {DeveloperData} from './DeveloperData'
import {WorkerData} from './WorkerData'
import {SupervisorData} from './SupervisorData'
import {FranchiseData} from './FranchiseData'
import { useService } from '../../../hooks'

export const DashboardData = () => {
    const {getLoggedInUserData} = useService('User')
    const user = getLoggedInUserData()
    return <>
        {
            user?.role === 'admin' ?
            <AdminData /> :
            user?.role === 'developer' ?
            <DeveloperData /> :
            user?.role === 'supervisor' ?
            <SupervisorData /> :
            user?.role === 'worker' ?
            <WorkerData /> :
            ['franchiseOwner', 'franchiseManager'].includes(user?.role) ?
            <FranchiseData />
            : <></>
        }
    </>
}