import { useContext } from "react"
import { NavigatorContext } from "../../context"
import { useService } from "../../hooks"
import { LinkType } from "./type"

export const Link = ({id, target, href='#', className, onClick, title, children}: LinkType) => {
    const {isAdminUrl} = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const onLinkClick = e => {
        e.preventDefault()
        if(onClick) {
            onClick?.()
        } else {
            const external = href[0] !== '/'
            const differentOrigin = (!isAdminUrl(window.location.href) && isAdminUrl(href)) || (isAdminUrl(window.location.href) && !isAdminUrl(href))
            navigateTo({route: href, external, differentOrigin})
        }
    }
    return <a
        id={id}
        target={target}
        href={href}
        className={className}
        onClick={onLinkClick}
        title={title}
    >{children}</a>
}