import {CSVLink} from 'react-csv'
import Select from 'react-select'
import { useUsers } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const Users = () => {
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        trashAction,
        urlHash,
        user,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        filteredList,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectUser,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
        exportedData,
    } = useUsers()
    const exportedFileName = 'exported_users_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex mb50">
            {
                trashAction?'Trashed': ''
            } {urlHash !== ''?urlHash:'User'} List
            {
                !trashAction && user.role !== "supervisor" &&
                <>
                    <Link className="btn btn-primary btn-small" href="/admin/user/add">Add</Link>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button onClick={() => {
                            setisMultipleDelete(true)
                            setshowTrashConfirm(true)
                        }} className="btn btn-outline btn-small">Delete</Button>
                    }
                </>
            }
            {
                filteredList.length !== 0 &&
                <span className="btn btn-primary btn-small ml20 export-btn">
                    <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
                </span>
            }
        </h1>
        <div className="filter-box">
            {urlHash === '' && <div className="filters relative">
                <label className="filter-label-add">Role: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i)
                    }}
                    options={filterTags}
                />
            </div>}
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():deleteSelectUser()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
