export const userRole = require('./userRole.json')
export const bottomNav = require('./bottomNav.json')
export const footerNav = require('./footerNav.json')
export const adminNav = require('./adminNav.json')
export const orderOption = require('./orderOption.json')
export const garageoption = require('./garageoption.json')
export const robotsOption = require('./robotsOption.json')
export const authorOption = require('./authorOption.json')
export const emailOption = require('./emailOption.json')
export const faqCategory = require('./faqCategory.json')
export const timeSlot = require('./timeSlot.json')
export const profileNav = require('./profileNav.json')
export const headerNav = require('./headerNav.json')
export const fuelOption = require('./fuelOption.json')
export const packagesOption = require('./packagesOption.json')
export const activeDateFilter = require('./activeDateFilter.json')
export const statusOption = require('./statusOption.json')
export const badge = require('./badge.json')
export const actor = require('./actor.json')
export const sortOption = require('./sortOption.json')
export const carStatusOption = require('./carStatusOption.json')
export const inactiveCarOption = require('./inactiveCarOption.json')
export const chartColor = require('./chartColor.json')
export const b2bService = require('./b2bService.json')
export const featuredSetting = require('./featuredSetting.json')
export const dataTable = require('./dataTable.json')
export const usageOption = require('./usageOption.json')
export const dashboardWidgetOption = require('./dashboardWidgetOption.json')
export const paymentOption = require('./paymentOption.json')
export const complaintTypeOption = require('./complaintTypeOption.json')
export const stateTypes = require('./stateTypes.json')
export const openigHours = require('./openigHours.json')
export const bannerText = require('./bannerText.json')
export const provideText = require('./provideText.json')
export const showServices = require('./showServices.json')
export const franchiseTiles = require('./franchiseTiles.json')