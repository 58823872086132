import { useContext, useEffect, useState } from "react"
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddSociety = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getSociety,
        addSociety: publishSociety,
        updateSociety,
    } = useService('Location')
    const [address, setaddress] = useState('')
    const [assignWorker, setassignWorker] = useState('')
    const [supervisor, setsupervisor] = useState('')
    const [supervisorOptions, setsupervisorOptions] = useState([])
    const [mode, setmode] = useState('Add')
    const [assignWorkers, setassignWorkers] = useState([])
    const [places, setplaces] = useState([])
    const [showSuggestions, setshowSuggestions] = useState(true)
    const [addressDetail, setaddressDetail] = useState('')
    const [title, settitle] = useState('')
    const [uid, setuid] = useState('')
    const [locality, setlocality] = useState('')
    const [pinCode, setpinCode] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    
    const getSocietyData = async (query) => {
        const result = await getSociety(query)
        if (result.status) {
            const societyData = result.data[0], pinCode = '', state = '', city = '', locality = '',
            addressDetail = JSON.parse(societyData.addressDetail)
            addressDetail.address_components?.forEach(e => {
                if (e.types?.includes("postal_code"))
                    pinCode = e.long_name
                if (e.types?.includes("administrative_area_level_1"))
                    state = e.long_name
                if (e.types?.includes("administrative_area_level_2"))
                    city = e.long_name
                if (e.types?.includes("locality"))
                    locality = e.long_name
            })
            settitle(societyData.title)
            setmode('Edit')
            setaddress(societyData.address)
            setsupervisor(societyData.supervisor !== 'undefined' && typeof societyData.supervisor !== 'undefined' && societyData.supervisor !== ''? JSON.parse(societyData.supervisor): '')
            setassignWorker(societyData.assignWorker !== 'undefined' && typeof societyData.assignWorker !== 'undefined' && societyData.assignWorker !== ''? JSON.parse(societyData.assignWorker): '')
            setaddressDetail(addressDetail)
            setuid(societyData.uid)
            setlocality(locality)
            setpinCode(pinCode)
            setcity(city)
            setstate(state)
        }
    }
    
    const addSociety = async (e) => {
        e.preventDefault()
        let error = false, foundPinCode = false,
        errorMsg = ''
        addressDetail.address_components?.forEach(e => {
            if (e.types?.includes("postal_code")) {
                foundPinCode = true
                e.long_name = pinCode
            }
            if (e.types?.includes("administrative_area_level_1"))
                e.long_name = state
            if (e.types?.includes("administrative_area_level_2"))
                e.long_name = city
            if (e.types?.includes("locality"))
                e.long_name = locality
        })
        if (!foundPinCode) {
            addressDetail.address_components.push({
                long_name: pinCode,
                types: ["postal_code"]
            })
        }
        let societyObject = {
            title,
            address,
            addressDetail: JSON.stringify(addressDetail),
            supervisor: JSON.stringify(supervisor),
            assignWorker: JSON.stringify(assignWorker)
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (address === '') {
            errorMsg = 'Please enter the address'
            error = true
        } else if (addressDetail === '') {
            errorMsg = 'Please enter the addressDetail'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                societyObject.uid = generateUID()
                const result = await publishSociety(societyObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Society added successfully!' })
                    navigateTo({route: '/admin/society/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to add society!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    societyData: JSON.stringify(societyObject)
                }
                const result = await updateSociety(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Society updated successfully!' })
                    navigateTo({route: '/admin/society/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to update society!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getUsersData = async () => {
        let tableDataSupervisor = [], tableDataWorkers = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'supervisor') {
                        tableDataSupervisor.push({label: result.name, value: result.uid})
                        setsupervisorOptions(tableDataSupervisor)
                    }
                    else if (result.role === 'worker') {
                        tableDataWorkers.push({label: result.name, value: result.uid})
                        setassignWorkers(tableDataWorkers)
                    }
                })
            }
        }
    }
    
    const handleChange = address => {
        setaddress(address)
        setplaces([])
        setshowSuggestions(true)
    }
    
    const parseAddress = (address_components) => {
        let pinCode ='', locality= '', city= '', state = ''
        address_components?.forEach(e => {
            if (e.types?.includes("postal_code"))
                pinCode = e.long_name
            if (e.types?.includes("administrative_area_level_1"))
                state = e.long_name
            if (e.types?.includes("administrative_area_level_2"))
                city = e.long_name
            if (e.types?.includes("locality"))
                locality = e.long_name
        })
        setlocality(locality)
        setpinCode(pinCode)
        setcity(city)
        setstate(state)
    }
    
    const saveSuggestion = async addressSuggestion => {
        let results = await geocodeByPlaceId(addressSuggestion.placeId)
        setaddress(results[0].formatted_address)
        setaddressDetail(results[0])
        handleChange(results[0].formatted_address)
        handleSelect(results[0].formatted_address)
        parseAddress(results[0].address_components)
    }
    
    const handleSelect = async address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
            let places = []
            places.push(latLng)
            if (latLng !== null) {
                setplaces(places)
                setshowSuggestions(false)
            }
        })
        .catch(e => console.log('error', e))
    }

    const selectAssignedWorker = e => {
        if (e === null) {
            setassignWorker(e)
        } else if (e.length > 2) {
            setassignWorker(assignWorker)
            showAlert({
                type: 'error',
                msg: 'You can not assign more than 2 workers to an order'
            })
        } else {
            setassignWorker(e)
        }
    }

    useEffect(() => {
        setTitle('Add Society', 'admin')
        getUsersData()
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[3] === 'edit') {
            setmode('Edit')
            getSocietyData({uid: urlData[4]})
        }
    }, [])
    return {
        mode,
        addSociety,
        title,
        settitle,
        address,
        handleChange,
        handleSelect,
        showSuggestions,
        saveSuggestion,
        addressDetail,
        places,
        locality,
        setlocality,
        city,
        setcity,
        state,
        setstate,
        pinCode,
        setpinCode,
        supervisor,
        setsupervisor,
        supervisorOptions,
        assignWorker,
        selectAssignedWorker,
        assignWorkers,
    }
}