import { useEffect, useState } from "react"
import { useService } from "../../../hooks"

export const useTestimonialWeb = () => {
    const {fetchAll} = useService('Data', 'testimonial')
    const [testimonialList, settestimonialList] = useState([])
    
    const getTestimonialData = async () => {
        const result = await fetchAll()
        if (result.status)
            settestimonialList(result.data)
    }

    useEffect(() => {
        getTestimonialData()
    }, [])
    return {
        testimonialList,
    }
}