import { useAddNotification } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddNotification = () => {
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        pageTitle,
        addNotification,
        title,
        settitle,
        body,
        setbody,
        jsonData,
        setjsonData,
        image,
        setimage,
    } = useAddNotification()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/notification/list' />
            { pageTitle }
        </h1>
        <form className="columns" noValidate onSubmit={addNotification}>
            <div className="left">
                <Box title="Title" required>
                    <Input autoFocus required name="title" value={title} onChange={e => settitle(e.target.value)} />
                </Box>
                <Box title="Box" required>
                    <textarea className="description" placeholder="Type the notification here" required name="body" value={body} onChange={e => setbody(e.target.value)}></textarea>
                </Box>
                <Box title="Data">
                    <textarea className="description" placeholder="Please enter valid Json data" required name="jsonData" value={jsonData} onChange={e => setjsonData(e.target.value)}></textarea>
                </Box>
            </div>
            <div className="right">
                <Box title="Meta">
                    <Button className="btn-primary btn" type="submit" title="Click here to Add notification">Submit</Button>
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['notification']} />
            </div>
        </form>
    </div>
}
