import './style.scss'
import { useService, useWidget } from '../../../hooks'

export const AdminDashboard = () => {
    const {setTitle} = useService('Meta')
    const DashboardData = useWidget('DashboardData')
    setTitle('Dashboard', 'admin')

    return <div id="content-box" className="content">
        <h1 className="title flex">
            Dashboard
        </h1>
        <DashboardData />
    </div>
}
