import { useMemo } from "react"
import { TableContent, TableFooter, TableHeader } from "./components"
export const Table = ({data, pageSize, tableClass = '', total, showMeta = false, showTotal = true}) => {
    const content = useMemo(() => {
        return data?.content
    }, [data?.content])

    return <div className="table-wrapper overflow-auto">
        {showMeta && <div className="table-metadata">
            <span className="widget">Total: <b>{total}</b></span>
         </div>}
        {showTotal && <p>Total: <b>{data?.content?.length ?? 0}</b></p>}
        <table id="list-wrapper" className={tableClass}>
            <TableHeader data={data?.headers} />
            <TableContent data={{content, addonBodyContent: data?.addonBodyContent}} pageSize={pageSize} />
            <TableFooter data={data?.footers} />
        </table>
    </div>
}