import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps'
import { useMap } from './hooks'

const MapWithMarker = props => {
    const {places} = useMap(props)
    const Markers = ({ places = [] }) => {
        return places.map((place, index ) => {
            return <div key={index}>
                <Marker key={place.id} position={{ lat: place.lat, lng: place.lng }} />
            </div>
        })
    }

    const Map = ({ places = [], zoom, center }) => {
        return <GoogleMap defaultZoom={zoom} defaultCenter={center}>
            <Markers places={places} />
        </GoogleMap>
    }

    return <Map center={props.center} zoom={props.zoom} places={places} />
}
export default withScriptjs(withGoogleMap(MapWithMarker))