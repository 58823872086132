import { useElement } from '../../hooks'

const secureIndex = [
    {
        image: 'icon-space.png',
        label: 'SPACE',
        value: 'Min. 600 sq. ft. + Commercial Space'
    },
    {
        image: 'icon-location.png',
        label: 'LOCATION',
        value: 'Prime Location'
    },
    {
        image: 'icon-height.png',
        label: 'HEIGHT CLEARANCE',
        value: 'Min. 10 ft'
    },
    {
        image: 'icon-electricity.png',
        label: 'ELECTRICITY',
        value: '3 Phase'
    },
    {
        image: 'icon-front.png',
        label: 'FRONT FACIA',
        value: 'Min. 20 ft'
    }
]
export const PropertyRequirements = () => {
    const Img = useElement('Img')
    return <section className="property-requirements mb60-mbs40">
        <div className="jumbo-container">
            <h2 className='heading-02'>Property Requirements</h2>
            <div className="main-secure">
                {
                    secureIndex.map((item, index) => {
                        return <div className="item" key={index}>
                            <div className="main-box">
                                <div className="icon">
                                    <Img alt={item.label} src={item.image} />
                                    <p className='m0'>{item.label}</p>
                                </div>
                                <p className="icon-title">{item.value}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </section>
}