export const StateModel = [
    {
        key: 'code',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'name',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'status',
        dataType: Boolean
    }
]