import Select from 'react-select'
import { useElement, useService } from "../../hooks"
import { useBookServiceForm } from "./hooks"
import './style.scss'
import { memo } from 'react'
export const BookServiceForm = memo(() => {
    const Button = useElement('Button')
    const {isEmpty} = useService('Misc')
    const {
        carBrands,
        carModels,
        services,
        locations,
        activeCarBrand,
        activeCarModel,
        activeService,
        activeLocation,
        bookService,
        selectCarBrand,
        selectCarModel,
        selectService,
        selectLocation,
    } = useBookServiceForm()
    return <form className='book-service-form' noValidate onSubmit={bookService}>
        <h1>BOOK A SERVICE FOR YOUR CAR</h1>
        <Select
            value={activeCarBrand}
            defaultValue={activeCarBrand}
            onChange={selectCarBrand}
            options={carBrands}
            placeholder='Select your car brand'
        />
        <Select
            value={activeCarModel}
            isDisabled={isEmpty(activeCarBrand)}
            defaultValue={activeCarModel}
            onChange={selectCarModel}
            options={carModels}
            placeholder='Select your car model'
        />
        <Select
            value={activeService}
            isDisabled={isEmpty(activeCarModel)}
            defaultValue={activeService}
            onChange={selectService}
            options={services}
            placeholder='Select service'
        />
        <Select
            value={activeLocation}
            isDisabled={isEmpty(activeService)}
            defaultValue={activeLocation}
            onChange={selectLocation}
            options={locations}
            placeholder='Select your location'
        />
        <div className='flex mb0'>
            <Button disabled={isEmpty(activeLocation)} onClick={bookService} className='mauto btn btn-primary'>Book Service Now</Button>
        </div>
    </form>
})