export const CarModelModel = [
    {
        key: 'modelKey',
        dataType: Number
    },
    {
        key: 'end_year',
        dataType: Number
    },
    {
        key: 'Segment',
        dataType: String
    },
    {
        key: 'fuel',
        dataType: Array
    },
    {
        key: 'name',
        dataType: String
    },
    {
        key: 'image_path',
        dataType: String
    },
    {
        key: 'start_year',
        dataType: Number
    },
    {
        key: 'manufacturer',
        dataType: String
    },
    {
        key: 'year_of_manufacture',
        dataType: Number
    },
    {
        key: 'uid',
        dataType: String
    }
]