import { useComponent, useElement, useService } from '../../../hooks'
import { useProductBrands } from './hooks'

export const ProductBrands = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        tableContent,
        activeShownTotal,
        trashBrand,
        brandCancel,
        showTrashConfirm,
        setbrandName,
        setuid,
        addProductBrand,
        brandName,
        addBrandName,
        brandSlug,
        setbrandSlug,
    } = useProductBrands()
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Brand <Button onClick={() => {
            openModal("brand-modal")
        }} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashBrand} trashCancelAction={brandCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal brand-modal" id="brand-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                    setbrandName('')
                    setbrandSlug('')
                    setuid('')
                    closeModal("brand-modal")
                }}>
                <i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="brand-modal-header">
                        <div className="modal-header">
                            <div className="heading">Add</div>
                            <form noValidate onSubmit={addProductBrand}> 
                                <div className="form-group mb40">
                                    <Input className="input" placeholder="Please enter a Brand Name" required name="brandName" value={brandName}
                                    onChange={addBrandName} />
                                    <label>Brand</label>
                                    <small className="msg text-danger">Please enter a Brand</small>
                                </div>
                                <div className="form-group mb40">
                                    <Input className="input" placeholder="Please enter slug" required name="brandSlug" value={brandSlug} onChange={e => setbrandSlug(e.target.value)} />
                                    <label>Slug</label>
                                    <small className="msg text-danger">Please enter slug</small>
                                </div>
                                <div className="buttons">
                                    <Button type="submit" title="Click here to Add Brand" className="btn btn-primary btn-small">Submit</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
