import { useComponent, useElement } from '../../../hooks'
import { useInvoiceListAdmin } from './hooks'

export const InvoiceListAdmin = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
    } = useInvoiceListAdmin()
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">
            All Invoices
        </h1>
        <div className="filter-box">
            <div className="filters">
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashEvent} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
    </div>
}
