import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddPage = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'page')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        isEmpty,
        generateUID,
        showAlert,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const keywordOptions =  [],
    tagOptions = []
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [image, setimage] = useState('[]')
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [assignWorkers, setassignWorkers] = useState([])
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [author, setauthor] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState('')
    const [tag, settag] = useState('')

    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                setassignWorkers(tableData)
            }
        }
    }
    
    const getPageData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const pageData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === pageData.robots))
            setactiveAuthorIndex(authorOption.findIndex(e => e.value === pageData.author))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === pageData.status))
            setmetaKeyword(isEmpty(pageData.metaKeyword) ? [] : pageData.metaKeyword)
            settag(isEmpty(pageData.tag) ? [] : pageData.tag)
            settitle(pageData.title)
            setmode('Edit')
            setslug(pageData.slug)
            setcontent(pageData.content)
            setexcerpt(pageData.excerpt)
            setcss(pageData.css)
            setjs(pageData.js)
            setcanonical(pageData.canonical)
            setrobots(pageData.robots)
            setmetaDescription(pageData.metaDescription)
            setauthor(pageData.author)
            setpublishDate(new Date(pageData.publishDate))
            setstatus(pageData.status)
            setuid(pageData.uid)
            setimage(pageData.image)
        } else showAlert({ type: 'error', msg: 'No page added yet!' })
    }
    
    const addPage = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        pageObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                pageObject.uid = generateUID()
                const result = await save(pageObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Page added successfully!' })
                    navigateTo({route: '/admin/page/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add page!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    pageData: JSON.stringify(pageObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Page updated successfully!' })
                    navigateTo({route: '/admin/page/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update page!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const urlData = urlToSplitData(getLocationData().pathname)
    
    useEffect(() => {
        setTitle('Add Page', 'admin')
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getPageData({uid: urlData[4]})
        }
        getUsersData()
    }, [])

    const addTitle = e => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    const pageTitle = mode + ' Page'
    let activeStatus = statusOption[activeStatusIndex],
    activeRobots = robotsOption[activeRobotsIndex]
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    robotsOption.forEach(e => {
        if (e.value === robots) {
            activeRobots = e
        }
    })
    statusOption.forEach(e => {
        if (e.value === status) {
            activeStatus = e
        }
    })
    return {
        pageTitle,
        addPage,
        title,
        addTitle,
        slug,
        setslug,
        content,
        setcontent,
        setimage,
        image,
        tagOptions,
        settag,
        tag,
        setstatus,
        activeStatus,
        publishDate,
        setpublishDate,
        toggleRenderSlug,
        renderSlug,
        metaKeyword,
        keywordOptions,
        setmetaKeyword,
        setmetaDescription,
        metaDescription,
        setrobots,
        activeRobots,
        excerpt,
        setexcerpt,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
    }
}