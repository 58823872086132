import { useContext, useEffect, useState } from "react"
import { base, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext, NavigatorContext } from "../../../../context"

export const useInvoiceListAdmin = () => {
    const Actor = useElement('Actor')
    const Link = useElement('Link')
    const {remove} = useService('Data', 'event')
    const {fetchAll} = useService('Data', 'invoice')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [tableData, settableData] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [filteredList, setfilteredList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)

    useEffect(() => {
        setTitle('Invoice List', 'admin')
        getInvoicesData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getInvoicesData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            settableData(tableData)
            setfilteredList(tableData)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashEvent = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getInvoicesData()
            showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                srNo: index + 1,
                invoiceNo: item.invoiceNo,
                orderId: <Link target="_blank" href={"/admin/order/view/"+item.orderId} rel="noreferrer">#{item.orderId}</Link>,
                action: <div className="action-bar">
                    <Actor type='download' onClick={() => navigateTo({route: base.invoiceBaseUrl+"/invoice_"+item.invoiceNo+".pdf", newTab: true})} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let orderId = '', filteredList = [], invoiceNo = ''
            tableData.forEach(e => {
                orderId = e.orderId ?? ''
                invoiceNo = e.invoiceNo ?? ''
                if (orderId.search(searchKey) !== -1 || invoiceNo.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'S. No.',
                    key: 'srNo'
                },
                {
                    label: 'Invoice No.',
                    key: 'invoiceNo'
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList])
    return {
        trackScrolling,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
    }
}