export const franchiseRoutes = [
    {
        "pathName": "/admin/franchise/list",
        "module": ["Franchise"],
        "component": "FranchiseList",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/franchise/add",
        "module": ["Franchise"],
        "component": "AddFranchise",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/franchise/edit",
        "module": ["Franchise"],
        "component": "AddFranchise",
        "type": "admin",
        "mode": "dynamic",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/trash/franchise/list",
        "module": ["Franchise"],
        "component": "FranchiseList",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    }
]