import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {robotsOption, statusOption} from '../../../constant'
import { useAddProject } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddProject = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const DatePicker = useElement('DatePicker')
    const Editor = useElement('Editor')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        pageTitle,
        addProject,
        title,
        addTitle,
        slug,
        setslug,
        description,
        setdescription,
        url,
        seturl,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        setreleaseDate,
        releaseDate,
        status,
        setstatus,
        category,
        setcategory,
        categoryOptions,
        tag,
        settag,
        tagOptions,
        image,
        setimage,
    } = useAddProject()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/project/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addProject}>
            <div className="left">
                <Box title='Title' required>
                    <Input autoFocus required name="title" value={title} onChange={addTitle} />
                </Box>
                <Box title='Slug' required>
                    <Input required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                </Box>
                <Box title='Content' required>
                    <Editor
                        value={description}
                        onEditorChange={(description) => setdescription(description)}
                    />
                </Box>
                <Box title='Excerpt' required>
                    <Editor
                        value={url}
                        onEditorChange={(url) => seturl(url)}
                    />
                </Box>
                <Box title='Canonical URL'>
                    <Input required name="canonical" value={canonical} onChange={e => setcanonical(e.target.value)} />
                </Box>
                <Box title='Robots'>
                    <Select
                        value={activeRobots}
                        defaultValue={activeRobots}
                        onChange={e => setrobots(e.value)}
                        options={robotsOption}
                    />
                </Box>
                <Box title='Meta Description'>
                    <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={e => setmetaDescription(e.target.value)}></textarea>
                </Box>
                <Box title='Meta Keywords'>
                    <CreatableSelect
                        isMulti
                        onChange={e => setmetaKeyword(e)}
                        options={keywordOptions}
                        value={metaKeyword}
                    />
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <p>
                        Render Slug:
                        <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={toggleRenderSlug}></span>
                    </p>
                    <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                <Box title='Release Date'>
                    <DatePicker
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeFormat="HH:mm"
                        onChange={releaseDate => setreleaseDate(releaseDate)}
                        selected={releaseDate}
                    /> 
                </Box>
                <Box title='Status'>
                    <Select
                        value={status}
                        onChange={e => setstatus(e)}
                        options={statusOption}
                    />
                </Box>
                <Box title='Category'>
                    <CreatableSelect
                        isMulti
                        value={category}
                        onChange={e => setcategory(e)}
                        options={categoryOptions}
                    />
                </Box>
                <Box title='Tags'>
                    <CreatableSelect
                        isMulti
                        value={tag}
                        onChange={e => settag(e)}
                        options={tagOptions}
                    />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['project']} />
            </div>
        </form>
    </div>
}
