import {brandInfo, mainUrl} from '../../constant'
import { usePost } from './hooks'
import { useComponent, useElement, useService } from '../../hooks'

export const Post = () => {
    const {getFormattedDate} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const BlogSideBar = useComponent('BlogSideBar')
    const Share = useComponent('Share')
    const {
        category,
        title,
        publishDate,
        image,
        content,
        slug,
    } = usePost()
    
    let categoryList = ''
    category.forEach(e => {
        categoryList += e.label+', '
    })
    return <div className="blog-page jumbo-container">
        <div className="blog-page-content pt120 pb100 mt40">
            <div className="wrapper">
                <div className="col-8">
                    <div className="blog-details-wrap">
                        <div className="blog-details-items">
                            <div className="content bg-none">
                                <h3 className="title">{title}</h3>
                                <div className="post-meta border-none pt20">
                                    <ul className="list-wrap">
                                        <li>
                                            <p className="author"><i className="hi-user1"></i>{brandInfo.name.capital}</p>
                                        </li>
                                        <li>
                                            <p className="author"><i className="hi-calendar1"></i> {getFormattedDate(publishDate)}</p>
                                        </li>
                                        <li>
                                            <p className="author"><i className="hi-folder1"></i>{categoryList}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="thumb mt20">
                                <Img src={JSON.parse(image)[0]} alt={title} />
                            </div>
                            <div className="blog-details-inner">
                                <Html content={content} />
                            </div>
                        </div>
                        <Share shareUrl={mainUrl+"/post/"+slug} />
                    </div>
                </div>
                <div className="col-4">
                    <BlogSideBar />
                </div>
            </div>
        </div>
    </div>
}
