import { SheetJSFT } from '../../../constant'
import { useComponent, useElement, useService } from '../../../hooks'
import { useImportPinCode } from './hooks'

export const ImportPinCode = () => {
    const {getLocationData} = useService('Router')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const {
        trackScrolling,
        fileSelected,
        fileImported,
        importDone,
        processCars,
        handleFile,
        handleChange,
        showTable,
        tableContent,
        activeShownTotal,
    } = useImportPinCode()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        {
            fileSelected?
            <>
                {
                    fileImported?
                    <>
                    {
                        !importDone &&
                        <Button type='submit' className="btn btn-primary mv20" onClick={processCars}>Import</Button>
                    }
                    </>:
                    <Button type='submit' className="btn btn-primary mv20" onClick={handleFile}>Parse</Button>
                }
            </>:
            <Box title="Import Excel to Add Cars">
                <Input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={handleChange} />
                <label className="btn btn-primary" htmlFor="file">
                    <i className="hi-upload-cloud"></i>
                    <span>Choose a file…</span>
                </label>
            </Box>
        }
        {
            showTable?
            <>
                <Table
                    className="import-table"
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </>
            :<>
                {
                    importDone &&
                    <>
                        <p>All Cars imported successfully</p>
                        <div className="flex">
                            <Link className="btn btn-primary" href="/admin/car/list/">Back to Cars List</Link>
                            <Button className="btn btn-outline ml20" onClick={() => getLocationData().reload()}>Import More</Button>
                        </div>
                    </>
                }
            </>
        }
    </div>
}
