export const societyRoutes = [
    {
        "pathName": "/admin/society/list",
        "module": ["Society"],
        "component": "Societies",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/society/add",
        "module": ["Society"],
        "component": "AddSociety",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/society/edit",
        "module": ["Society"],
        "component": "AddSociety",
        "type": "admin",
        "mode": "dynamic",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    }
]