import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const usePage = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'page')
    const {setTitle} = useService('Meta')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {isEmpty} = useService('Misc')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [image, setimage] = useState([])
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [author, setauthor] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState('')
    const [tag, settag] = useState('')

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getPageData({slug: urlData[2]})
    }, [slug, urlData])

    const getPageData =async (query) => {
        const result = await fetchByAttr(query)
        if (result.status && result.data?.length > 0) {
            if (result.data[0].status !== 'publish') {
                navigateTo({route: '/error404'})
            } else {
                const pageData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === pageData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === pageData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === pageData.status))
                settag(isEmpty(pageData.tag) ? [] : JSON.parse(pageData.tag))
                setmetaKeyword(isEmpty(pageData.metaKeyword) ? [] : JSON.parse(pageData.metaKeyword))
                setTitle(pageData.title)
                settitle(pageData.title)
                setmode('Edit')
                setslug(pageData.slug)
                setcontent(pageData.content)
                setexcerpt(pageData.excerpt)
                setcss(pageData.css)
                setjs(pageData.js)
                setcanonical(pageData.canonical)
                setrobots(pageData.robots)
                setmetaDescription(pageData.metaDescription)
                setauthor(pageData.author)
                setpublishDate(new Date(pageData.publishDate))
                setstatus(pageData.status)
                setuid(pageData.uid)
                setimage(JSON.parse(pageData.image))
            }
        }
    }
    return {
        title,
        image,
        content,
    }
}