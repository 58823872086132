import Select from 'react-select'
import { useAddApp } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddApp = () => {
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const {
        builders,
        mode,
        addApp,
        title,
        settitle,
        description,
        setdescription,
        id,
        setid,
        secret,
        setsecret,
        toggleStatus,
        user,
        status,
        brandKey,
        setbrandKey,
        activeBuilder,
        assignBuilder,
    } = useAddApp()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/app/list' />
            { mode + ' App' }
        </h1>
        <form className="columns" noValidate onSubmit={addApp}>
            <div className="left">
                <Box title="Title" required>
                    <Input autoFocus required name="title" value={title} onChange={e => settitle(e.target.value)} />
                </Box>
                <Box title="Description">
                    <textarea className="description" placeholder="Type the App here" required name="description" value={description} onChange={e => setdescription(e.target.value)}></textarea>
                </Box>
                <Box title="App Id">
                    <Input readOnly name="id" value={id} onChange={e => setid(e.target.value)} />
                </Box>
                <Box title="App Secret">
                    <Input readOnly name="secret" value={secret} onChange={e => setsecret(e.target.value)} />
                </Box>
            </div>
            <div className="right">
                <Box title='Status'>
                    <div className="flex-box switch-box">
                        <span>Inactive</span>
                        <label className="switch">
                            <Input type="checkbox" onChange={toggleStatus} checked={status === 'active'?'checked':false} />
                            <span className="slider round"></span>
                        </label>
                        <span>Active</span>
                    </div>
                </Box>
                <Box title="Brand Key">
                    <Input name="brandKey" value={brandKey} onChange={e => setbrandKey(e.target.value)} />
                </Box>
                {
                    ["admin", "developer"].includes(user.role) &&
                    <Box title='Builder'>
                        <Select
                            value={activeBuilder}
                            defaultValue={activeBuilder}
                            onChange={assignBuilder}
                            options={builders}
                        />
                    </Box>
                }
                <Box title="Meta">
                    <Button className="btn-primary btn" type="submit" title="Click here to Add App">Submit</Button>
                </Box>
            </div>
        </form>
    </div>
}
