import { useComponent, useElement, useService } from '../../../hooks'
import { useCompanyProfile } from './hooks'

export const CompanyProfile = () => {
    const {getActiveSubscription} = useService('User')
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const SubscriptionData = useComponent('SubscriptionData')
    const {
        profileImageOptions,
        showProfileImageOptions,
        viewPhoto,
        selectFiles,
        updateProfile,
        setuserData,
        userData,
        activeImage,
    } = useCompanyProfile()
    
    return <>
        <div className="right-side-profile-content">
            <h1>Profile</h1>
            <div className="profile-sec wrapper">
                <div className="profile-name-section">
                    <div className="form-box">
                        <div className="profile-avatar">
                            <div className="profile-image">
                                <Img src={activeImage} alt="active"/>
                                <div className="profile-image-hover-shadow" onClick={profileImageOptions}>
                                    <div className="profile-image-hover-icon"><i className="hi-camera"></i></div>
                                    <div className="profile-image-hover-text">Change profile Photo</div>
                                </div>
                                {
                                    showProfileImageOptions &&
                                    <div className="profile-upload-option">
                                        <ul>
                                            <li onClick={viewPhoto}>View Image</li>
                                            <li><label htmlFor="choose-file">Upload Image</label></li>
                                        </ul>
                                        <Input type="file" className="hidden" onChange={selectFiles} id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/png,image/webp,image/jpg" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="name-sec">
                            <h4>{userData.name}</h4>
                            <p>{userData.email}</p>
                        </div>
                        {
                            getActiveSubscription() !== null &&
                            <div className="subscription-dashbord">
                                <p className="subs-heading">Active Subscription : </p>
                                <span className="pointer" onClick={() => openModal("get-subscription-modal")}>{getActiveSubscription()}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="form-editable-section">
                    <div className="form-box">
                        <div className="account-detail">
                            <div className="col-9">
                                <h3>Account Details</h3><hr/>
                                {!['manager', 'company', 'branch', 'representative'].includes(userData.role) ? <form noValidate onSubmit={updateProfile}>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Email <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input placeholder="email@yourcompany.com" type="email" className="input" required name="email" value={userData.email||''} onChange={e => setuserData({...userData, email: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Name <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input placeholder="Full Name" className="input" required name="name" value={userData.name||''} onChange={e => setuserData({...userData, name: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">GSTIN</label>
                                        <div className="col-9 controls">
                                            <Input placeholder="GSTIN" className="input" required name="gstin" value={userData.gstin||''} onChange={e => setuserData({...userData, gstin: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Mobile Phone <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <p className="input">{userData.mobile}</p>
                                        </div>
                                    </div>
                                    <Button type="submit" className="btn-primary  btn btn-submit-profile">Submit</Button>
                                </form>:
                                <>
                                    <div>
                                        <label className="bold">Email: </label>
                                        <p>{userData.email}</p>
                                    </div>
                                    <div>
                                        <label className="bold">Name: </label>
                                        <p>{userData.name}</p>
                                    </div>
                                    <div>
                                        <label className="bold">GSTIN: </label>
                                        <p>{userData.gstin}</p>
                                    </div>
                                    <div>
                                        <label className="bold">Mobile: </label>
                                        <p>{userData.mobile}</p>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal preview-modal" id="preview-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("preview-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <Img src={activeImage} className="mauto" alt="preview" />
                </div>
            </div>
        </div>
        <div className="modal" id="get-subscription-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <SubscriptionData activeSubscription={getActiveSubscription()} />
                </div>
            </div>
        </div>
    </>
}
