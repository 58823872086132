export const TableHeader = ({data}) => {
    return <thead>
        <tr>{
            data?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    </thead>
}