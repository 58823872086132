import { bottomNav, brandInfo } from "../../../constant"
import { useElement } from "../../../hooks"
import './style.scss'

export const CopyrightSection = () => {
    const Link = useElement('Link')
    const renderBottomNav = () => {
        return bottomNav.map((e, i) => <li key={i}><Link href={e.link}>{e.label}</Link></li>)
    }
    return <section>
        <div className="jumbo-container flex">
            <ul className="bottom-nav">
                {renderBottomNav()}
            </ul>
            <div className="copyright-area">
                <div className="copyright-area-inner">Copyright &copy; {new Date().getFullYear()} {brandInfo.companyName} | All rights reserved.</div>
            </div>
        </div>
    </section>
}