import {brandInfo, mainUrl} from '../../../constant'
import { useProjectList } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'
import { NavigatorContext } from '../../../context'
import { useContext } from 'react'

export const ProjectList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {getMonthName} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Banner = useWidget('Banner')
    const Share = useComponent('Share')
    const {
        activeShownTotal,
        projectList,
    } = useProjectList()
    
    const renderProjectList = projectFiles => {
        return projectFiles.slice(0, activeShownTotal).map(( item, index ) => {
            return item.status === 'release' && <div className="project-single-item" key={index}>
                <div>
                    <div>
                        <div className="thumb-area-wrap">
                            <div className="thumb-area">
                                <div className="thumb">
                                    {
                                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                        <Img src={'product-placeholder.png'} alt={item.title} />:
                                        <Img className="pointer" onClick={() => navigateTo({route: '/project/'+item.slug})} src={JSON.parse(item.image)[0]} alt={item.title} />
                                    }
                                </div>
                                <div className="date">
                                    <span className="day">{new Date(item.releaseDate).getDate()}</span>
                                    <span className="month">{getMonthName(new Date(item.releaseDate).getMonth())}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="content-box" className="content">
                            <h4 className="title"><Link href={"/project/"+item.slug}>{item.title}</Link></h4>
                            <div className="project-meta">
                                <ul className="list-wrap">
                                    <li>
                                        <p className="author">{brandInfo.name.capital}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="pv30">
                                <Html content={item.excerpt} />
                            </div>
                            <div className="read-more-area">
                                <div className="read-more">
                                    <Link href={"/project/"+item.slug}>Read More</Link>
                                </div>
                                <Share shareUrl={mainUrl+"/project/"+item.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    return <>
        <Banner title='Project' image="project.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt40 pb40">
                { renderProjectList(projectList || []) }
            </div>
        </div>
    </>
}
