import { useRun } from './hooks';

export const Run = () => {
    const {
        jump,
        player,
        obstacle,
    } = useRun()
    
    return <>
        <div className='game-box'>
            <div className='control-panel' onClick={jump}></div>
            <div className='display-board'></div>
            <div className='game-console'>
                <div className='floor'></div>
                <div className='player' ref={player}></div>
                <div className='obstacle' ref={obstacle}></div>
            </div>
        </div>
    </>
}
