import './style.scss';
import { useFranchiseWithUs } from './hooks'
import { useComponent, useElement, useWidget } from '../../../hooks'

export const FranchiseWithUs = () => {
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Banner = useWidget('Banner')
    const PropertyRequirements = useComponent('PropertyRequirements')
    const {
        sendFranchiseRequest,
        name,
        setname,
        email,
        setemail,
        mobile,
        setmobile,
        city,
        setcity,
        pinCode,
        setpinCode,
        message,
        setmessage,
        scrollToApplyForFranchise,
    } = useFranchiseWithUs()

    return <>
        <Banner title='Become Partner' buttonAlign='center' align='right' image="franchise-banner.jpg" cta={{label: 'Apply', onClick: scrollToApplyForFranchise}} />
        <section className="franchise-with-us mb60-mbs40 mt40">
            <div className="wrapper jumbo-container">
                <div className="section-title-wrap">
                    <div className="section-title">
                        <h2 className="heading-02">Why Partner with Us?</h2>
                    </div>
                    <ul className='franchise-benefits'>
                        <li>Low initial investment</li>
                        <li>Quick pay back period</li>
                        <li>High return on investment</li>
                        <li>No need to involve in day-to-day operations</li>
                        <li>Marketing support & Lead generation (Online & Offline)</li>
                        <li>Online monitoring through franchise dashboard</li>
                        <li>Back-end support through CarMitra</li>
                        <li>Company level tie-ups with established brands</li>
                        <li>In-house training of detailers to ensure Quality</li>
                        <li>Product research at IISER (Indian Institute of Science Education & Research)</li>
                    </ul>
                </div>
                <Img src="baecome-partner-person.jpg" />
            </div>
            <div className='wrapper jumbo-container about-franchise mt40'>
                <div className='flex'>
                    <Img src='baecome-partner-2.jpg' />
                    <div className='content'>
                        <h4>We work on FOCO Model.</h4>
                        <p>FOCO stands for Franchise Owned Company Operated. It's a franchise model where the investor puts all the money in, and the company handles the rest. The company gives the franchise investor its brand name for a non-refundable sum and for a pre-agreed time period. The franchisee owns the property and is responsible for all additional pinCode expenditures. The franchising company manages the store/outlet operations.</p>
                    </div>
                </div>
                <div className='flex'>
                    <Img src='onetime-investment.jpg' />
                    <div className='content'>
                        <h4>One Time Investmet:</h4>
                        <p>Investing in a car detailing franchise can be a lucrative opportunity for entrepreneurs looking to enter the automotive industry. Unlike other business models, a car detailing franchise offers a one-time investment that provides several advantages, including brand recognition, training and support, and a proven business model.</p>
                    </div>
                </div>
            </div>
            <div className='wrapper mt40'>
                <Button className="btn btn-primary mauto mt20 btn-big" onClick={scrollToApplyForFranchise}>Apply</Button>
            </div>
        </section>
        <PropertyRequirements />
        <section className='franchise-display-media'>
            <div className='jumbo-container'>
                <div className="section-title">
                    <h2 className="heading-02">Our Detailing Stores</h2>
                </div>
            </div>
            <div className='jumbo-container wrapper'>
                <div className='video-box'>
                    <Img src="franchise-media1-min.jpg" />
                    <i className='hi-play_circle_filled'></i>
                </div>
                <div className='grid-box'>
                    <Img hasZoom src="franchise-media2-min.jpg" />
                    <Img hasZoom src="franchise-media4-min.jpg" />
                    <Img hasZoom src="franchise-media3-min.jpg" />
                    <Img hasZoom src="franchise-media5-min.jpg" />
                </div>
            </div>
        </section>
        <div id="apply-for-franchise" className="apply-for-franchise body-background franchise-page" style={{minHeight:1}}>
            <div className="jumbo-container">
                <div className='card wrapper'>
                    <form onSubmit={sendFranchiseRequest} noValidate>
                        <div className="title-section">
                            <h1 className='heading-02'>Become our Partner</h1>
                            <p>Take your first step towards Automotive Entrepreneurship!</p>
                        </div>
                        <div className="contact-form-section">
                            <div className="form-group input-box">
                                <Input className="input" value={name} onChange={e => setname(e.target.value)} name="Name" placeholder="Full Name" />
                            </div>
                            <div className='form-block'>
                                <div className="form-group input-box">
                                    <Input className="input" value={email} onChange={e => setemail(e.target.value)} type="email" name="Email" placeholder="Email" />
                                </div>
                                <div className="form-group input-box">
                                    <Input className="input" value={mobile} onChange={e => setmobile(e.target.value)} type="tel" name="Mobile" placeholder="Mobile" />
                                </div>
                            </div>
                            <div className='form-block'>
                                <div className="form-group">
                                    <Input className="input" value={city} onChange={e => setcity(e.target.value)} name="city" placeholder="Interested City" />
                                </div>
                                <div className="form-group">
                                    <Input className="input" value={pinCode} onChange={e => setpinCode(e.target.value)} name="pinCode" placeholder="Location Preference For Franchise" />
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea className="input textarea" value={message} onChange={e => setmessage(e.target.value)} name="message" placeholder="Other Details" />
                            </div>
                            <Button className="btn btn-primary" type="submit">Submit</Button>
                        </div>
                    </form>
                    <Img src='partnership-form.jpg' />
                </div>
            </div>
        </div>
    </>
}
