import { useComplaint } from './hooks'
import { useElement, useService, useWidget } from '../../../hooks'
import './style.scss'
export const Complaint = () => {
    const {isModuleEnabled} = useService('Misc')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Banner = useWidget('Banner')
    const {
        setmessage,
        message,
        setproductName,
        productName,
        renderComplaintTypes,
        setorderId,
        orderId,
        setnumber,
        number,
        email,
        setemail,
        name,
        setname,
        sendComplaintRequest,
    } = useComplaint()
    return <>
        <Banner title='Complaint' image="complaint.jpeg" />
        <div className="body-background" style={{minHeight:1}}>
            <div className="jumbo-container">
                <div className="contact-page complaint-page">
                    <form className="right-form-section" onSubmit={sendComplaintRequest} noValidate>
                        <div className="title-section">
                            <h1>We are here to assist you!</h1>
                            <p>Please complete the form below for your complaints.</p>
                        </div>
                        <div className="contact-form-section">
                            <div className="form-group">
                                <label>Full Name <sup>*</sup></label>
                                <Input className="input" value={name} onChange={e => setname(e.target.value)} name="Name" placeholder="Enter Full Name" />
                            </div>
                            <div className="form-group">
                                    <label>Email <sup>*</sup></label>
                                <Input className="input" value={email} onChange={e => setemail(e.target.value)} type="email" name="Email" placeholder="Enter Email" />
                            </div>
                            <div className="form-group">
                                    <label>Mobile No. <sup>*</sup></label>
                                <Input className="input" value={number} onChange={e => setnumber(e.target.value)} type="number" name="number" placeholder="Enter Mobile number" />
                            </div>
                            {isModuleEnabled(["Ecommerce"]) && <div className="form-group">
                                <label>Order Id</label>
                                <Input className="input" value={orderId} onChange={e => setorderId(e.target.value)} type="orderId" name="OrderId" placeholder="Enter Order Id" />
                            </div>}
                            <div className="form-group complaint-type-check">
                                { renderComplaintTypes() }
                                <Input className="input" value={productName} onChange={e => setproductName(e.target.value)} type="productName" name="ProductName" placeholder="Enter Product/service Name" />
                            </div>
                            <div className="form-group">
                                <label>Message <sup>*</sup></label>
                                <textarea className="input textarea" value={message} onChange={e => setmessage(e.target.value)} name="message" placeholder="Enter Message" />
                            </div>
                            <Button className="btn btn-primary" type="submit">Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
