import Select from 'react-select'
import { useAdminState } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const AdminState = () => {
    const {slugify} = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        stateTypes,
        trackScrolling,
        openAddModal,
        tableContent,
        activeShownTotal,
        trashState,
        trashCancel,
        showTrashConfirm,
        closeModal,
        mode,
        addState,
        setactiveItem,
        activeItem,
        assignStateType,
    } = useAdminState()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">State <Button onClick={openAddModal} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashState} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal state-modal" id="state-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                    setactiveItem(null)
                    closeModal("state-modal")
                }}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="state-modal-header p20">
                        <div className="heading">{mode} State</div>
                        {activeItem && <form noValidate onSubmit={addState}> 
                            <div className="form-group mb20">
                                <Input className="input" required name="name" value={activeItem.name} onChange={e => setactiveItem({...activeItem, name: e.target.value, code: slugify(e.target.value)}) } />
                                <label>Name</label>
                                <small className="msg text-danger">Please enter a Name</small>
                            </div>
                            <div className="form-group mb20">
                                <Input className="input" required name="code" value={activeItem.code} onChange={e => setactiveItem({...activeItem, code: e.target.value}) } />
                                <label>Code</label>
                                <small className="msg text-danger">Please enter a Code</small>
                            </div>
                            <div className="form-group mb20">
                                <Select
                                    value={activeItem?.type}
                                    defaultValue={activeItem?.type}
                                    onChange={assignStateType}
                                    options={stateTypes}
                                />
                                <label>Type</label>
                            </div>
                            <div className="flex-box relative mb20">
                                <span>Inactive</span>
                                <label className="switch">
                                    <Input type="checkbox" onChange={() => setactiveItem({...activeItem, status: !activeItem.status})} checked={activeItem.status?'checked':false} />
                                    <span className="slider round"></span>
                                </label>
                                <span>Active</span>
                            </div>
                            <div className="buttons">
                                <Button type="submit" title="Click here to Add State" className="btn btn-primary btn-small">Submit</Button>
                            </div>
                        </form>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
