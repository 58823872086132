import { useContext, useEffect, useState } from 'react'
import { base, config, searchRegex, usageOption } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext } from '../../../../context'

export const useMedia = () => {
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {
        fetchAll,
        save,
        remove,
        update,
    } = useService('Data', 'media')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        getBase64,
        generateUID,
        closeModal,
        showAlert,
        openModal,
        hasImageExtension,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [viewMode, setviewMode] = useState('grid')
    const [tableContent, settableContent] = useState({})
    const [activeUsage, setactiveUsage] = useState(null)
    const [activeImage, setactiveImage] = useState(false)
    const [activeItem, setactiveItem] = useState(null)
    const [file, setfile] = useState([])
    const [uploadCount, setuploadCount] = useState(0)
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [fileObj, setfileObj] = useState([])
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [trashAction, settrashAction] = useState(false)
    const [searchKey, setsearchKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Media', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[2] === 'trash')
            settrashAction(true)
        getMedias()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const selectFiles = async (e) => {
        let fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            const result = await getBase64(fileObj[0][i])
            let fileData = {
                url: URL.createObjectURL(fileObj[0][i]),
                data: result,
                type: fileObj[0][i].type,
                fileName: fileObj[0][i].name,
                size: fileObj[0][i].size
            }
            if (fileData.size <= 4000000)
                fileArray.push(fileData)
            else showAlert({ type: 'error', msg: 'File is too large.' })
            setfile(fileArray)
            setfileArray(fileArray)
        }
    }
    
    const uploadFiles = e => {
        e.preventDefault()
        for (let i = 0; i < file.length; i++) {
            uploadCall(file[i])
            setTimeout(() => {
                if (i === file.length-1) {
                    setfile([])
                    setfileArray([])
                }
            }, 1000)
        }
    }
    
    const getMedias = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            tableData = tableData.filter(e => e.fileName.includes('.'))
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No media added yet!' })
            else {
                settableData(tableData)
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1)
                        activefilterValue = e.value
                })
                filterItems( activefilterValue, activeFilterIndex, tableData)
            }
        }
    }
    
    const uploadCall = async file => {
        let error = false,
        derivedUploadCount = uploadCount,
        errorMsg = ''
        if (!error) {
            file.uid = generateUID()
            const result = await save(file)
            if (result.status) {
                derivedUploadCount++
                setuploadCount(derivedUploadCount)
                getMedias()
                if (derivedUploadCount === fileArray.length) {
                    setfileArray([])
                    setfile([])
                    setuploadCount(0)
                }
                showAlert({ type: 'success', msg: 'Media added successfully!' })
            } else showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const trash = async uid => {
        const result = await remove({ uid })
        if (result.status) {
            getMedias()
            showAlert({ type: 'success', msg: 'Media deleted successfully!' })
        } else showAlert({ type: 'error', msg: 'Unable to delete media!' })
    }
    
    const updateMedia = async media => {
        delete media._id
        let data = {
            query: JSON.stringify({uid: media.uid}),
            mediaData: JSON.stringify(media)
        }
        const result = await update(data)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Media updated successfully!' })
            setactiveImage(null)
            closeModal('media-modal')
            getMedias()
        } else showAlert({ type: 'error', msg: 'Unable to update media!' })
    }
    
    const settingOpen = item => {
        let activeUsage = null
        usageOption.forEach(e => {
            if (e.value === item.usage) activeUsage = e
        })
        setactiveImage(item)
        setactiveUsage()
        openModal('media-modal')
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                srNo: index + 1,
                content: hasImageExtension(item.fileName) || item.type === "image/webp" || item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png" || item.type === "image/x-png" || item.type === "image/gif" || typeof item.type=== 'undefined' || item.type === '' ?
                <Img alt={item.fileName} src={item.fileName} hasZoom />:
                <video className="video-container video-container-overlay" autoPlay={false} loop>
                    <source src={base.imageBaseUrl+item.fileName} type={item.type} />
                </video>,
                fileName: item.fileName,
                uid: item.uid,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => trash(item.uid)} />
                    <Actor type='setting' onClick={() => settingOpen(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.fileName.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const setMediaUsage = e => {
        setactiveImage({...activeImage, usage: e.value})
        setactiveUsage(e)
    }

    const filterItems = ( key, activeFilterIndex, tableData ) => {
        let filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                if (key === 'image') {
                    return typeof e.type === "undefined" || (e.type).includes(key)
                } else {
                    return e.type && (e.type).includes(key)
                }
            })
        }
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Sr No',
                    key: 'srNo'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Name',
                    key: 'fileName'
                },
                {
                    label: 'Id',
                    key: 'uid'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList])
    return {
        activeShownTotal,
        setactiveItem,
        settingOpen,
        trackScrolling,
        trashAction,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        setviewMode,
        searchKey,
        search,
        resetSearch,
        selectFiles,
        fileArray,
        uploadFiles,
        viewMode,
        filteredList,
        tableContent,
        activeImage,
        setactiveImage,
        activeUsage,
        setMediaUsage,
        trash,
        updateMedia,
    }
}