import { useComponent, useElement, useService } from '../../../hooks'
import { useCarModelManufacturerList } from './hooks'

export const CarModelManufacturerList = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Button = useElement('Button')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashOne,
        manufacturerCancel,
        showTrashConfirm,
        setname,
        setuid,
        uploadCall,
        name,
        addCarManufacturerName,
        slug,
        setslug,
        uploaded,
        image,
        setimage,
        selectFiles,
    } = useCarModelManufacturerList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Car Manufacturers <Button onClick={() => {
            setmode('Add')
            openModal("manufacturer-modal")
        }} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashOne} trashCancelAction={manufacturerCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal manufacturer-modal" id="manufacturer-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                setname('')
                setslug('')
                setuid('')
                closeModal("manufacturer-modal")
            }}>
                <i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="manufacturer-modal-header">
                        <div className="modal-header p0">
                            <div className="heading">Add Manufacturer</div>
                            <form noValidate onSubmit={uploadCall}> 
                                <div className="form-group mb40">
                                    <Input className="input" placeholder="Please enter a Car Manufacturer Name" required name="name" value={name}
                                    onChange={addCarManufacturerName} />
                                    <label>Name</label>
                                    <small className="msg text-danger">Please enter a Car Manufacturer</small>
                                </div>
                                <div className="form-group mb40">
                                    <Input className="input" placeholder="Please enter slug" required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                                    <label>Slug</label>
                                    <small className="msg text-danger">Please enter slug</small>
                                </div>
                                { image !== '' && <div className="form-group mb40">
                                    <label>Image</label>
                                    <Img className='input-img' src={ uploaded ? image.url : image} />
                                </div>}
                                <div className="form-group mb40">
                                    <label htmlFor="choose-file" className="btn btn-blank btn-small">Choose Files</label>
                                    <div className='clearfix'></div>
                                    <Input className="hidden" placeholder="Please select image" required name="image" value={image} onChange={e => setimage(e.target.value)} />
                                    <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/webp" />
                                </div>
                                <div className="buttons">
                                    <Button type="submit" title="Click here to Add Car Manufacturer" className="btn btn-primary btn-small">Submit</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
