import { useEffect, useState } from "react"
import { useService } from "../../../hooks"
import { searchRegex } from "../../../constant"

export const useCarSelector = ({
    activeCar,
    getNewCars,
    getSelectedCar,
}) => {
    const {fetchByAttr} = useService('Data', 'carModel')
    const {
        getLoggedInUserData,
        isUserLoggedIn,
    } = useService('User')
    const {
        closeModal,
        setActiveCar,
        getActiveCar,
    } = useService('Misc')
    const {
        getLocationData,
    } = useService('Router')
    const {
        getCarManufacturers: getManufacturers,
        getCars,
    } = useService('Car')
    const targetModal = "car-select-modal"
    const [searchCarKey, setsearchCarKey] = useState('')
    const [carSeleted, setcarSeleted] = useState(false)
    const [carManufactureList, setcarManufactureList] = useState([])
    const [filteredManufacturerList, setfilteredManufacturerList] = useState([])
    const [selectedCar, setselectedCar] = useState({})
    const [carList, setcarList] = useState([])
    const [userCarList, setuserCarList] = useState([])
    const [showCarSelectModal, setshowCarSelectModal] = useState(false)
    const [showCarListModal, setshowCarListModal] = useState(false)

    const getCarManufacturers = async () => {
        const result = await getManufacturers()
        if (result.status) {
            if (result.data?.length !== 0) {
                setcarManufactureList(result.data)
                setfilteredManufacturerList(result.data)
            }
        }
    }
    
    const getCarManufacturerCars = async manufacturer => {
        const result = await fetchByAttr({manufacturer})
        if (result.data?.length !== 0) {
            setcarList(result.data)
            setshowCarSelectModal(false)
            setshowCarListModal(true)
            closeModal('car-select-modal')
        }
    }
    
    const getCarsData = async () => {
        const result = await getCars({ownerId: getLoggedInUserData().uid})
        if (result.status) {
            if (result.data?.length !== 0) {
                setuserCarList(result.data)
                let activeCar = getActiveCar() ? getActiveCar() : null
                setselectedCar(activeCar)
                setcarSeleted(true)
                setActiveCar(activeCar)
            }
        }
    }
    const openCarList = fileName => {
        getCarManufacturerCars(fileName)
    }
    
    const showSelectedCar = (activeCar, carSelector = 'old') => {
        if (carSelector === 'new' && !getNewCars) {
            setselectedCar(activeCar)
        } else {
            setselectedCar(activeCar)
            setshowCarListModal(false)
        }
        setcarSeleted(true)
        setActiveCar(activeCar)
        getSelectedCar(activeCar)
        if ((getLocationData().href.includes('service')) || (getLocationData().href.includes('packages')))
            getLocationData().reload()
    }
    
    const deselectCar = () => {
        setcarSeleted(false)
        localStorage.removeItem('activeCar')
        if ((getLocationData().href.includes('service')) || (getLocationData().href.includes('packages')))
            getLocationData().reload()
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchCarKey(e.target.value)
            let tempTitle = '', filteredManufacturerList = []
            carManufactureList.forEach(e => {
                tempTitle = e.name.toLowerCase()
                if (tempTitle.search(searchCarKey.toLowerCase()) !== -1) {
                    filteredManufacturerList.push(e)
                }
            })
            setfilteredManufacturerList(filteredManufacturerList)
        }
    }

    const hideCarSelectModal = () => setshowCarSelectModal(false)

    const hideCarListModal = () => setshowCarListModal(false)

    const selectCar = () => {
        if (isUserLoggedIn() && !getNewCars && userCarList.length!== 0)
            setshowCarListModal(true)
        else if (targetModal === "car-select-modal")
            setshowCarSelectModal(true)
        else
            setshowCarListModal(true)
    }

    useEffect(() => {
        if(carManufactureList.length === 0)
            getCarManufacturers()
        let selectedCar = null
        if (selectedCar !== null)
            selectedCar = getActiveCar()
        if (selectedCar !== null) {
            setselectedCar(selectedCar)
            setcarSeleted(true)
        }
        if (isUserLoggedIn() && !getNewCars)
            getCarsData()
        if (activeCar !== selectedCar && activeCar !== null && activeCar) {
            setselectedCar(activeCar)
            setcarSeleted(true)
        }
    }, [])

    useEffect(() => {
        if (activeCar !== selectedCar && activeCar !== null && activeCar) {
            setselectedCar(activeCar)
            setcarSeleted(true)
        }
    }, [activeCar])

    return {
        openCarList,
        showSelectedCar,
        deselectCar,
        search,
        hideCarSelectModal,
        hideCarListModal,
        selectCar,
        carSeleted,
        selectedCar,
        userCarList,
        carList,
        showCarSelectModal,
        searchCarKey,
        filteredManufacturerList,
        showCarListModal,
    }
}