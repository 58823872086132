import { socialLinks } from "../../../../constant"

export const useFooter = () => {
    const socialMediaAccounts = [
        {link: socialLinks.fb, icon:"hi-facebook1"},
        {link: socialLinks.twitter, icon:"hi-twitter"},
        {link: socialLinks.insta, icon:"hi-instagram"},
        {link: socialLinks.linkedin, icon:"hi-linkedin"}
    ]

    const backToTop = (duration = 1000) => {
        if (document.scrollingElement.scrollTop === 0) return
        const totalScrollDistance = document.scrollingElement.scrollTop
        let scrollY = totalScrollDistance, oldTimestamp = null
        function step (newTimestamp) {
            if (oldTimestamp !== null) {
                scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration
                if (scrollY <= 0) return document.scrollingElement.scrollTop = 0
                document.scrollingElement.scrollTop = scrollY
            }
            oldTimestamp = newTimestamp
            window.requestAnimationFrame(step)
        }
        window.requestAnimationFrame(step)
    }
    return {
        socialMediaAccounts,
        backToTop,
    }
}