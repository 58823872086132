import { useEffect } from "react"
import { useService } from "../../../hooks"

export const useGuestCarQueryModal = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')

    useEffect(() => {
        openModal("first-time-modal")
    }, [])
    
    const closeFirstTimeModal = () => closeModal("first-time-modal")

    const openCarSelectModal = () => {
        closeFirstTimeModal()
        openModal('car-select-modal')
    }
    return {
        openCarSelectModal, closeFirstTimeModal
    }
}