import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,garageoption} from '../../../constant'
import { useAddGarage } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddGarage = () => {
    const DatePicker = useElement('DatePicker')
    const Editor = useElement('Editor')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        pageTitle,
        addGarage,
        title,
        addTitle,
        slug,
        setslug,
        address,
        setaddress,
        businessinformation,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        image,
        setimage,
        tagOptions,
        settag,
        tag,
        categoryOptions,
        setcategory,
        category,
        setstatus,
        activeStatus,
        status,
        yearEstablished,
        setyearEstablished,
        setphoneNumber,
        phoneNumber,
        setemail,
        email,
        setbusinessinformation,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
    } = useAddGarage()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/garage/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addGarage}>
            <div className="left">
                <Box title="Title" required>
                    <Input autoFocus required name="title" value={title} onChange={addTitle} />
                </Box>
                <Box title="Slug" required>
                    <Input required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                </Box>
                <Box title="Address" required>
                    <Input required name="address" value={address} onChange={e => setaddress(e.target.value)} />
                </Box>
                <Box title="Business Information" required>
                    <Editor
                        value={businessinformation}
                        initialValue={businessinformation? businessinformation: "<p>This is the demo mail business information.</p>"}
                        onEditorChange={(businessinformation) => setbusinessinformation(businessinformation)}
                    />
                </Box>
                <Box title="CSS">
                    <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={e => setcss(e.target.value)}></textarea>
                </Box>
                <Box title="JS">
                    <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={e => setjs(e.target.value)}></textarea>
                </Box>
                <Box title="Canonical URL">
                    <Input required name="canonical" value={canonical} onChange={e => setcanonical(e.target.value)} />
                </Box>
                <Box title="Robots">
                    <Select
                        value={activeRobots}
                        defaultValue={activeRobots}
                        onChange={e => setrobots(e.value)}
                        options={robotsOption}
                    />
                </Box>
                <Box title="Meta Description">
                    <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={e => setmetaDescription(e.target.value)}></textarea>
                </Box>
                <Box title="Meta Keywords">
                    <CreatableSelect
                        isMulti
                        onChange={e => setmetaKeyword(e)}
                        options={keywordOptions}
                        value={metaKeyword}
                    />
                </Box>
            </div>
            <div className="right">
                <Box title="Meta">
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={toggleRenderSlug}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                <Box title="Email" required>
                    <Input required name="email" value={email} onChange={e => setemail(e.target.value)} />
                </Box>
                <Box title="Phone Number" required>
                    <Input required name="phoneNumber" value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} />
                </Box>
                <Box title="Year Establised">
                    <DatePicker
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeFormat="HH:mm"
                        onChange={yearEstablished => setyearEstablished(yearEstablished)}
                        selected={yearEstablished}
                    /> 
                </Box>
                <Box title="Status">
                    <Select
                        value={status}
                        defaultValue={activeStatus}
                        onChange={e => setstatus(e)}
                        options={garageoption}
                    />
                </Box>
                <Box title="Category">
                    <CreatableSelect
                        isMulti
                        value={category}
                        onChange={e => setcategory(e)}
                        options={categoryOptions}
                    />
                </Box>
                <Box title="Tags">
                    <CreatableSelect
                        isMulti
                        value={tag}
                        onChange={e => settag(e)}
                        options={tagOptions}
                    />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['garage']} />
            </div>
        </form>
    </div>
}
