import { useState } from "react"
import { useService } from "../../../hooks"
import { searchRegex } from "../../../constant"

export const useStoreLocatorSideBar = () => {
    const {fetchByAttr} = useService('Data', 'franchise')
    const [franchiseList, setfranchiseList] = useState([])
    const [filteredFranchiseList, setfilteredFranchiseList] = useState([])
    const [searchFranchiseKey, setsearchFranchiseKey] = useState('')

    const getFranchisesData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            setfranchiseList(result.data)
            setfilteredFranchiseList(result.data)
        }
    }
    
    const searchFranchise = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchFranchiseKey(e.target.value)
            let tempTitle = '', filteredFranchiseList = []
            franchiseList.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchFranchiseKey.toLowerCase()) !== -1) {
                    filteredFranchiseList.push(e)
                }
            })
            setfilteredFranchiseList(filteredFranchiseList)
        }
    }

    const fetchFranchiseData = () => {
        if (franchiseList.length === 0)
            getFranchisesData()
    }
    return {
        fetchFranchiseData,
        filteredFranchiseList,
        searchFranchiseKey,
        searchFranchise
    }
}