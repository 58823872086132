import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useOfferList } from '../../admin/OfferList/hooks'
import { useElement, useService, useWidget } from '../../../hooks'

export const Offers = () => {
    const {isValidJson} = useService('Validation')
    const {getLoggedInUserData} = useService('User')
    const {
        addDays,
        toIndianCurrency,
        openModal,
        closeModal,
        showAlert,
        isEmpty,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Banner = useWidget('Banner')
    const {
        setactiveOffer,
        offerList,
        activeOffer,
    } = useOfferList()
    const renderOfferList = offerFiles => {
        return offerFiles.map(( item, index ) => {
            let foundUser = false
            if (item?.offerForUser && isValidJson(item?.offerForUser)) {
                if (item.offerForUser !== 'null' && item.offerForUser !== null) {
                    JSON.parse(item.offerForUser).forEach(e => {
                        if (getLoggedInUserData() && e.value === getLoggedInUserData().uid)
                            foundUser = true
                    })
                }
            }
            if (
                item?.offerRestriction &&
                (item.offerRestriction === 'public' || (item.offerRestriction === 'private' && foundUser )) &&
                item.status !== 'expired' &&
                new Date().getTime() < addDays(new Date(item.expiryDate), 1).getTime() &&
                !item.trash
            )
                return <div className={( index % 2 !== 0 )?"offerBox offerBoxDark":"offerBox offerBoxOrange"} key={index}>
                    <div className="youget">
                        <p>You Get</p>
                        <span className="percentoff">{ item.discountType === "fixedprice" ? toIndianCurrency(item.discount):item.discount+'%'} Off</span>
                    </div>
                    <div className="imageSection">
                        {
                            isEmpty(item.image)?
                            <Img src={'sale.png'} alt={item.title} />:
                            <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                        }
                    </div>
                    <div className="textSection">
                        <h4>{item.content}</h4>
                        <div className="get-codebtn-holder" onClick={() => {
                            setactiveOffer(item)
                            openModal("offer-modal")
                        }}>
                            <div className="get-offer-code">
                                <div className="offer-get-code-link cpnbtn">
                                    {item.code}
                                    <div className="p1"></div>
                                    <div className="p2">
                                        <div className="t1"></div>
                                        <div className="t1">
                                            <div className="t2"></div>
                                        </div>
                                    </div>
                                    <span>Coupon Code</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            else return true
        })
    }

    return <>
        <Banner title='Offers' image="white-car-banner.jpg"/>
        <div className="jumbo-container">
            <div className="offer-grid">
                { renderOfferList(offerList || []) }
            </div>
        </div>
        <div className="modal" id="offer-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("offer-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        !isEmpty(activeOffer) &&
                        <>
                            <div className="offer-header">
                                <div className="modal-header">
                                    <div className="offer-logo">
                                    {
                                        isEmpty(activeOffer?.image)?
                                        <Img src={'sale.png'} alt={activeOffer.title} />:
                                        <Img src={JSON.parse(activeOffer.image)[0]} alt={activeOffer.title} />
                                    }
                                    </div>
                                    <div className="offer-description">
                                        <div className="title" title={activeOffer.content}>{activeOffer.content}</div>
                                    </div>
                                </div>
                                <div className="down-arrow"></div>
                            </div>
                            <div className="showcoupon">
                                <div className="popup-code-block">
                                    <span className="code-txt">{activeOffer.code}</span>
                                    <span className="copy-btn">
                                        <CopyToClipboard text={activeOffer.code}
                                            onCopy={() => showAlert({ type: 'success', msg: 'Copied!' })}>
                                            <span>COPY CODE</span>
                                        </CopyToClipboard>
                                    </span>
                                </div>
                            </div>
                            <div className="coupon-bottom-text">
                                <span>On cart page paste your code at checkout</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </>
}
