import {CSVLink} from 'react-csv'
import Select from 'react-select'
import { useProductOrders } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const ProductOrders = () => {
    const {closeModal} = useService('Misc')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const {
        trackScrolling,
        filteredList,
        exportedData,
        setstartDate,
        startDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        orderDataTitle,
        orderStatusIndex,
        paymentFail,
    } = useProductOrders()
    const exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="flex title">
            Product Orders
            {
                filteredList.length !== 0 &&
                <span className="btn btn-primary btn-small ml20 export-btn">
                    <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
                </span>
            }
        </h1>
        <div className="filter-box">
            <div className="flex date-filter overflow-visible">
                <div className="form-group">
                    <DatePicker
                        selected={startDate}
                        onChange={startDate => setstartDate(startDate)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="From:"
                    />
                </div>
                <div className="form-group">
                    <DatePicker
                        selected={endDate}
                        onChange={endDate => setendDate(endDate)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="To:"
                    />
                </div>
            <div className="form-group ml10">
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i, tableData)
                    }}
                    options={filterTags}
                />
            </div>
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <div className="modal" id="product-status-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content content medium">
                <Button className="close" onClick={() => closeModal("product-status-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <h2>{orderDataTitle.title}</h2>
                    <div className="track">
                        <div className={orderStatusIndex >= 0? "step active" : "step" }> <span className="icon"><i className="hi-refresh"></i></span> <span className="text">Initialised</span> </div>
                        {
                            paymentFail ?
                            <div className={orderStatusIndex >= 1? "step active" : "step" }> <span className="icon"> <i className='hi-info1'></i> </span> <span className="text"> Payment Fail</span> </div>:
                            <div className={orderStatusIndex >= 2? "step active" : "step"}> <span className="icon"> <i className="hi-money"></i> </span> <span className="text"> Paid </span> </div>
                        }
                        <div className={orderStatusIndex >= 3? "step active" : "step"}> <span className="icon"> <i className="hi-crop_rotate"></i></span> <span className="text">Processing</span> </div>
                        <div className={orderStatusIndex >= 4? "step active" : "step"}> <span className="icon"><i className="hi-delivery_dining"></i> </span> <span className="text">Shipped</span> </div>
                        <div className={orderStatusIndex >= 5? "step active" : "step"}> <span className="icon"> <i className="hi-gift"></i> </span> <span className="text">Delivered</span> </div>
                        <div className={orderStatusIndex >= 6? "step active" : "step"}> <span className="icon"> <i className="hi-check-circle"></i></span> <span className="text">Completed</span> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
