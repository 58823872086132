import { useComponent, useElement, useService } from '../../../hooks'

export const Robots = () => {
    const {preventFormSubmit} = useService('Misc')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Box = useComponent('Box')
    const {setTitle} = useService('Meta')
    setTitle('Robots', 'admin')
    return <>
        <h1 className="title flex">robots.txt <Link href="/robots.txt" target="_blank" title="Click to view"><span className="back-icon"><i className='hi-paper-plane'></i></span></Link></h1>
        <form className="columns" noValidate onSubmit={preventFormSubmit}>
            <div className="left">
                <Box title="File Content">
                    <textarea className="description" formcontrolname="urls" placeholder="Type your robots.txt here"></textarea>
                </Box>
            </div>
            <div className="right">
                <Box title="Meta">
                    <Button className="btn btn-primary has-icon" type="submit" disabled title="Please make some changes to save">Save</Button>
                </Box>
            </div>
        </form>
    </>
}
