import './style.scss';
export const SnakeNLadder = () => {
    const renderBoard = () => {
        return <div className="board">
            {renderRows()}
        </div>
    }
    
    const renderRows = () => {
        return <div className="game-row">
            {renderColumns()}
        </div>
    }
    
    const renderColumns = () => {
        return [...Array(100)].map((_, i) => {
            return <div className="game-column" key={i}>
                <span className='box-number'>{i + 1}</span>
            </div>
        })
    }

    return <div className="game-screen">
        {renderBoard()}
    </div>
}