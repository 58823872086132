import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useNotification = () => {
    const {fetchAll} = useService('Data', 'notification')
    const {setTitle} = useService('Meta')
    const [notificationList, setnotificationList] = useState([])

    useEffect(() => {
        setTitle('My Notifications')
        getNotificationsData()
    }, [])

    const getNotificationsData = async () => {
        const result = await fetchAll()
        if (result.status)
            setnotificationList(result.data)
    }
    return {
        notificationList,
    }
}