export const notificationRoutes = [
    {
        "pathName": "/admin/notification/add",
        "module": ["Notification"],
        "component": "AddNotification",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/notification/edit",
        "module": ["Notification"],
        "component": "AddNotification",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/notification/list",
        "module": ["Notification"],
        "component": "AdminNotifications",
        "type": "admin",
        "mode": "static"
    }
]