import { brandInfo } from '../../../constant'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'

export const Packages = () => {
    const Banner = useWidget('Banner')
    const PackagesData = useComponent('PackagesData')
    const {setTitle} = useService('Meta')
    setTitle('Packages')

    return <>
        <Banner title="PACKAGES AND SERVICES" image="yellow-back-1.jpg"/>
        <div className="pricing-page">
            <div className="jumbo-container">
                <div className="heading-section-pricing">
                    <p className="mt20 heading-table-price-p">Choose the plan that's right for you</p>
                    <p className="mt40">{brandInfo.name.capital} understands and aims at providing affordable and accessible servicing . <br /> Hence there are 3 types of packages designed according to the budget and need of every customer</p>
                    <PackagesData />
                </div>
            </div>
        </div>
    </>
}
