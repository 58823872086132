import { useContext, useEffect, useState } from "react"
import { robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddProduct = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchAll} = useService('Data', 'taxClass')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        isEmpty,
        generateUID,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getProduct,
        getProductCategory,
        getProductBrand,
        addProduct: publishProduct,
        updateProduct,
    } = useService('Product')
    const keywordOptions =  [],
    tagOptions = []
    const [companies, setcompanies] = useState([])
    const [company, setcompany] = useState(null)
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [price, setprice] = useState(0)
    const [image, setimage] = useState('[]')
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [background, setbackground] = useState('#333')
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [featuredVideo, setfeaturedVideo] = useState('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState('')
    const [category, setcategory] = useState(null)
    const [categoryOption, setcategoryOption] = useState([])
    const [brand, setbrand] = useState(null)
    const [brandOptions, setbrandOptions] = useState([])
    const [tag, settag] = useState('')
    const [sku, setsku] = useState('')
    const [hsn, sethsn] = useState('')
    const [stock, setstock] = useState('')
    const [tax, settax] = useState('')
    const [saleprice, setsaleprice] = useState('')
    const [isAssured, setisAssured] = useState(false)
    const [isFeatured, setisFeatured] = useState(false)
    const [inclusiveTax, setinclusiveTax] = useState(true)
    const [taxClassData, settaxClassData] = useState([])
    
    const getUsersData = async () => {
        let companies = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                setcompanies(companies)
            }
        }
}
    
    const getTaxClassesListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            settaxClassData(taxClassData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No taxClass added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    
    const getProductData = async (query) => {
        const result = await getProduct(query)
        if (result.status) {
            const productData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === productData.robots))
            settag(isEmpty(productData.tag) ? [] : JSON.parse(productData.tag))
            setcategory(isEmpty(productData.category) ? [] : JSON.parse(productData.category))
            setmetaKeyword(isEmpty(productData.metaKeyword) ? [] : JSON.parse(productData.metaKeyword))
            settitle(productData.title)
            setmode('Edit')
            setslug(productData.slug)
            setcontent(productData.content)
            setexcerpt(productData.excerpt)
            setcss(productData.css)
            setjs(productData.js)
            setcanonical(productData.canonical)
            setrobots(productData.robots)
            setmetaDescription(productData.metaDescription)
            setstatus(statusOption.find(e => e.value === productData.status))
            setbrand(productData.brand !== null && typeof productData.brand && productData.brand !== 'undefined' ? JSON.parse(productData.brand) : null)
            setpublishDate(new Date(productData.publishDate))
            setisAssured(productData.isAssured)
            setisFeatured(productData.isFeatured)
            setinclusiveTax(productData.inclusiveTax)
            setsku(productData.sku ?? '')
            sethsn(productData.hsn ?? '')
            setfeaturedVideo(productData.featuredVideo ?? '')
            setstock(productData.stock)
            settax(productData.tax)
            setprice(productData.price)
            setsaleprice(productData.saleprice)
            setbackground(productData.background)
            setuid(productData.uid)
            setimage(productData.image)
            setactiveRobotsIndex(activeRobotsIndex)
            if (productData.status === 'private' && productData.company) {
                setcompany(productData.company)
            }
        } else showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    
    const getProductCategoryData = async () => {
        let tableData = []
        const result = await getProductCategory()
        if (result.data?.length !== 0) {
            result.data.forEach(e => {
                tableData.push({label: e.catName, value: e.catSlug})
            })
            setcategoryOption(tableData)
        }
    }
    
    const getProductBrandData = async () => {
        let tableData = []
        const result = await getProductBrand()
        if (result.status) {
            if (result.data?.length !== 0) {
                result.data.forEach(e => {
                    tableData.push({label: e.brandName, value: e.brandSlug})
                })
                setbrandOptions(tableData)
            }
        }
    }
    
    const addProduct = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        productObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            publishDate,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            price,
            brand: brand !== null ? JSON.stringify(brand) : null,
            sku,
            hsn,
            featuredVideo,
            stock,
            isFeatured,
            isAssured: isAssured,
            inclusiveTax,
            tax,
            saleprice,
            image
        }
        if (status.value === 'private' && company) {
            productObject.company = company
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (stock === '') {
            errorMsg = 'Please enter the stock availablity'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (brand === '') {
            errorMsg = 'Please select brand'
            error = true
        } else if (price === 0) {
            errorMsg = 'Please enter price'
            error = true
        }
        if (saleprice !== '') {
            if (Number(price) <= Number(saleprice)) {
                errorMsg = 'Sale Price should be less than MRP'
                error = true
            }
        }
        if (!error) {
            if (mode === 'Add') {
                productObject.uid = generateUID()
                const result = await publishProduct(productObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Product added successfully!' })
                    navigateTo({route: '/admin/product/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add product!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    productData: JSON.stringify(productObject)
                }
                const result = await updateProduct(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Product updated successfully!' })
                    navigateTo({route: '/admin/product/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update product!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        setTitle('Add Product', 'admin')
        getProductCategoryData()
        getProductBrandData()
        getTaxClassesListData()
        setstatus(statusOption[0])
        if (urlData[3] === 'edit') {
            getProductData({uid: urlData[4]})
        }
        getUsersData()
    }, [])

    const addTitle = e => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    const productTitle = mode + ' Product'
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let selectedTaxClass = {}
    if (taxClassData.length>0 && tax === '') {
        selectedTaxClass = taxClassData[0]
    }
    taxClassData.forEach(e => {
        if (e.value === tax) {
            selectedTaxClass = e
        }
    })
    return {
        productTitle,
        addProduct,
        title,
        addTitle,
        slug,
        setslug,
        sku,
        setsku,
        featuredVideo,
        setfeaturedVideo,
        hsn,
        sethsn,
        stock,
        setstock,
        content,
        setcontent,
        excerpt,
        setexcerpt,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        activeRobotsIndex,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        selectedTaxClass,
        settax,
        taxClassData,
        price,
        setprice,
        saleprice,
        setsaleprice,
        setinclusiveTax,
        inclusiveTax,
        setpublishDate,
        publishDate,
        setstatus,
        status,
        company,
        setcompany,
        companies,
        setisFeatured,
        isFeatured,
        setisAssured,
        isAssured,
        category,
        setcategory,
        categoryOption,
        brand,
        setbrand,
        brandOptions,
        tag,
        settag,
        tagOptions,
        image,
        setimage,
    }
}