import { usePage } from './hooks'
import { useElement, useWidget } from '../../hooks'

export const Page = () => {
    const Html = useElement('Html')
    const Banner = useWidget('Banner')
    const {
        title,
        image,
        content,
    } = usePage()

    return <>
        <Banner title={title} image={image[0] ?? "red-back-1.jpg"}/>
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt120 pb100">
                <div className="wrapper">
                    <div className="blog-details-wrap">
                        <div className="blog-details-items">
                            <div className="content bg-none">
                                <h3 className="title">{title}</h3>
                            </div>
                            <div className="blog-details-inner">
                                <Html content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
