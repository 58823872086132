import { useService, useWidget } from '../../../hooks'
import { useFaq } from './hooks'

export const Faq = () => {
    const {isModuleEnabled} = useService('Misc')
    const Banner = useWidget('Banner')
    const {
        setactiveFaq,
        activeFaq,
        activeCategory,
        setactiveCategory,
        category,
        filteredFaqList,
    } = useFaq()
    const renderFaq = faqList => {
        return faqList.map(( item, index ) => {
            return <div className={activeFaq.uid === item.uid ? "accordion active": "accordion"} key={index} onClick={() => setactiveFaq(activeFaq.uid === item.uid? {} : item)}>
                <p className="accordion-title">{item.query} <i className="hi-add-circle"></i></p>
                <div className={activeFaq.uid === item.uid ? "accordion-text active": "accordion-text"}>
                    <p>{item.ans}</p>
                </div>
            </div>
        })
    }
    
    const renderFaqCategory = faqCategory => {
        return faqCategory.map(( item, index ) => {
            return isModuleEnabled(item.module) && <li key={index} className={activeCategory.value === item.value? "active faq-heading "+item.value : "faq-heading "+item.value} onClick={() => setactiveCategory(item)}>
                <div className="heading">
                    <i className={item.icon}></i>
                    <span>{item.label}</span>
                </div>
            </li>
        })
    }

    return <>
        <Banner title="FAQ" image="faq.jpg" />
        <div className="faq-page">
            <div className="jumbo-container">
                <div className="faq-category">
                    <ul>{ renderFaqCategory(category || []) }</ul>
                </div>
                <div className="faq-details">
                    { renderFaq(filteredFaqList || []) }
                </div>
            </div>
        </div>
    </>
}
