import { useElement } from '../../hooks'

export const UserImage = ({user}) => {
    const Img = useElement('Img')
    return <>
        {
            user.image ?
            <Img src={JSON.parse(user.image)[0]} title={user.name} />:
            <i className="hi-user"></i>
        }
    </>
}
