import { useCallback } from "react"
import { useElement, useService } from "../../hooks"

export const CartSingleItem = ({item, removeProduct}) => {
    const Img = useElement('Img')
    const {
        toIndianCurrency,
        isEmpty,
    } = useService('Misc')
    const removeItem = useCallback(() => {
        removeProduct(item)
    }, [])
    return <div className="cart-single-item">
        <div className="item-left">
            {
                item.type === 'servicePackage'?
                <i className="hi-dropbox service-package-icon"></i>:
                item.type === 'subscription'?
                <i className="hi-block service-package-icon"></i>:
                <Img src={item.image} alt={item.title} />
            }
        </div>
        <div className="item-right">
            <div className="item-text">
                <h5 className="heading-05">{item.title}</h5>
                <div className="price priceProductPage">
                    {
                        isEmpty(item.saleprice) || item.saleprice === item.mrp ?
                        <div className="mrp">
                            <p className="black m0">{toIndianCurrency(item.price)} </p>
                            <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                        </div>:
                        <>
                            <div className="mrp">
                                <p className="black m0">{toIndianCurrency(item.price)} </p>
                                <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                            </div>
                            <div className="sale-price">
                                <p className="black">{toIndianCurrency(item.mrp)} </p>
                            </div>
                            <div className="per-off m0">{((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % Off
                            </div>
                        </>
                    }
                </div>
                <div className="price"><span className="quantity_text"> Quantity :</span> {item.purchaseQuantity} </div>
            </div>
        </div>
        <div className="cart-item-close-btn" onClick={removeItem}>×</div>
    </div>
}