import { useContext, useEffect, useState } from "react"
import { config, searchRegex, statusOption } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext, NavigatorContext } from "../../../../context"

export const useCarModelList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'carModel')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const filterTags = statusOption
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState({})
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [trashAction, settrashAction] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('CarModels List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getCarModelListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getCarModelListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No carModels added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No carModels added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectedCarModel = () => {
        deleteCarModel(activeItem.uid)
    }
    
    const deleteCarModel = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                getCarModelListData()
                showAlert({ type: 'success', msg: 'CarModel deleted successfully!' })
                setactiveItem(null)
                showTrashConfirm(false)
                getCarModelListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete carModel!' })
        } else {
            let carModelObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    carModelObject = e
                }
            })
            carModelObject.trash = task === 'trash'
            delete carModelObject._id
            let data = {
                query: JSON.stringify({uid}),
                carModelData: JSON.stringify(carModelObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'CarModel '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getCarModelListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' carModel!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            deleteCarModel(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const cloneCarModel = async item => {
        let carModelObject = { ...item, modelKey: item.modelKey+'-copy', uid: generateUID() }
        const result = await save(carModelObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'CarModel clonsed successfully!' })
            getCarModelListData()
        } else showAlert({ type: 'error', msg: 'Unable to add carModel!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                name: item.name,
                modelKey: item.modelKey,
                Segment: item.Segment,
                image_path: <Img source='carCDN' src={item.image_path} />,
                manufacturer: item.manufacturer,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='undo' onClick={() => deleteCarModel(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/carModel/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => cloneCarModel(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.modelKey?.toLowerCase() + ' ' + e.Segment?.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }

    useEffect(() => {
        let exportedData = []
        filteredList.forEach(e => {
            let ex = {
                name: e.name,
                modelKey: e.modelKey,
                Segment: e.Segment,
                manufacturer: e.manufacturer,
                year_of_manufacture: e.year_of_manufacture,
                start_year: e.start_year,
                end_year: e.end_year
            }
            exportedData.push(ex)
        })
        setexportedData(exportedData)
        settableContent({
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Model Key',
                    key: 'modelKey'
                },
                {
                    label: 'Segment',
                    key: 'Segment'
                },
                {
                    label: 'Image',
                    key: 'image_path'
                },
                {
                    label: 'Manufacturer',
                    key: 'manufacturer'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])

    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        filteredList,
        exportedData,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedCarModel,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}