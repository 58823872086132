export const FranchiseRequestModel = [
    {
        key: 'name',
        dataType: String
    },
    {
        key: 'email',
        dataType: String
    },
    {
        key: 'message',
        dataType: String
    },
    {
        key: 'mobile',
        dataType: String
    },
    {
        key: 'city',
        dataType: String
    },
    {
        key: 'pinCode',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    }
]