import { useContext, useEffect, useState } from "react"
import { fuelOption, packagesOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddManager = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'media')
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getBase64,
        generateUID,
        showAlert,
    } = useService('Misc')
    const {isValidVehicleRegistrationNumber} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getCar,
        addCar,
        updateCar,
    } = useService('Car')
    const user = getLoggedInUserData()
    const [mode, setmode] = useState('Add')
    const [registrationNo, setregistrationNo] = useState('')
    const [registrationDate, setregistrationDate] = useState('')
    const [chassisNo, setchassisNo] = useState('')
    const [engineNo, setengineNo] = useState('')
    const [ownerName, setownerName] = useState(user.name)
    const [vehicleClass, setvehicleClass] = useState('')
    const [activeFuelType, setactiveFuelType] = useState(0)
    const [fuelType, setfuelType] = useState(fuelOption[0])
    const [packageType, setpackageType] = useState(packagesOption[0])
    const [activePackageType, setactivePackageType] = useState(packagesOption[0])
    const [maker, setmaker] = useState('')
    const [fitness, setfitness] = useState('')
    const [mvTax, setmvTax] = useState('')
    const [insurance, setinsurance] = useState('')
    const [pucc, setpucc] = useState('')
    const [emission, setemission] = useState('')
    const [rcStatus, setrcStatus] = useState('')
    const [financed, setfinanced] = useState('')
    const [showForm, setshowForm] = useState(false)
    const [file, setfile] = useState(null)
    const [fileObj, setfileObj] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [userData, setuserData] = useState(null)
    const [uid, setuid] = useState('')
    const [showInsurance, setshowInsurance] = useState(true)
    const [showRc, setshowRc] = useState(true)
    const [rcImage, setrcImage] = useState('')
    const [insuranceImage, setinsuranceImage] = useState('')

    useEffect(() => {
        setTitle('Add Manager')
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[2] === 'edit' || urlData[2] === 'view') {
            let query = {
                uid: urlData[3]
            }
            getCarData(query, urlData[2])
        }
    }, [])

    const getCarData = async (query, mode) => {
        const result = await getCar(query)
        if (result.status) {
            if (result.data?.length > 0) {
                let userData = result.data[0]
                if (typeof userData.rcImage === 'undefined' || userData.rcImage === '' ) {
                    showRc = false
                    userData.rcImage = '[]'
                }
                if (typeof userData.insuranceImage === 'undefined' || userData.insuranceImage === '') {
                    showInsurance = false
                    userData.insuranceImage = '[]'
                }
                setmode(mode)
                setregistrationNo(userData.registrationNo)
                setregistrationDate(userData.registrationDate)
                setchassisNo(userData.chassisNo)
                setengineNo(userData.engineNo)
                setownerName(userData.ownerName)
                setvehicleClass(userData.vehicleClass)
                setmaker(userData.maker)
                setfitness(userData.fitness)
                setmvTax(userData.mvTax)
                setinsurance(userData.insurance)
                setpucc(userData.pucc)
                setfuelType(typeof userData.fuelType === "object"? JSON.parse(userData.fuelType) : fuelOption[activeFuelType])
                setemission(userData.emission)
                setrcStatus(userData.rcStatus)
                setfinanced(userData.financed)
                setuserData(JSON.parse(userData.userData))
                setuid(userData.uid)
                setshowForm(true)
                setrcImage(userData.rcImage)
                setinsuranceImage(userData.insuranceImage)
                setshowRc(showRc)
                setshowInsurance(showInsurance)
            }
        }
    }
    
    const handleChange = fuelType => {
        setfuelType(fuelType)
    }
    
    const addManager = async (e) => {
        e.preventDefault()
        let error = false,
            errorMsg = '',
            carObject = {
                registrationNo: registrationNo,
                registrationDate: registrationDate,
                chassisNo: chassisNo,
                engineNo: engineNo,
                ownerName: ownerName,
                vehicleClass: vehicleClass,
                maker: maker,
                fitness: fitness,
                mvTax: mvTax,
                insurance: insurance,
                pucc: pucc,
                fuelType: JSON.stringify(fuelType),
                emission: emission,
                rcImage: rcImage,
                insuranceImage: insuranceImage,
                rcStatus: rcStatus,
                financed: financed,
                userData: JSON.stringify(userData)
            }
        if (registrationNo === '') {
            errorMsg = 'Please enter car Registration Number!'
            error = true
        } else if (fuelType === '') {
            errorMsg = 'Please select car fuel'
            error = true
        } else if (!isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                carObject.uid = generateUID()
                carObject.ownerId = user.uid
                const result = await addCar(carObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Manager added successfully!' })
                    navigateTo({route: '/manager/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to add car!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    userData: JSON.stringify(carObject)
                }
                const result = await updateCar(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Manager updated successfully!' })
                    localStorage.removeItem('activeCar')
                    navigateTo({route: '/manager/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to update car!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getSelectedManager = userData => {
        setvehicleClass(userData.Segment)
        setmaker(userData.name)
        setshowForm(true)
        setuserData(userData)
    }
    
    const selectFiles = async (e) => {
        let fileArray = [], fileObj = [],
        activeUploadType = e.target.id === 'choose-rc'?'rc': 'insurance'
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            const result = await getBase64(fileObj[0][i])
            let fileData = {
                url: URL.createObjectURL(fileObj[0][i]),
                data: result,
                fileName: fileObj[0][i].name
            }
            fileArray.push(fileData)
            setfile(fileArray)
            setfileArray(fileArray)
            uploadFiles(activeUploadType)
        }
    }
    
    const uploadCall = async (file, activeUploadType) => {
        let error = false,
        errorMsg = ''
        if (!error) {
            file.uid = generateUID()
            const fileResult = await save(file)
            if (fileResult.status) {
                let carObject = {}
                if (activeUploadType === 'rc') {
                    carObject = {
                        rcImage: '["'+fileArray[0].fileName+'"]'
                    }
                } else {
                    carObject = {
                        insuranceImage: '["'+fileArray[0].fileName+'"]'
                    }
                }
                let data = {
                    query: JSON.stringify({uid: userData.uid}),
                    userData: JSON.stringify(carObject)
                }
                const result = await updateCar(data)
                if (result.status) {
                    setfileArray([])
                    setfile([])
                    userData.image = carObject.image
                    if (activeUploadType === 'rc') {
                        setrcImage(carObject.rcImage)
                        setshowRc(true)
                    } else {
                        setinsuranceImage(carObject.insuranceImage)
                        setshowInsurance(true)
                    }
                } else
                    showAlert({ type: 'error', msg: 'Unable to update image!' })
            } else showAlert({ type: 'error', msg: 'Unable to update image!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const uploadFiles = activeUploadType => {
        for (let i = 0; i < file.length; i++) {
            uploadCall(file[i], activeUploadType)
        }
    }
    return {
        addManager,
        mode,
        fuelOption,
        registrationNo,
        setregistrationNo,
        ownerName,
        setownerName,
        vehicleClass,
        setvehicleClass,
        fuelType,
        handleChange,
        maker,
        setmaker,
    }
}