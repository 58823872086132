import { useEffect, useState } from "react"
import { useService } from "../../../hooks"

export const useFeaturedServices = () => {
    const {getService} = useService('Service')
    const [serviceList, setserviceList] = useState([])

    const getServicesData = async () => {
        const result = await getService({status: 'publish'})
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = result.data
                serviceList.sort( compare )
                setserviceList(serviceList.reverse())
            }
        }
    }
    
    const compare = ( a, b, key = 'publishDate' ) => {
        if ( (new Date(a[key])) < (new Date(b[key])) )
            return -1
        if ( (new Date(a[key])) > (new Date(b[key])) )
            return 1
        return 0
    }

    useEffect(() => {
        getServicesData()
    }, [])
    
    return {
        serviceList
    }
}