import { useComponent, useElement } from '../../../hooks'
import { useAddTaxClass } from './hooks'

export const AddTaxClass = () => {
    const Box = useComponent('Box')
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const {
        pageTitle,
        addTaxClass,
        className,
        setclassName,
        amount,
        setamount,
        label,
        setlabel,
    } = useAddTaxClass()
    
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/taxClass/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addTaxClass}>
            <div className="left">
                <Box title='Class Name' required>
                    <Input autoFocus required name="className" value={className} onChange={e => setclassName(e.target.value)} />
                </Box>
                <Box title='Amount' required>
                    <Input type="number" minimum={1} required name="amount" value={amount} onChange={e => setamount(e.target.value)} />
                </Box>
                <Box title='Label' required>
                    <Input required name="label" value={label} onChange={e => setlabel(e.target.value)} />
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
            </div>
        </form>
    </div>
}
