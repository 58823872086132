import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddTaxClass = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'taxClass')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [mode, setmode] = useState('Add')
    const [uid, setuid] = useState('')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [className, setclassName] = useState('')
    const [amount, setamount] = useState('')
    const [label, setlabel] = useState('')
    
    const getTaxClassData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            let taxClassData = result.data[0]
            setmode('Edit')
            setclassName(taxClassData.className)
            setamount(taxClassData.amount)
            setlabel(taxClassData.label)
            setuid(query.uid)
        } else showAlert({ type: 'error', msg: 'No taxClasss added yet!' })
    }
    
    const addTaxClass = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        taxClassObject = {
            className,
            amount,
            label
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                taxClassObject.uid = generateUID()
                const result = await save(taxClassObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Tax Class added successfully!' })
                    navigateTo({route: '/admin/taxClass/list'})
                } else showAlert({ type: 'error', msg: 'Unable to add taxClass!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    taxClassData: JSON.stringify(taxClassObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Tax Class updated successfully!' })
                    navigateTo({route: '/admin/taxClass/list'})
                } else showAlert({ type: 'error', msg: 'Unable to update taxClass!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    useEffect(() => {
        setTitle('Add Tax Class', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[3] === 'edit') {
            getTaxClassData({uid: urlData[4]})
        }
    }, [])

    let pageTitle = mode + ' Tax Class'
    return {
        pageTitle,
        addTaxClass,
        className,
        setclassName,
        amount,
        setamount,
        label,
        setlabel,
    }
}