import Select from 'react-select'
import { useLogList } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const LogList = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
    } = useLogList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex mb50">
            All Logs
        </h1>
        <div className="filter-box">
            <div className="filters relative">
            <label className="filter-label-add">Status: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i)
                    }}
                    options={filterTags}
                />
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashEvent} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
    </div>
}
