import './style.scss'
import { usePayment } from './hooks'
import { useElement, useWidget } from '../../../hooks'

export const Payment = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Banner = useWidget('Banner')
    const {
        status,
        urlData,
    } = usePayment()
    return <>
        <Banner title='Payment Successful' image="yellow-back-1.jpg"/>
        {
            status?
            <div className="form-box-setup payment-success jumbo-container">
                <i className="hi-check-circle big-icon"></i>
                <div className="tac mt40">
                    <h2 className="success-Text-payment">Order Placed successfully!</h2>
                    <p className="order-text-payment">Thanks for choosing us, we wish you enjoy our service / product</p>
                    <p className="mail-text-payment">An email confirmation will be sent to you shortly with your order details </p>
                    <p className="order-id-text">Your Order Id: <span className="orange">#{urlData[2]}</span></p>
                    <div className="btn-box-payment">
                        <Link href="/order/list" className="btn btn-outline mauto">My Orders</Link>
                        <Link href="/accessories" className="btn btn-primary mauto">Continue Shopping</Link>
                    </div>
                </div>
            </div>:
            <div className="form-box-setup payment-success jumbo-container">
                <Img alt="Payment Success" source='internal' src={require('../../../../assets/images/Payment-failed1.png')} />
                <div className="fail-text">
                    <h2>Failed</h2>
                    <p>Your payment was proceed unsuccessfully.</p>
                </div>
            </div>
        }
    </>
}
