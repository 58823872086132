import { useSendMail } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const SendMail = () => {
    const Button = useElement('Button')
    const Editor = useElement('Editor')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const {
        sendMail,
        setselectAll,
        selectAll,
        toEmail,
        settoEmail,
        subject,
        setsubject,
        mailContent,
        setmailContent,
    } = useSendMail()

    return <>
        <h1>Send Mail</h1>
        <form className="columns" noValidate onSubmit={sendMail}>
            <div className="left">
                <Box title={<span className="head flex">Receiver Email
                    <span className="mlauto" onClick={() => setselectAll(!selectAll)}>
                        {
                            selectAll?
                            <i className="hi-check_circle"></i>:
                            <i className="hi-circle"></i>
                        }
                        All
                    </span>
                </span>}>
                    {
                        !selectAll && <Input autoFocus type="email" required name="to" value={toEmail} onChange={e => settoEmail(e.target.value)} />
                    }
                </Box>
                <Box title='Subject'>
                    <Input required name="subject" value={subject} onChange={e => setsubject(e.target.value)} />
                </Box>
                <Box title='Content'>
                    <Editor
                        initialValue={mailContent}
                        value={mailContent}
                        onEditorChange={(mailContent) => setmailContent(mailContent)}
                    />
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn btn-primary" type="submit" title="Click here to send mail">Send</Button>
                </Box>
            </div>
        </form>
    </>
}
