import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {CSVLink} from 'react-csv'
import { useCompanyWorkDashboard } from './hooks';
import { useComponent, useElement } from '../../../hooks';

export const CompanyWorkDashboard = () => {
    const DatePicker = useElement('DatePicker')
    const Table = useComponent('Table')
    const animatedComponents = makeAnimated()
    const exportedFileName = 'exported_daily_work_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    const {
        user,
        exportedData,
        activeBranches,
        selectCompany,
        branches,
        setfromFilterDate,
        fromFilterDate,
        settoFilterDate,
        toFilterDate,
        totalData,
        tableContent,
        activeShownTotal,
    } = useCompanyWorkDashboard()

    return <>
        <div className="right-side-profile-content">
            <div className="header-title-box">
                <div className='overflow-visible'>
                    <div className='flex'>
                        <h1 className="title">
                            Daily Work Report
                            <p className='company-title'>{user.name}</p>
                        </h1>
                        <span className="mlauto">
                            <CSVLink data={exportedData} filename={exportedFileName} className="btn btn-primary btn-small export-btn" target="_blank">Export</CSVLink>
                        </span>
                    </div>
                    {/* <div className="ml20 mt10 select-filter">
                        <Select
                            value={activeDateFilter}
                            defaultValue={activeDateFilter}
                            onChange={activeDateFilter => {setactiveDateFilter(activeDateFilter); getDashboardData(activeDateFilter, activeBranches))}}
                            options={activeDateFilter}
                        />
                    </div> */}
                </div>
                {
                    // activeDateFilter?.value === 'specificDuration' &&
                    <div className="select-filter">
                        <ul className='mb20 ml-20'>
                            {
                                ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                                <li>
                                    <label>Select Branches</label>
                                    <Select
                                        isClearable
                                        closeMenuOnSelect={false}
                                        component={animatedComponents}
                                        placeholder='Select Company'
                                        defaultValue={activeBranches}
                                        onChange={selectCompany}
                                        options={branches}
                                        isMulti
                                    />
                                </li>
                                }
                            <li>
                                <label>From Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={fromFilterDate => setfromFilterDate(fromFilterDate)}
                                    className="date-picker"
                                    selected={fromFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="From Date"
                                />
                            </li>
                            <li>
                                <label>To Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={toFilterDate => settoFilterDate(toFilterDate)}
                                    className="date-picker"
                                    selected={toFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="To Date"
                                />
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <div className='blocks mb20'>
                <div className='block'>
                    <h3>Opening Stock</h3>
                    <p>{totalData['Opening Stock']}</p>
                </div>
                <div className='block'>
                    <h3>Vehicle In</h3>
                    <p>{totalData['Stock In']}</p>
                </div>
                <div className='block'>
                    <h3>Vehicle Out</h3>
                    <p>{totalData['Stock Out']}</p>
                </div>
                <div className='block'>
                    <h3>Closing Stock</h3>
                    <p>{totalData['Closing Stock']}</p>
                </div>
                <div className='block'>
                    <h3>Inspection Done</h3>
                    <p>{totalData['Inspection Done']}</p>
                </div>
            </div>
            {tableContent && <Table
                tableClass="has-total"
                showTotal={false}
                data={tableContent}
                pageSize={activeShownTotal}
            />}
        </div>
    </>
}
