import {CSVLink} from 'react-csv'
import Select from 'react-select'
import { usePackageOrders } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const PackageOrders = () => {
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Table = useComponent('Table')
    const {
        trackScrolling,
        filteredList,
        exportedData,
        startDate,
        setstartDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
    } = usePackageOrders()
    const exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="flex title">Package Orders
        {
            filteredList.length !== 0 &&
            <span className="btn btn-primary btn-small ml20 export-btn">
                <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
            </span>
        }
        </h1>
        <div className="filter-box">
            <div className="flex date-filter overflow-visible">
                <div className="form-group">
                    <DatePicker
                        selected={startDate}
                        onChange={startDate => setstartDate(startDate)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="From:"
                    />
                </div>
                <div className="form-group">
                    <DatePicker
                        selected={endDate}
                        onChange={endDate => setendDate(endDate)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="To:"
                    />
                </div>
                <div className="form-group ml10">
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => {
                            setactiveFilter(e)
                            filterItems(e.value, i, tableData)
                        }}
                        options={filterTags}
                    />
                </div>
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
    </div>
}
