export const userRoutes = [
    {
        "pathName": "/login",
        "component": "Login",
        "type": "preLogin",
        "mode": "static"
    },
    {
        "pathName": "/otp",
        "component": "Otp",
        "type": "preLogin",
        "mode": "dynamic"
    },
    {
        "pathName": "/register",
        "module": ["User"],
        "component": "Register",
        "type": "preLogin",
        "mode": "dynamic"
    },
    {
        "pathName": "/user/profile",
        "module": ["User"],
        "component": "Profile",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/user/chat",
        "module": ["User"],
        "component": "Chat",
        "template": "blank",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/my-account",
        "module": ["User"],
        "redirectTo": "/user/profile",
        "mode": "static"
    },
    {
        "pathName": "/dashboard",
        "component": "DashBoard",
        "type": "postLogin",
        "rejectRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/notification",
        "module": ["Notification"],
        "component": "Notifications",
        "type": "postLogin",
        "mode": "static"
    }
]