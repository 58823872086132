import { postData,getData } from '../Ops'
import { FaqModel, PageModel, ChatModel, ComplaintModel, FranchiseRequestModel, EventModel, FeedbackModel, LogModel, NotificationModel, SettingModel, TestimonialModel, PostModel, JobModel, GarageModel, PackageWorkModel, PaymentModel, SubscriptionModel, OfferModel, ServiceWorkModel, MediaModel, InvoiceModel, TaxClassModel, RecommendationModel, CompanyOrderModel, BusinessWorkModel, B2BDailyWorkModel, CompanyDataModel, CarModelModel, B2BDailyStockModel, MsgModel, AppModel, ProjectModel, BadgeModel, PinCodeModel, FranchiseModel, StateModel, CityModel } from '../../model'

const dataModels = [
    {
        name: 'carModel',
        model: CarModelModel
    },
    {
        name: 'companyData',
        model: CompanyDataModel
    },
    {
        name: 'faq',
        model: FaqModel
    },
    {
        name: 'project',
        model: ProjectModel
    },
    {
        name: 'media',
        model: MediaModel
    },
    {
        name: 'taxClass',
        model: TaxClassModel
    },
    {
        name: 'invoice',
        model: InvoiceModel
    },
    {
        name: 'chat',
        model: ChatModel
    },
    {
        name: 'complaint',
        model: ComplaintModel
    },
    {
        name: 'franchise',
        model: FranchiseModel
    },
    {
        name: 'franchiseRequest',
        model: FranchiseRequestModel
    },
    {
        name: 'event',
        model: EventModel
    },
    {
        name: 'feedback',
        model: FeedbackModel
    },
    {
        name: 'log',
        model: LogModel
    },
    {
        name: 'notification',
        model: NotificationModel
    },
    {
        name: 'setting',
        model: SettingModel
    },
    {
        name: 'testimonial',
        model: TestimonialModel
    },
    {
        name: 'page',
        model: PageModel
    },
    {
        name: 'post',
        model: PostModel
    },
    {
        name: 'job',
        model: JobModel
    },
    {
        name: 'pinCode',
        model: PinCodeModel
    },
    {
        name: 'garage',
        model: GarageModel
    },
    {
        name: 'packageWork',
        model: PackageWorkModel
    },
    {
        name: 'serviceWork',
        model: ServiceWorkModel
    },
    {
        name: 'payment',
        model: PaymentModel
    },
    {
        name: 'offer',
        model: OfferModel
    },
    {
        name: 'subscription',
        model: SubscriptionModel
    },
    {
        name: 'recommendation',
        model: RecommendationModel
    },
    {
        name: 'companyOrder',
        model: CompanyOrderModel
    },
    {
        name: 'businessWork',
        model: BusinessWorkModel
    },
    {
        name: 'b2bDailyWorkData',
        model: B2BDailyWorkModel
    },
    {
        name: 'b2bDailyStockData',
        model: B2BDailyStockModel
    },
    {
        name: 'msg',
        model: MsgModel
    },
    {
        name: 'app',
        model: AppModel
    },
    {
        name: 'badge',
        model: BadgeModel
    },
    {
        name: 'state',
        model: StateModel
    },
    {
        name: 'city',
        model: CityModel
    }
]
export class DataService {
    private model: string
    constructor(model: string) {
        this.model = model
    }
    getPageData = (pageData: any) => {
        if (pageData) {
            return '/'+pageData.number+'/'+pageData.size
        }
        return ''
    }
    save = async (data: any) => {
        const formData = new FormData()
        dataModels.forEach(e => {
            if (e.name === this.model) {
                e.model.forEach(d => {
                    formData.append(d.key, data[d.key])
                })
            }
        })
        try {
            const res = await postData(this.model+'/save', formData)
            return res
        } catch (e) {
            return e
        }
    }
    update = async (data: any) => {
        const formData = new FormData()
        formData.append('query', data['query'])
        formData.append(this.model+'Data', data[this.model+'Data'])
        try {
            const res = await postData(this.model+'/update', formData)
            return res
        } catch (e) {
            return e
        }
    }
    fetchByAttr = async (query: any, pageData = false) => {
        const paginationData = this.getPageData(pageData)
        const queryData = JSON.stringify(query)
        const formData = new FormData()
        formData.append('query', queryData)
        try {
            const res = await postData(this.model+'/get'+paginationData, formData)
            return res
        } catch (e) {
            return e
        }
    }
    remove = async (query: any) => {
        const queryData = JSON.stringify(query)
        const formData = new FormData()
        formData.append('query', queryData)
        try {
            const res = await postData(this.model+'/delete', formData)
            return res
        } catch (e) {
            return e
        }
    }
    fetchAll = async (pageData = false) => {
        const paginationData = this.getPageData(pageData)
        try {
            const res = await getData(this.model+'/list'+paginationData)
            return res
        } catch (e) {
            return e
        }
    }
}