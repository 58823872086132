import { useElement } from "../../hooks"
import { useModal } from "./hooks"

export const Modal = ({
    id = new Date().getTime(),
    children = 'This is the default Modal Content',
    dismissable = true,
    showCancel = true,
    showSuccess = true,
    successButtonLabel = 'Delete',
    cancelButtonLabel = 'Cancel',
    onSuccess = () => {},
    onCancel = () => {},
    showModal = false
}) => {
    const Button = useElement('Button')
    const {
        onCancelButtonClick,
        onSuccessButtonClick,
    } = useModal({
        showModal,
        onCancel,
        onSuccess,
    })
    return <div className={showModal ? "modal open" : "modal"} id={id}>
        <div className="modal-backdrop"></div>
        <div className="modal-content small">
            {dismissable && <Button className="close" onClick={onCancelButtonClick}><i className="hi-close"></i></Button>}
            <div className="modal-header"></div>
            <div className="modal-body">
                {children}
            </div>
            {(showSuccess || showCancel) && <div className="modal-footer">
                {showSuccess && <Button className="btn btn-primary" onClick={onSuccessButtonClick}>{successButtonLabel}</Button>}
                {showCancel && <Button className="btn btn-outline" onClick={onCancelButtonClick}>{cancelButtonLabel}</Button>}
            </div>}
        </div>
    </div>
}