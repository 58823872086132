import { useEffect, useState } from "react"
import { faqCategory } from "../../../../constant"
import { useService } from "../../../../hooks"

export const useFaq = () => {
    const category = faqCategory
    const {fetchAll} = useService('Data', 'faq')
    const {setTitle} = useService('Meta')
    const {showAlert} = useService('Misc')
    const [faqList, setfaqList] = useState([])
    const [activeFaq, setactiveFaq] = useState({})
    const [activeCategory, setactiveCategory] = useState('')
    const [filteredFaqList, setfilteredFaqList] = useState([])

    useEffect(() => {
        setTitle('FAQs')
        getFaqsData()
    }, [])

    const getFaqsData = async () => {
        let faqList = [], result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No faq added yet!' })
            else
                result.data.forEach(e => faqList.push(e))
            setfaqList(faqList)
            setactiveCategory(category[0])
        }
    }

    useEffect(() => {
        let filteredFaqList = []
        faqList.forEach(e => {
            if (JSON.parse(e.category).value === activeCategory.value)
                filteredFaqList.push(e)
        })
        setfilteredFaqList(filteredFaqList)
    }, [activeCategory])
    return {
        setactiveFaq,
        activeFaq,
        activeCategory,
        setactiveCategory,
        category,
        filteredFaqList,
    }
}