import { useCallback } from "react"
import filterTags from './filterTags.json'
import { useFranchiseData } from "./hooks"
import { useComponent, useService } from "../../../../hooks"

export const FranchiseData = () => {
    const {toIndianCurrency} = useService('Misc')
    const Box = useComponent('Box')
    const {
        orderOptions,
        paymentOptions,
        orderDataReceived,
        paymentsDataReceived,
    } = useFranchiseData()
    
    const renderOrdersBox = useCallback(() => {
        return filterTags.map((item, index) => {
            return <div className="order-box" key={index}>
                <div className={"order-label " + item.value}>
                    <i className={item.icon}></i>
                    <p>{item.label}</p>
                </div>
                <p>{orderOptions[item.value] ?? 0}</p>
            </div>
        })
    }, [orderOptions])

    const renderPaymentssBox = useCallback(() => {
        return filterTags.map((item, index) => {
            return <div className="order-box" key={index}>
                <div className={"order-label " + item.value}>
                    <i className={item.icon}></i>
                    <p>{item.label}</p>
                </div>
                <p>{toIndianCurrency(paymentOptions[item.value] ?? 0)}</p>
            </div>
        })
    }, [paymentOptions])
    
    return <>
        {
            orderDataReceived && <Box title="Orders" bodyClass="data-box">
                {renderOrdersBox()}
            </Box>
        }
        {
            paymentsDataReceived && <Box title="Payments" bodyClass="data-box" boxClass="mt20">
                {renderPaymentssBox()}
            </Box>
        }
    </>
}