export const Report = ({
    type,
    title = ''
}) => {
    return <>
        {

        }
    </>
}
