import { useEffect, useState } from "react"

export const useMap = (props) => {
    const [places, setplaces] = useState([])
    const addPlace = newPlace => {
        setplaces([newPlace])
    }
    useEffect(() => {
        if (props.places.length !== 0) {
            setplaces(props.places)
            addPlace(props.places[0])
        }
    }, [])

    useEffect(() => {
        if (props.places.length !== places.length) {
            setplaces(props.places)
            if (props.places.length !== 0) {
                addPlace(props.places[0])
            }
        }
    }, [props, places])
    return {
        places,
    }
}