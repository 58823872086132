import { useComponent, useElement, useService } from '../../../hooks';
import { useChat } from './hooks';
import './style.scss';

export const Chat = () => {
    const {getLoggedInUserData} = useService('User')
    const {getFormattedTime} = useService('Misc')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const UserImage = useComponent('UserImage')
    const {
        tabs,
        activeTab,
        setactiveTab,
        msg,
        sender,
        filterMsg,
        user,
        filterContact,
        initiateChat,
        chatPanelClass,
        setshowContactFilter,
        showContactFilter,
        contactfilter,
        setcontactfilter,
        rightClass,
        receiver,
        setrightClass,
        setreceiver,
        setshowMsgFilter,
        showMsgFilter,
        chatContentRef,
        setmsgfilter,
        msgfilter,
        scrollToBottom,
        setshowSmileyBox,
        showSmileyBox,
        addMsg,
        setactiveMsg,
        activeMsg,
    } = useChat()
    const renderTabs = () => {
        return tabs.map((item,index) => {
            let tabClass = activeTab === item.value ? 'pointer tab active':'pointer tab'
            return <li className={tabClass} key={index} onClick={() => setactiveTab(item.value)}>
                <i className={activeTab === item.value ? item.activeIcon : item.icon}></i>
                <span>{item.label}</span>
            </li>
        })
    }

    const renderMgs = () => {
        return msg.map(( item, index ) => {
            item.msgSender = item.sender === sender.uid ? 'mine' : 'other'
            item.senderTime = getFormattedTime(item.time)
            filterMsg(item)
            return <li className="chat-container" key={index}>
                <div className={"msg "+item.msgSender}>
                    <span>{item.message}</span>
                    <small className='time'>{item.senderTime}</small>
                </div>
            </li>
        })
    }

    const renderContacts = (contacts = false) => {
        let list = user
        if (contacts) list = contacts
        return list.filter(e => e.uid !== getLoggedInUserData().uid).map(( item, index ) => {
            filterContact(item)
            return <li className="contact-container flex pointer" key={index} onClick={() => initiateChat(item)}>
                <div className='pointer img-box'>
                    <UserImage user={item} />
                    <span className={'liveStatus ' + item.liveStatus}></span>
                </div>
                <div className='detail-box'>
                    <h4 className='name'>{item.name ? item.name : item.mobile}</h4>
                    <span className='status'>{item.textStatus}</span>
                </div>
            </li>
        })
    }

    return <>
        <div className={"chat-panel flex " + chatPanelClass}>
            <div className="left">
                <div className="header">
                    <div className='pointer img-box'>
                        <UserImage user={sender} />
                    </div>
                    <span className='icon-box mlauto' onClick={() => setshowContactFilter(!showContactFilter)}>
                        <i className="hi-search1"></i>
                    </span>
                    <span className='icon-box'>
                        <i className="hi-circle"></i>
                    </span>
                </div>
                <div className="content">
                    {showContactFilter && <form className='search-form'>
                        <Input type='search' placeholder='Search...' className='search-bar' onChange={e => setcontactfilter(e.target.value)} value={contactfilter} />
                    </form>}
                    {
                        activeTab === 'contacts'?
                        <>
                            <ul className='contacts'>
                                {renderContacts()}
                            </ul>
                        </>:
                        activeTab === 'chat'?
                        <>
                            <Img src={'coming-soon.jpg'} />
                        </>:
                        activeTab === 'steps'?
                        <>
                            <Img src={'coming-soon.jpg'} />
                        </>:
                        activeTab === 'notification'?
                        <>
                            <Img src={'coming-soon.jpg'} />
                        </>:
                        <></>
                    }
                </div>
                <div className="footer">
                    <ul className='tabs flex'>
                        {renderTabs()}
                    </ul>
                </div>
            </div>
            <div className={rightClass}>
                {
                    receiver === null ?
                    <div className="empty"></div>:
                    <>
                        <div className="header">
                            <span className='icon-box mr12' onClick={() => {
                                setrightClass('right')
                                setreceiver('')
                            }}>
                                <i className="hi-chevron-left"></i>
                            </span>
                            <div className='pointer img-box'>
                                <UserImage user={receiver} />
                            </div>
                            <h4 className='mrauto'>{receiver.name ? receiver.name : receiver.mobile}</h4>
                            <span className='icon-box' onClick={() => setshowMsgFilter(!showMsgFilter)}>
                                <i className="hi-search1"></i>
                            </span>
                            <span className='icon-box'>
                                <i className="hi-circle"></i>
                            </span>
                        </div>
                        <div className="content" ref={chatContentRef}>
                            <div className="messages">
                                {showMsgFilter && <form className='search-form'>
                                    <Input type='search' placeholder='Search...' className='search-bar' onChange={e => setmsgfilter(e.target.value)} value={msgfilter} />
                                </form>}
                                <ul className='msg'>
                                    {renderMgs()}
                                </ul>
                                <span className='icon-box' onClick={scrollToBottom}>
                                    <i className="hi-chevron-bottom"></i>
                                </span>
                            </div>
                        </div>
                        <div className="footer">
                            <div className='smiley-box'>

                            </div>
                            <span className='icon-box' onClick={() => setshowSmileyBox(!showSmileyBox)}>
                                <i className='white hi-happy1'></i>
                            </span>
                            <span className='icon-box'>
                                <i className='hi-attachment'></i>
                            </span>
                            <form className='msg-form flex' onSubmit={addMsg}>
                                <Input placeholder='Message...' className='msg-bar' onChange={e => setactiveMsg(e.target.value)} value={activeMsg} />
                                <span className='icon-box'>
                                    <i className='white hi-mic'></i>
                                </span>
                            </form>
                            <span className='icon-box' onClick={addMsg}>
                                <i className='hi-send white'></i>
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    </>
}
