import { useInput } from './hooks'

export const Input = ({
    className="input",
    placeholder="",
    type="text",
    required=false,
    id,
    name='',
    value,
    onChange=() => {},
    setValue=() => {},
    accept,
    multiple,
    min,
    max,
}) => {
    const {
        onChangeInput
    } = useInput({
        onChange,
        setValue
    })
    return <input
        id={id}
        min={min}
        max={max}
        className={className}
        placeholder={placeholder}
        accept={accept}
        multiple={multiple}
        type={type}
        required={required}
        name={name}
        value={value}
        onChange={onChangeInput}
    />
}