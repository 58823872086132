import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useAddEvent } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddEvent = () => {
    const Editor = useElement('Editor')
    const BackIcon = useElement('BackIcon')
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        statusOption,
        robotsOption,
        pageTitle,
        addEvent,
        title,
        addTitle,
        slug,
        setslug,
        featuredVideo,
        setfeaturedVideo,
        content,
        setcontent,
        excerpt,
        setexcerpt,
        location,
        setlocation,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        setpublishDate,
        publishDate,
        activeStatus,
        setstatus,
        category,
        setcategory,
        categoryOptions,
        tag,
        settag,
        tagOptions,
        image,
        setimage,
    } = useAddEvent()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/event/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addEvent}>
            <div className="left">
                <Box title="Title" required>
                    <Input autoFocus required name="title" value={title} onChange={addTitle} />
                </Box>
                <Box title="Slug" required>
                    <Input required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                </Box>
                <Box title="Content" required>
                    <Editor
                        value={content}
                        onEditorChange={(content) => setcontent(content)}
                    />
                </Box>
                <Box title="Excerpt" required>
                    <Editor
                        value={excerpt}
                        onEditorChange={(excerpt) => setexcerpt(excerpt)}
                    />
                </Box>
                <Box title="Event Location">
                    <Input name="location" value={location} onChange={e => setlocation(e.target.value)} />
                </Box>
                <Box title="CSS">
                    <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={e => setcss(e.target.value)}></textarea>
                </Box>
                <Box title="JS">
                    <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={e => setjs(e.target.value)}></textarea>
                </Box>
                <Box title="Canonical URL">
                    <Input required name="canonical" value={canonical} onChange={e => setcanonical(e.target.value)} />
                </Box>
                <Box title="Robots">
                    <Select
                        value={activeRobots}
                        defaultValue={activeRobots}
                        onChange={e => setrobots(e.value)}
                        options={robotsOption}
                    />
                </Box>
                <Box title="Meta Description">
                    <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={e => setmetaDescription(e.target.value)}></textarea>
                </Box>
                <Box title="Meta Keywords">
                    <CreatableSelect
                        isMulti
                        onChange={e => setmetaKeyword(e)}
                        options={keywordOptions}
                        value={metaKeyword}
                    />
                </Box>
            </div>
            <div className="right">
                <Box title="Meta">
                    <p>
                        Render Slug:
                        <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={toggleRenderSlug}></span>
                    </p>
                    <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                <Box title="Event Date">
                    <DatePicker
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeFormat="HH:mm"
                        onChange={publishDate => setpublishDate(publishDate)}
                        selected={publishDate}
                    /> 
                </Box>
                <Box title="Status">
                    <Select
                        value={activeStatus}
                        defaultValue={activeStatus}
                        onChange={e => setstatus(e.value)}
                        options={statusOption}
                    />
                </Box>
                <Box title="Category">
                    <CreatableSelect
                        isMulti
                        value={category}
                        onChange={e => setcategory(e)}
                        options={categoryOptions}
                    />
                </Box>
                <Box title="Tags">
                    <CreatableSelect
                        isMulti
                        value={tag}
                        onChange={e => settag(e)}
                        options={tagOptions}
                    />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['event']} />
                <Box title="Featured Video">
                    <Input name="featuredVideo" value={featuredVideo} onChange={e => setfeaturedVideo(e.target.value)} />
                </Box>
            </div>
        </form>
    </div>
}
