import { useCallback } from "react"
import { useService } from "./useService"

const language = {
    en: require('../../language/en.json'),
    hi: require('../../language/hi.json')
}

export const useLocale = () => {
    const {getActiveLanguage} = useService('Misc')
    const activeLanguage = getActiveLanguage()
    const translate = useCallback((key) => {
        return language[activeLanguage][key]
    }, [])
    return {
        t: translate
    }
}