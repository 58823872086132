import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useLogin = () => {
    const {sendOtp} = useService('User')
    const {setTitle} = useService('Meta')
    const {isValidMobile} = useService('Validation')
    const {navigateTo} = useContext(NavigatorContext)
    const {
        showAlert
    } = useService('Misc')
    const [mobileNumber, setmobileNumber] = useState('')

    useEffect(() => {
        setTitle('Login')
    }, [])

    const handleSocialLogin = user => {
        if (typeof user._provider === 'google') {
            user = user._profile
        }
        localStorage.setItem('socialData', JSON.stringify(user))
        navigateTo({route: '/register/'+btoa('social-user')})
    }
    
    const handleSocialLoginFailure = e => {
        showAlert({ type: 'error', msg: 'Deauthorized social login' })
        console.log('error', e)
    }
    
    const sendOtpToUser = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = {
            mobile: mobileNumber
        }
        if (mobileNumber === '') {
            errorMsg = 'Please enter your Mobile Number'
            error = true
        } else if (!isValidMobile(mobileNumber)) {
            errorMsg = 'Please enter a valid Mobile Number'
            error = true
        }
        if (!error) {
            const result = await sendOtp(userObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
                navigateTo({route: '/otp/'+btoa(mobileNumber+otpData.userType)})
            } else showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const navigateToHome = () => navigateTo({route: '/'})

    const setMobileNumber = e => setmobileNumber(e.target.value.replace(/^0+/, ''))

    return {
        handleSocialLogin,
        handleSocialLoginFailure,
        sendOtp: sendOtpToUser,
        navigateToHome,
        setMobileNumber,
        mobileNumber
    }
}