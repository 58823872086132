export const NotificationModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'body',
        dataType: String
    },
    {
        key: 'timing',
        dataType: String
    },
    {
        key: 'jsonData',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]