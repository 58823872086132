import { useContext } from 'react'
import { NavigatorContext } from '../../context'
import { useElement, useService } from '../../hooks'
import { useRelatedProductSlider } from './hooks'
import './style.scss'
export const RelatedProductSlider = ({fullWidth = false}) => {
    const {
        toIndianCurrency,
        isEmpty,
    } = useService('Misc')
    const Img = useElement('Img')
    const Rating = useElement('Rating')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Slider = useElement('Slider')
    const {
        getLoggedInUserData,
        isUserLoggedIn,
    } = useService('User')
    const user = getLoggedInUserData()
    const {navigateTo} = useContext(NavigatorContext)
    const {
        featuredProducts,
        addToCart,
    } = useRelatedProductSlider()
    const featuredSetting = {
        rows: 1,
        swipeToSlide: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                setting: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 560,
                setting: {
                    slidesToShow: 1
                }
            }
        ]
    }
    
    const renderFeatureProducts = () => {
        return featuredProducts.map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            return <div className="grid-list-column-item" key={index}>
                {
                    item.isAssured && <Img className="assuredImg" src={'assured.png'} alt={item.title} />
                }
                <div className="thumb">
                    {
                        isEmpty(item.image)?
                        <Img src={'product-placeholder.png'} alt={item.title} />:
                        <Img className="pointer" onClick={() => navigateTo({route: '/product/'+item.slug})} src={JSON.parse(item.image)[0]} alt={item.title} />
                    }
                </div>
                <h5 className="title"><Link title={item.title} href={"/product/"+item.slug}>{item.title}</Link></h5>
                {
                    item.feedback && <div className="common-rating-style">
                        <Rating rating={averageRating} />
                    </div>
                }
                {
                    isEmpty(item.saleprice) ?
                    <div className="mrp">
                        <span className="black">{toIndianCurrency(item.price)}</span>
                    </div>:
                    <div className="flex">
                        <div className="mrp mrauto">
                            <span className="black">{toIndianCurrency(item.saleprice)}</span>
                        </div>
                        <div className="sale-price mlauto">
                            <span className="black">{toIndianCurrency(item.price)}</span>
                        </div>
                        <div className="per-off">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % Off
                        </div>
                    </div>
                }
                {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <div className="main-btn-wrap pt20">
                    {
                        isEmpty(item.stock)?
                        <Button disabled className="btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                        <Button onClick={() => addToCart(item)} className="btn btn-primary btn-block btn-small">Add to Cart<i className='hi-cart'></i></Button>
                    }
                </div>}
            </div>
        })
    }

    const titleClass = "heading-02  " + fullWidth ? "jumbo-container" : ""
    return <section className="related-product-section mb60-mbs40">
        <h2 className={titleClass}>Related Products</h2>
        <div className="wrapper">
            <div className="featured-product-slider">
                <div className="product-slider-active">
                    <Slider {...featuredSetting}>
                        {renderFeatureProducts()}
                    </Slider>
                </div>
            </div>
        </div>
    </section>
}
