import { useContext, useEffect, useState } from 'react'
import { config, orderOption, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext } from '../../../../context'

export const useProductOrders = () => {
    const Img = useElement('Img')
    const {getLoggedInUserData} = useService('User')
    const user = getLoggedInUserData()
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        getFranchiseData,
        showAlert,
        toIndianCurrency,
        getFormattedDate,
        openModal,
        parseAddress,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {
        getOrder,
        getOrders,
    } = useService('Order')
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [tableData, settableData] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [paymentFail, setpaymentFail] = useState(false)
    const [serviceData, setserviceData] = useState([])
    const [orderDataTitle, setorderDataTitle] = useState([])
    const [orderStatusIndex, setorderStatusIndex] = useState(0)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const orderStatus = orderOption
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Product Orders', 'admin')
        getOrdersData()
    }, [])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getOrdersData = async () => {
        let result = ''
        if(['franchiseOwner', 'franchiseManager'].includes(user?.role)) {
            let query = {
                source: 'franchise',
                franchiseId: getFranchiseData().value
            }
            result = await getOrder(query)
        } else {
            result = await getOrders()
        }
        if (result.status) {
            let tableData = [];
            result.data.reverse().forEach((item) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData);
                    orderData.cartData.forEach(e => {
                        if (e.type === 'product') {
                            e.orderData = item
                            tableData.push(e)
                        }
                    })
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1)
                    activefilterValue = e.value
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No order placed yet!' })
        } else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.slice(0, activeShownTotal).forEach(( item, index ) => {
            let orderTime = item.orderData.timing,
            startTime = startDate === ''?'':new Date(startDate),
            endTime = endDate === ''?new Date():new Date(endDate)
            if (orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    image: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage'?
                            <i className="hi-dropbox service-package-icon"></i>:
                            item.type === 'subscription'?
                            <i className="hi-block service-package-icon"></i>:
                            <Img src={item.image} alt={item.title} />
                        }
                    </div>,
                    title: item.title,
                    price: <>{toIndianCurrency(item.price)}</>,
                    orderId: <>#{item.orderData.uid}</>,
                    userName: JSON.parse(item.orderData.userData).name,
                    timing: <>
                        {getFormattedDate(item.orderData.timing)}
                        <p className="status-btn" onClick={() => getProductTrackData(item)}>Status</p>
                    </>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const getProductTrackData = serviceData => {
        let orderData = [], itemFound = false
        if (serviceData !== null) {
            orderData = serviceData.orderData
            if (orderData.orderNotes) {
                orderData.orderNotes.forEach(e => {
                    if (e.tempUid === serviceData.tempUid) {
                        itemFound = true
                        orderStatus.forEach((el, i ) => {
                            if (el === e.status) {
                                setorderStatusIndex(i)
                                setorderDataTitle(serviceData)
                                openModal('product-status-modal')
                            }
                        })
                    }
                })
            }
            if (!itemFound) {
                orderStatus.forEach(( e, i ) => {
                    if (e === orderData.status) {
                        setorderStatusIndex(i)
                        setorderDataTitle(serviceData)
                        openModal('product-status-modal')
                    }
                })
            }
        }
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let filteredList = [], tempTitle = ''
            tableData.forEach(e => {
                tempTitle = (JSON.parse(e.orderData.userData).name).toLowerCase()
                if (e.uid.search(searchKey.toLowerCase()) !== -1 || tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
            setactiveFilterIndex(0)
        }
    }

    useEffect(() => {
        let exportedData = [], cartTotalTax = 0
        filteredList.forEach(e => {
            let orderData = isValidJson(e.orderData) ? JSON.parse(e.orderData) : e.orderData
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            cartTotalTax = 0
            orderData?.cartData?.forEach(( c, i ) => {
                c.tax = Number(c.tax);
                c.price = Number(c.price).toFixed(2)
                c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                cartTotalTax += c.taxAmount
                if (c.type === 'product' && e.status !== 'initialised' && e.status !== 'payment-fail') {
                    let ex = {
                        Customer_Id: (i === 0) ? JSON.parse(e.orderData.userData).uid: '',
                        Order_Id: (i === 0) ? e.uid: '',
                        Customer_Name: (i === 0) ? JSON.parse(e.orderData.userData).name: '',
                        Mobile_No: (i === 0) ? JSON.parse(e.orderData.userData).mobile: '',
                        Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? parseAddress(JSON.parse(e.activeAddress)): ''): '',
                        Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                        Status: (i === 0) ? e.status: '',
                        Product: c.type === 'product'?c.title: '',
                        Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
                        Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
                        Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
                        GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: ''
                    }
                    exportedData.push(ex)
                }
            })
        })
        setexportedData(exportedData)
        
        settableContent({
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, startDate, endDate])
    return {
        trackScrolling,
        filteredList,
        exportedData,
        setstartDate,
        startDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        orderDataTitle,
        orderStatusIndex,
        paymentFail,
    }
}