import { useElement, useService } from '../../../hooks'

export const Thankyou = () => {
    const Img = useElement('Img')
    const Button = useElement('Button')
    const {setTitle} = useService('Meta')
    setTitle('Thank You')
    return <div className="body-background flex">
        <div className="container">
            <div className="form-box-setup thankyou-page">
                <div className="form-box">
                    <Img alt="Thank you" source='internal' src={require('../../../../assets/images/thankyou.png')} />
                    <div id="content-box" className="content">
                        <h1 className="title">
                            <span>Congrats, you are in!</span>
                        </h1>
                        <p>Thnakyou for the purchasing service, Your details will sending on your mail </p>
                    </div>
                    <Button className="btn-primary btn mv30" type="submit">Okay</Button>
                </div>
            </div>
        </div>
    </div>
}