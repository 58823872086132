import { useEffect, useState } from "react"
import { timeSlot, base } from "../../../../constant"
import { useService } from "../../../../hooks"

export const useMicroServices = () => {
    const {
        getLoggedInUserData,
        getActiveSubscription,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {getMicroServices} = useService('MicroService')
    const {
        getActiveCar,
        generateUID,
        toIndianCurrency,
        showAlert,
        openModal,
        openCarSelectModal,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart} = useService('Order')
    const user = getLoggedInUserData()
    const [activeCar, setactiveCar] = useState(getActiveCar())
    const [microServiceList, setmicroServiceList] = useState([])
    const [activeMicroService, setactiveMicroService] = useState('')
    const [orderDate, setorderDate] = useState('')
    const [chooseServiceTime, setchooseServiceTime] = useState(false)
    const [activeTimeSlot, setactiveTimeSlot] = useState(timeSlot[0])
    const [selectPick, setselectPick] = useState(false)
    const [showPick, setshowPick] = useState(false)

    useEffect(() => {
        setTitle('Micro Services')
        getMicroServicesData()
    }, [])

    const getMicroServicesData = async () => {
        const urlData = urlToSplitData(getLocationData().pathname)
        const result = await getMicroServices()
        if (result.status) {
            if (result.data?.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                microServiceList.sort( compare )
                setmicroServiceList(microServiceList.reverse())
                if (urlData[2] !== null && urlData[2] !== '' && urlData[2]) {
                    let element = document.querySelector("#"+urlData[2])
                    setactiveMicroService(urlData[2])
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
        }
    }
    
    const compare = ( a, b, key = 'publishDate' ) => {
        if ( (new Date(a[key])) < (new Date(b[key])) )
            return -1
        if ( (new Date(a[key])) > (new Date(b[key])) )
            return 1
        return 0
    }
    
    const chooseActiveCar = () => {
        let prices = '[]', activeMicroServiceData = null, price = 0, found = false
        microServiceList.forEach(e => {
            if (activeMicroService === e.slug) {
                activeMicroServiceData = e
            }
        })
        prices = JSON.parse(JSON.parse(activeMicroServiceData.prices))
        if (activeCar === null) {
            openCarSelectModal()
            checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (getActiveSubscription() !== null) {
                            c.saleprice = c.saleprice*0.9
                        }
                    }
                    price = Number(c.saleprice)
                    price += price*Number(activeMicroServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    c.saleprice = c.saleprice + c.saleprice*Number(activeMicroServiceData.tax)*0.01
                    c.saleprice = String(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    c.price = c.price + c.price*Number(activeMicroServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    serviceAddedTemplate(price, activeMicroServiceData, c.price, c.saleprice)
                }
            })
        }
    }
    
    const checkForActiveCar = () => {
        let prices = '[]', activeMicroServiceData = null, price = 0, found = false
        microServiceList.forEach(e => {
            if (activeMicroService === e.slug) {
                activeMicroServiceData = e
            }
        })
        prices = JSON.parse(JSON.parse(activeMicroServiceData.prices))
        if (activeCar === null) {
            openCarSelectModal()
            checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (getActiveSubscription() !== null)
                            c.saleprice = c.saleprice*0.9
                    }
                    price = Number(c.saleprice)
                    price += price*Number(activeMicroServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    c.saleprice = c.saleprice + c.saleprice*Number(activeMicroServiceData.tax)*0.01
                    c.saleprice = String(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    c.price = c.price + c.price*Number(activeMicroServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    serviceAddedTemplate(price, activeMicroServiceData, c.price, c.saleprice)
                }
            })
        }
    }
    
    const serviceAddedTemplate = (derivedPrice, item, mrp, saleprice) => {
        let price = derivedPrice
        let service = {
            orderDate,
            uid: item.uid,
            activeTimeSlot,
            activeCar,
            title: activeCar.registrationNo ? item.title + ' ['+activeCar.registrationNo+']' : item.title,
            image: JSON.parse(item.image)[0],
            price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: generateUID(),
            tax: item.tax,
            hsn: item.hsn,
            mrp,
            saleprice,
            selectPick,
            taxAmount: Number((price * (Number(item.tax))/100).toFixed(2))
        };
        service.displayPrice = (service.price + service.taxAmount).toFixed(2)
        let productAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Micro Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(item.image)[0]+'" alt="'+item.title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+item.title+'</p>'+
        '<p class="service-price">'+toIndianCurrency(price)+'</p>'+
        '</div>'+
        '</div>'
        addToCart(service)
        if (activeMicroService !== 'pick-drop' && selectPick) {
            setactiveMicroService('pick-drop')
            chooseActiveCar()
        } else {
            setchooseServiceTime(false)
            setactiveMicroService('')
            setorderDate('')
            setactiveTimeSlot(timeSlot[0])
            setactiveCar('')
            setselectPick(false)
            setshowPick(false)
        }
        showAlert({ type: 'custom', template: productAddedTemplate })
    }
    
    const buyItem = (buyType, item) => {
        if (item.slug !== 'pick-drop') {
            setshowPick(true)
        }
        if (activeCar !== null) {
            setactiveMicroService(item.slug)
            openModal("add-to-cart-modal")
        } else {
            openCarSelectModal()
        }
    }
    let today = new Date(),
    tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return {
        activeCar,
        activeMicroService,
        user,
        buyItem,
        setactiveTimeSlot,
        activeTimeSlot,
        microServiceList,
        setorderDate,
        setchooseServiceTime,
        today,
        orderDate,
        chooseServiceTime,
        showPick,
        setselectPick,
        selectPick,
        chooseActiveCar,
    }
}