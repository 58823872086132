import { Img, CheckBox, Button, Badge, BackIcon, Actor, PreLoader, Link, Html, Input, DatePicker, Slider, Editor } from "../element"

const elements: any = {
    "Img": Img,
    "CheckBox": CheckBox,
    "Button": Button,
    "Badge": Badge,
    "BackIcon": BackIcon,
    "Actor": Actor,
    "PreLoader": PreLoader,
    "Link": Link,
    "Html": Html,
    "Input": Input,
    "DatePicker": DatePicker,
    "Slider": Slider,
    "Editor": Editor,
}

export const useElement = (element: string) => {
    return elements[element]
}