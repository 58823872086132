import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useAdminHeader = () => {
    const {
        getLoggedInUserData,
        logout: logoutUser,
    } = useService('User')
    const {
        getThemeMode,
        setThemeMode,
        toggleBodyClass,
        getFranchiseData: getFranchiseInfo,
        isEmpty,
        setFranchiseData,
    } = useService('Misc')
    const {fetchByAttr} = useService('Data', 'franchise')
    const user = getLoggedInUserData()
    const [showUserNav, setshowUserNav] = useState(false)
    const [franchiseData, setfranchiseData] = useState(null)
    let userProfileImage = ''

    const toggleThemeMode = () => {
        if (getThemeMode() === "theme-dark") {
            setThemeMode("theme-light")
        } else setThemeMode("theme-dark")
    }
    
    const toggleNav = () => {
        toggleBodyClass('', '', 'closed')
    }
    
    const toggleUserNav = () => {
        setshowUserNav(!showUserNav)
    }

    const logout = () => {
        setshowUserNav(false)
        logoutUser('/login')
    }

    const getFranchiseData = async () => {
        let franchiseData = getFranchiseInfo()
        if(isEmpty(franchiseData)) {
            let managerData = JSON.stringify({
                label: user.name,
                value: user.uid
            })
            const result = await fetchByAttr({
                $or: [ { contactPerson: managerData }, { owner: managerData } ]
            })
            if(result.status) {
                franchiseData = result.data[0]
                setFranchiseData(franchiseData)
            }
            setfranchiseData(franchiseData)
        }
    }

    useEffect(() => {
        if (['franchiseManager', 'franchiseOwner'].includes(user?.role)) {
            getFranchiseData()
        }
    }, [])
    return {
        toggleNav,
        toggleThemeMode,
        franchiseData,
        userProfileImage,
        user,
        toggleUserNav,
        showUserNav,
        logout,
    }
}