export const PinCodeModel = [
    {
        key: 'code',
        dataType: String
    },
    {
        key: 'area',
        dataType: String
    },
    {
        key: 'district',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'state',
        dataType: String
    },
    {
        key: 'status',
        dataType: Boolean
    }
]