export const B2BDailyWorkModel = [
    {
        key: 'ownerId',
        dataType: String
    },
    {
        key: 'registrationNo',
        dataType: String
    },
    {
        key: 'Timestamp',
        dataType: String
    },
    {
        key: 'Date',
        dataType: String
    },
    {
        key: 'Vehicle No (In Capital Letters)',
        dataType: String
    },
    {
        key: 'Yard Name',
        dataType: String
    },
    {
        key: 'Cleaning Work Done',
        dataType: String
    },
    {
        key: 'Vehicle Inspection Done',
        dataType: String
    },
    {
        key: 'Engine Starting',
        dataType: String
    },
    {
        key: 'Status [Switches Working]',
        dataType: String
    },
    {
        key: 'Status [AC Working]',
        dataType: String
    },
    {
        key: 'Status [Lights Working]',
        dataType: String
    },
    {
        key: 'Battery Voltage',
        dataType: String
    },
    {
        key: 'Vehicle Tyre Status',
        dataType: String
    },
    {
        key: 'Service Done [Puncture Repair]',
        dataType: String
    },
    {
        key: 'Service Done [Battery Charging]',
        dataType: String
    },
    {
        key: 'Vehicle Running Condition',
        dataType: String
    }
]