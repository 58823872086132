import {
    SubFooter,
    SocialButton,
    SideCart,
    Header,
    Footer,
    Content,
    Banner,
    AdminSidebar,
    AdminFooter,
    AdminHeader,
    DashboardWidget,
    DashboardData,
} from '../widget'
const widgets: any = {
    'SubFooter': SubFooter,
    'SocialButton': SocialButton,
    'SideCart': SideCart,
    'Header': Header,
    'Footer': Footer,
    'Content': Content,
    'Banner': Banner,
    'AdminSidebar': AdminSidebar,
    'AdminFooter': AdminFooter,
    'AdminHeader': AdminHeader,
    'DashboardWidget': DashboardWidget,
    'DashboardData': DashboardData,
}
export const useWidget = (widget: string) => {
    return widgets[widget]
}