export const blogRoutes = [
    {
        "pathName": "/blog",
        "module": ["Blog"],
        "component": "Blog",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/post",
        "module": ["Blog"],
        "component": "Post",
        "type": "public",
        "mode": "dynamic"
    }
]