import Select from 'react-select'
import { useStoreLocator } from './hooks'
import { useComponent, useElement, useWidget } from '../../../hooks'
import { base } from '../../../constant'
import './style.scss'

export const StoreLocator = () => {
    const Link = useElement('Link')
    const Banner = useWidget('Banner')
    const Map = useComponent('Map')
    const {
        activeShownTotal,
        filteredList,
        activeCity,
        assignCity,
        cities,
    } = useStoreLocator()
    const renderStoreLocatorList = storeLocatorFiles => {
        return storeLocatorFiles.map(( item, index ) => {
            return <div className="storeLocator-single-item" key={index}>
                <div className="thumb" style={{backgroundImage: `url('${base.imageBaseUrl}${JSON.parse(item.image)[0]}')`}}></div>
                <div className='store-content'>
                    <div className='store-info'>
                        <div className="content">
                            <h3 className="city fs32">{item.addressDetail.city}</h3>
                            <h4 className="area fs18">{item.addressDetail.area}</h4>
                            <p className='mb20'>Car Maintenance Repair and Detailing with expert hands in {item.addressDetail.area} {item.addressDetail.city}.</p>
                            <Link href={"/franchise/"+item.slug}>View More</Link>
                        </div>
                        <div className='map'>
                            {item.places && <Map
                                googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+base.googleApiKey}
                                loadingElement={<div style={{ height: `240px` }} />}
                                containerElement={<div style={{ height: `240px` }} />}
                                mapElement={<div style={{ height: `240px` }} />}
                                center={item.places[0] ?? []}
                                zoom={14}
                                places={item.places}
                            />}
                        </div>
                    </div>
                    <div className='info-box mt10 lh20'>
                        <p className='email'>
                            <i className='icon hi-email orange'></i><span><b>Email:</b> {item.email}</span>
                        </p>
                        <p className='mobile'>
                            <i className='icon hi-call orange'></i><span><b>Mobile:</b> {item.mobile}</span>
                        </p>
                        <span className='address mlauto'><i className='icon flaticon-pin orange'></i><b>Address:</b> {item.address}</span>
                    </div>
                </div>
            </div>
        })
    }

    const customRender = () => {
        return <div className="widget storeLocator-bg">
            <h5 className="widget-title border-bottom">Filter by City</h5>
            <Select
                value={activeCity}
                defaultValue={activeCity}
                onChange={assignCity}
                options={cities}
            />
        </div>
    }

    return <>
        <Banner title='CarMitra Studios' align="right" image="store-locator-banner.jpg" customRender={customRender} />
        <div className="mt40 storeLocator-page jumbo-container mb40">
            <div id="list-wrapper">
                { renderStoreLocatorList(filteredList || []) }
            </div>
        </div>
    </>
}
