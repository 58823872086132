import { useContext, useEffect, useState } from "react"
import { fuelOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddCarModel = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'media')
    const {
        fetchByAttr,
        save: saveCar,
        update,
    } = useService('Data', 'carModel')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
        getBase64,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getVehicleClassList,
        getCarManufacturers,
    } = useService('Car')
    const [modelKey, setmodelKey] = useState('')
    const [vehicleClassListoptions, setvehicleClassListoptions] = useState([])
    const [activeSegment, setactiveSegment] = useState(null)
    const [manufactureroptions, setmanufactureroptions] = useState([])
    const [manufacturer, setmanufacturer] = useState('')
    const [activeManufacturer, setactiveManufacturer] = useState('')
    const [uploaded, setuploaded] = useState(false)
    const [year_of_manufacture, setyear_of_manufacture] = useState(new Date ('2010'))
    const [name, setname] = useState("")
    const [image_path, setimage_path] = useState("")
    const [Segment, setSegment] = useState("Sedan")
    const [fuel, setfuel] = useState(fuelOption)
    const [end_year, setend_year] = useState(new Date ('2020'))
    const [start_year, setstart_year] = useState(new Date ('2010'))
    const [uid, setuid] = useState('')
    const [mode, setmode] = useState('Add')
    
    useEffect(() => {
        setTitle('Add CarModel', 'admin')
        getVehicleClasses()
    }, [])

    const getVehicleClasses = async () => {
        const result = await getVehicleClassList()
        let vehicleClassListoptions = []
        if (result.status) {
            result.data.forEach(e => {
                e.label = e.name
                e.value = e.priceGroup
                vehicleClassListoptions.push(e)
            })
        }
        setvehicleClassListoptions(vehicleClassListoptions)
        getManufacturerListData()
    }
    
    const uploadCall = async () => {
        let error = false,
        errorMsg = ''
        if (!error) {
            image_path.uid = generateUID()
            const fileResult = await save(image_path)
            if (fileResult.status) {
                addCarModel()
            } else showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getManufacturerListData = async () => {
        const result = await getCarManufacturers()
        if (result.status) {
            let manufactureroptions = []
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No Car Manufacturers added yet!' })
            } else {
                result.data.forEach(e => {
                    e.value = e.slug
                    e.label = e.name
                    manufactureroptions.push(e)
                })
            }
            setmanufactureroptions(manufactureroptions)
            const urlData = urlToSplitData(getLocationData().pathname)
            if (urlData[3] === 'edit') {
                getCarModelData({uid: urlData[4]})
            }
        }
    }
    
    const getCarModelData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            let carModelData = result.data[0]
            setactiveManufacturer(manufactureroptions.find(e => e.label === carModelData.manufacturer))
            setactiveSegment(vehicleClassListoptions.find(e =>  e.label === carModelData.Segment))
            setname(carModelData.name)
            setmode('Edit')
            setuploaded(true)
            setmodelKey(carModelData.modelKey)
            setend_year(new Date(carModelData.end_year))
            setSegment(carModelData.Segment)
            setfuel(JSON.parse(carModelData.fuel))
            setimage_path(carModelData.image_path)
            setstart_year(new Date(carModelData.start_year))
            setyear_of_manufacture(new Date(carModelData.year_of_manufacture))
            setmanufacturer(carModelData.manufacturer)
            setuid(carModelData.uid)
        } else showAlert({ type: 'error', msg: 'No carModels added yet!' })
    }
    
    const selectFiles = async (e) => {
        let fileData = e.target.files[0]
        const result = await getBase64(fileData)
        let image_path = {
            url: URL.createObjectURL(fileData),
            data: result,
            type: fileData.type,
            fileName: fileData.name,
            size: fileData.size
        }
        if (fileData.size <= 4000000) {
            setimage_path(image_path)
            setuploaded(true)
        }
        else showAlert({ type: 'error', msg: 'File is too large.' })
    }
    
    const addCarModel = async () => {
        let error = false,
        errorMsg = '',
        dereivedImage_path = image_path.fileName ?? image_path,
        dereivedModelKey = modelKey !== '' ? modelKey : (dereivedImage_path.substring(0, dereivedImage_path.lastIndexOf('.')) || dereivedImage_path)
        let carModelObject = {
            end_year: String(new Date(end_year).getFullYear()),
            Segment,
            modelKey: dereivedModelKey,
            fuel: JSON.stringify(fuel),
            name,
            image_path: dereivedImage_path,
            start_year: String(new Date(start_year).getFullYear()),
            year_of_manufacture: String(new Date(year_of_manufacture).getFullYear()),
            manufacturer
        }
        if (name === '') {
            errorMsg = 'Please enter the name'
            error = true
        } else if (image_path === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                carModelObject.uid = generateUID()
                const result = await saveCar(carModelObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'CarModel added successfully!' })
                    navigateTo({route: '/admin/carModel/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add carModel!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    carModelData: JSON.stringify(carModelObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'CarModel updated successfully!' })
                    navigateTo({route: '/admin/carModel/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update carModel!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const selectVehicleSegment = e => {
        setSegment(e.label)
        setactiveSegment(e)
    }

    const submitForm = e => {
        e.preventDefault()
        if(mode === 'Edit' && !image_path.fileName) {
            addCarModel()
        } else {
            uploadCall()
        }
    }

    const selectCarManufacturer = e => {
        setmanufacturer(e.label)
        setactiveManufacturer(e)
    }

    const addCarModelImage = e => {
        setmodelKey(modelKey !== '' ? modelKey : (e.target.value.substring(0, e.target.value.lastIndexOf('.')) || e.target.value))
        setimage_path(e.target.value)
    }
    return {
        setmodelKey,
        setfuel,
        setyear_of_manufacture,
        year_of_manufacture,
        setstart_year,
        start_year,
        setend_year,
        end_year,
        image_path,
        vehicleClassListoptions,
        selectFiles,
        manufactureroptions,
        fuelOption,
        activeSegment,
        addCarModelImage,
        selectCarManufacturer,
        submitForm,
        selectVehicleSegment,
        activeManufacturer,
        uploaded,
        mode,
        name,
        setname,
        modelKey,
        fuel
    }
}