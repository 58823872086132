
import { useComponent, useWidget } from "../../hooks"
import './style.scss'

export const PostLogin = component => {
    const Header = useWidget('Header')
    const ProfileSideBar = useComponent('ProfileSideBar')
    if (component.props.template === 'blank')
        return component
    else
        return <>
            <Header />
            <div className="profile-page">
                <div className="profile-main-section wrapper1">
                    <ProfileSideBar />
                    <div className="right-content-section">
                    {component}
                    </div>
                </div>
            </div>
        </>
}
