export const orderRoutes = [
    {
        "pathName": "/admin/order/list",
        "module": ["Ecommerce"],
        "component": "Orders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/order/list",
        "module": ["Ecommerce"],
        "component": "Orders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/product/order/list",
        "module": ["Ecommerce"],
        "component": "ProductOrders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/service/order/list",
        "module": ["Ecommerce"],
        "component": "ServiceOrders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/package/order/list",
        "module": ["Ecommerce"],
        "component": "PackageOrders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/order/feedback",
        "module": ["Ecommerce"],
        "component": "FeedbackOrders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/package/order/view",
        "module": ["Ecommerce"],
        "component": "PackageDetail",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/order/view",
        "module": ["Ecommerce"],
        "component": "OrderDetail",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/order/list",
        "module": ["Ecommerce"],
        "component": "Orders",
        "type": "admin",
        "accessRoles": ["supervisor", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/invoice/list",
        "module": ["Ecommerce"],
        "component": "InvoiceListAdmin",
        "type": "admin",
        "mode": "static"
    }
]