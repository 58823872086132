import { useEffect, useState } from "react"
import { useService } from "../../../../../hooks"

export const useFranchiseData = () => {
    const {getOrder} = useService('Order')
    const {getFranchiseData: getFranchiseInfo} = useService('Misc')
    const [orderDataReceived, setorderDataReceived] = useState(false)
    const [paymentsDataReceived, setpaymentsDataReceived] = useState(false)
    const [orderOptions, setorderOptions] = useState(null)
    const [paymentOptions, setpaymentOptions] = useState(null)

    const getFranchiseData = async () => {
        let paymentOptions = {}, orderOptions = {}
        const result = await getOrder({
            source: 'franchise',
            franchiseId: getFranchiseInfo().value
        })
        if (result.status) {
            result.data.forEach(e => {
                if (typeof e.trash === "undefined" || !e.trash) {
                    const amount = JSON.parse(e.orderData).discount ? (JSON.parse(e.orderData).cartTotal - JSON.parse(e.orderData).discount) : JSON.parse(e.orderData).cartTotal
                    if(!orderOptions[e.status]) {
                        orderOptions[e.status] = 1
                    } else {
                        orderOptions[e.status]++
                    }
                    if (!paymentOptions[e.status]) {
                        paymentOptions[e.status] = amount
                    } else {
                        paymentOptions[e.status] += amount
                    }
                }
            })
            setorderDataReceived(true)
            setpaymentsDataReceived(true)
            setorderOptions(orderOptions)
            setpaymentOptions(paymentOptions)
        }
        
    }
    useEffect(() => {
        getFranchiseData()
    }, [])
    return {
        orderOptions,
        paymentOptions,
        orderDataReceived,
        paymentsDataReceived,
    }
}