import { useContext, useEffect, useState } from "react"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useBookServiceForm = () => {
    const {fetchByAttr} = useService('Data', 'city')
    const {getService} = useService('Service')
    const {
        setActiveLocation: setLocation,
        getActiveLocation: getLocation,
    } = useService('Misc')
    const {
        getCarManufacturers: getManufacturers,
    } = useService('Car')
    const {setActiveCar, showAlert} = useService('Misc')
    const {fetchByAttr: fetchCarByAttr} = useService('Data', 'carModel')
    const [activeCarBrand, setactiveCarBrand] = useState(null)
    const [activeCarModel, setactiveCarModel] = useState(null)
    const [activeService, setactiveService] = useState(null)
    const [activeLocation, setactiveLocation] = useState(getLocation())
    const [carBrands, setcarBrands] = useState([])
    const [carModels, setcarModels] = useState([])
    const [services, setservices] = useState([])
    const [locations, setlocations] = useState([])
    const {navigateTo} = useContext(NavigatorContext)

    useEffect(() => {
        getCarManufacturers()
        getServicesData()
        getCitysData()
    }, [])

    const getCitysData = async () => {
        const result = await fetchByAttr({status: 'true'})
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No City added yet!' })
            } else {
                const citiesList = []
                result.data.forEach(e => {
                    e.value = e.code
                    e.label = e.name
                    citiesList.push(e)
                })
                setlocations(citiesList)
            }
        }
    }

    const getServicesData = async () => {
        const result = await getService({status: 'publish'})
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = []
                result.data.forEach(e => {
                    e.value = e.slug
                    e.label = e.title
                    serviceList.push(e)
                })
                setservices(serviceList)
            }
        }
    }

    const getCarManufacturers = async () => {
        const result = await getManufacturers()
        if (result.status) {
            if (result.data?.length !== 0) {
                let cb = []
                result.data.forEach(e => {
                    e.value = e.slug
                    e.label = e.name
                    cb.push(e)
                })
                setcarBrands(cb)
            }
        }
    }
    const getCarManufacturerCars = async manufacturer => {
        const result = await fetchCarByAttr({manufacturer})
        if (result.data?.length !== 0) {
            let cm = []
            result.data.forEach(e => {
                e.value = e.modelKey
                e.label = e.name + ' ' + e.Segment
                cm.push(e)
            })
            setcarModels(cm)
        }
    }
    const bookService = () => {
        if (activeService) navigateTo({route: '/service/'+activeService.slug})
    }
    const selectCarBrand = (e) => {
        setactiveCarBrand(e)
        getCarManufacturerCars(e.label)
    }
    const selectCarModel = (e) => {
        setActiveCar(e)
        setactiveCarModel(e)
    }
    const selectService = (e) => {
        setactiveService(e)
    }
    const selectLocation = (e) => {
        setactiveLocation(e)
        setLocation(e)
    }
    return {
        activeCarBrand,
        activeCarModel,
        activeService,
        activeLocation,
        bookService,
        selectCarBrand,
        selectCarModel,
        selectService,
        selectLocation,
        carBrands,
        carModels,
        services,
        locations,
    }
}