import { useEffect, useState } from "react"
import { mainUrl } from "../../../constant"

export const useShare = ({shareUrl}) => {
    const [url, seturl] = useState(mainUrl)
    useEffect(() => {
        seturl(mainUrl+shareUrl)
    }, [shareUrl, url])
    return {
        url
    }
}