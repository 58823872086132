import { base } from '../../constant'
import { useService } from '../../hooks'
import { useTopNavbarBanner } from './hooks'

export const TopNavbarBanner = ({onClick}) => {
    const {
        isUserLoggedIn,
        getLoggedInUserData,
    } = useService('User')
    const {ctaClick} = useTopNavbarBanner({onClick})
    return <div className="top-nav-bar-banner">
        <div className="main-box jumbo-container" style={{backgroundImage: `url("${base.imageBaseUrl}car-icon.png")`}}>
            <div className="text-banner">
                <h1>15% Off</h1>
                <div className="right-content">
                    <label>Car Detailing Services</label>
                    <p>Give Your Car The Best Care it Deserves</p>
                </div>
            </div>
            {( !isUserLoggedIn() || !['manager', 'company', 'branch', 'representative'].includes(getLoggedInUserData().role)) && <div className="shop-now-btn">
                <button onClick={ctaClick} className="btn btn-primary">Book Now</button>
            </div>}
        </div>
    </div>
}