import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useOtp = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'log')
    const {
        getUser,
        isAllowedAdmin,
        login: loginUser,
        sendOtp,
    } = useService('User')
    const {
        generateUID,
        proceedToCheckOut,
        showAlert,
    } = useService('Misc')
    const {setTitle} = useService('Meta')
    const {getCartData} = useService('Order')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [otp, setotp] = useState('')
    const urlData = urlToSplitData(getLocationData().pathname)
    const [mobile, setmobile] = useState(atob(urlData[2]).replace('new', '').replace('old', ''))
    const [email, setemail] = useState('')
    const login = async (e) => {
        e.preventDefault()
        let userType = 'old',
        error = false,
        errorMsg = '',
        otpData = JSON.parse(localStorage.getItem('otpData')),
        derivedOtp = otpData.otp,
        derivedUid = otpData.uid,
        query = {}
        userType = atob(urlData[2]).substr(atob(urlData[2]).length - 3)
        if (userType === 'old') {
            query = {
                uid: derivedUid
            }
        }
        if (otp === '') {
            errorMsg = 'Please enter OTP'
            error = true
        }
        if (otp != derivedOtp) {
            errorMsg = 'OTP does not match'
            error = true
        }
        if (!error) {
            if (userType === 'new')
                navigateTo({route: '/register/'+urlData[2]})
            else {
                const result = await getUser(query)
                if (result.status) {
                    let userData = result.data[0]
                    let userObject = await loginUser(userData)
                    let logData = {
                        log: userObject.name+' logged in with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: generateUID()
                    }
                    save(logData)
                    if (userObject !== null) {
                        let nextUrl = isAllowedAdmin() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (getCartData().length !== 0)
                            proceedToCheckOut()
                        else
                            navigateTo({route: nextUrl})
                    }
                } else showAlert({ type: 'error', msg: 'Unable to login!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    useEffect(() => {
        setTitle('Otp')
    }, [])
    const changeOtp = e => setotp(e.target.value)
    
    const navigateToLogin = () => navigateTo({route: '/login'})

    const resendOtp = async () => {
        let error = false,
        errorMsg = '',
        userObject = {
            mobile
        }
        if (!error) {
            const result = await sendOtp(userObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
            } else showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        login,
        changeOtp,
        resendOtp,
        otp,
        mobile,
        email,
        navigateToLogin,
    }
}