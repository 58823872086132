import { useComponent, useElement } from '../../../hooks'
import { useSendMail } from '../SendMail/hooks'
import { useAppBanners } from './hooks'

export const AppBanners = () => {
    const {
        openModal,
        closeModal,
        preventFormSubmit,
    } = useSendMail('Misc')
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const {
        selectImage,
        preview,
        trashSelectedImage,
        getMedias,
        setimage,
        mediaFiles,
        image,
        saveBanners,
        activeFile,
    } = useAppBanners()
    const renderImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index} className={image.includes(item.fileName)?"selected": ''}>
                <i className="hi-check-circle select" onClick={() => selectImage(item.fileName)}></i>
                <Img alt={item.fileName} src={item.fileName} />
            </li>
        })
    }
    
    const renderUploadedImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index}>
                <i className="hi-eye select" onClick={() => preview(item)}></i>
                <i className="hi-trash trash" onClick={() => trashSelectedImage(item)}></i>
                <Img alt={item.fileName} src={item} />
            </li>
        })
    }
    
    return <div className='content'>
        <h1>
            <BackIcon backLink='/admin' />
            App Banners
        </h1>
        <form className="columns" noValidate onSubmit={preventFormSubmit}>
            <div className="left">
                <Box title='App Banner' required>
                    <Button className="btn-primary btn btn-small" title="Click here to show images" onClick={() => {
                        getMedias()
                        openModal("media-modal")
                    }}>Add Media</Button>
                    <Input className="hidden" required name="image" value={image||''} onChange={e => setimage(e.target.value)} id="image-input" />
                    <div className="upload-box mt20 mb0">
                        <ul className="media-box banner-box">
                            {
                                JSON.parse(image).length !== 0 &&
                                renderUploadedImages(JSON.parse(image)||[])
                            }
                        </ul>
                    </div>
                    {
                        JSON.parse(image).length > 0 &&
                        <Button className="btn-primary btn btn-small mt20" title="Click here to show images" onClick={saveBanners}>Save</Button>
                    }
                </Box>
            </div>
        </form>
        <div className="modal media-modal" id="media-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("media-modal")}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="modal-header">
                        <h2 className="heading">Select Media</h2>
                    </div>
                    <div className="modal-box">
                        <ul className="media-box banner-box">
                            {
                                mediaFiles.length !== 0 &&
                                renderImages(mediaFiles||[])
                            }
                        </ul>
                    </div>
                </div>
                {
                    JSON.parse(image).length > 0 &&
                    <Button className="btn-primary btn btn-small mt20" title="Click here to show images" onClick={saveBanners}>Save</Button>
                }
            </div>
        </div>
        <div className="modal preview-modal" id="preview-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("preview-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        activeFile !== null &&
                        <Img alt={activeFile.item} src={activeFile} className="mauto" />
                    }
                </div>
            </div>
        </div>
    </div>
}
