import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useProject = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'project')
    const {setTitle} = useService('Meta')
    const {isEmpty} = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [image, setimage] = useState('[]')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [description, setdescription] = useState('')
    const [releaseDate, setreleaseDate] = useState(new Date())
    const [metaKeyword, setmetaKeyword] = useState('')
    const [category, setcategory] = useState([])
    const [tag, settag] = useState('')

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
        getProjectData({slug: urlData[2]})
    }, [slug, urlData])

    const getProjectData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'release')
                navigateTo({route: '/error404'})
            else {
                const projectData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === projectData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === projectData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === projectData.status))
                settag(isEmpty(projectData.tag) ? [] : JSON.parse(projectData.tag))
                setcategory(isEmpty(projectData.category) ? [] : JSON.parse(projectData.category))
                setmetaKeyword(isEmpty(projectData.metaKeyword) ? [] : JSON.parse(projectData.metaKeyword))
                setTitle(projectData.title)
                settitle(projectData.title)
                setslug(projectData.slug)
                setdescription(projectData.description)
                setreleaseDate(new Date(projectData.releaseDate))
                setimage(projectData.image)
            }
        }
    }
    return {
        title,
        releaseDate,
        image,
        slug,
        description,
    }
}