import {brandInfo} from '../../../constant'
import { useContact } from './hooks'
import { useElement, useWidget } from '../../../hooks'

export const Contact = () => {
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Banner = useWidget('Banner')
    const {
        sendContactRequest,
        name,
        setcompany,
        setemail,
        setname,
        email,
        company,
        mobile,
        setmobile,
        subject,
        setsubject,
        message,
        setmessage,
    } = useContact()

    return <>
        <Banner title='We Care For Our Customer' align='center' image="contact-us-banner.jpg" />
        <div className="body-background" style={{minHeight:1}}>
            <div className="jumbo-container">
                <div className="contact-page card">
                    <form className="right-form-section" onSubmit={sendContactRequest} noValidate>
                        <div className="title-section">
                            <h1>We’d love to hear from you!</h1>
                            <p>Please fill out the form below, or email us at <Link title={'Connect with '+brandInfo.name.capital} href={"mailto:"+brandInfo.mail}>{brandInfo.mail}</Link></p>
                        </div>
                        <div className="contact-form-section">
                            <div className="form-group input-box">
                                <Input className="input" value={name} onChange={e => setname(e.target.value)} name="Name" placeholder="Enter Full Name" />
                            </div>
                            <div className="form-group input-box">
                                <Input className="input" value={company} onChange={e => setcompany(e.target.value)} name="Company" placeholder="Enter Company Name" />
                            </div>
                            <div className="form-group input-box">
                                <Input className="input" value={email} onChange={e => setemail(e.target.value)} type="email" name="Email" placeholder="Enter Email" />
                            </div>
                            <div className="form-group input-box">
                                <Input className="input" value={mobile} onChange={e => setmobile(e.target.value)} type="tel" name="Mobile" placeholder="Enter Mobile" />
                            </div>
                            <div className="form-group">
                                <Input className="input" value={subject} onChange={e => setsubject(e.target.value)} name="subject" placeholder="Enter subject" />
                            </div>
                            <div className="form-group">
                                <textarea className="input textarea" value={message} onChange={e => setmessage(e.target.value)} name="message" placeholder="Enter Message" />
                            </div>
                            <Button className="btn btn-primary" type="submit">Submit</Button>
                        </div>
                    </form>
                    <div className="left-image-section">
                        <Img alt={"Contact " + brandInfo.name.capital} className='ml40' src={'contact-tile.jpg'} />
                    </div>
                </div>
            </div>
        </div>
    </>
}
