import { memo } from 'react'
import './style.scss'
import { useElement } from '../../hooks'
import { useFeaturedServices } from './hooks'

export const FeaturedServices = memo(() => {
    const settings = {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 8,
        "centered": false,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000,
    }
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Slider = useElement('Slider')
    const {
        serviceList
    } = useFeaturedServices()

    const renderServiceList = serviceFiles => {
        return serviceFiles.map(( item, index ) => {
            return item.isFeatured && !item.trashed && <Link href={"/service/"+item.slug} key={index}>
                <span className="premium-cards">
                    {item.icon && <Img className='icon' src={JSON.parse(item.icon)[0]} alt={item.title} />}
                    {item.image && <Img className='image' src={JSON.parse(item.image)[0]} alt={item.title} />}
                </span>
                <span className='title'>{item.title}</span>
            </Link>
        })
    }

    return <section className="premium-service-box mb60-mbs40">
        <Slider {...settings}>
            { renderServiceList(serviceList) }
        </Slider>
    </section>
})
