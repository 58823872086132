export const OfferModel = [
    {
        key: 'code',
        data: String
    },
    {
        key: 'content',
        data: String
    },
    {
        key: 'discount',
        data: String
    },
    {
        key: 'discountType',
        data: String
    },
    {
        key: 'offerApplicable',
        data: String
    },
    {
        key: 'expiryDate',
        data: String
    },
    {
        key: 'status',
        data: String
    },
    {
        key: 'offerRestriction',
        data: String
    },
    {
        key: 'offerRestrictionFor',
        data: String
    },
    {
        key: 'offerForSociety',
        data: String
    },
    {
        key: 'offerForUser',
        data: String
    },
    {
        key: 'image',
        data: String
    },
    {
        key: 'uid',
        data: String
    }
]