import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { brandInfo } from "../../../../constant"

export const useOurEvents = () => {
    const {fetchByAttr} = useService('Data', 'event')
    const {setTitle} = useService('Meta')
    const {openModal} = useService('Misc')
    const [eventList, seteventList] = useState([])
    const [activeEvent, setactiveEvent] = useState(null)
    const [activeMedia, setactiveMedia] = useState(null)

    useEffect(() => {
        setTitle('Events')
        getEventData()
    }, [])

    const viewEventVideo = (item) => {
        setactiveEvent(item)
        openModal('media-modal')
        setactiveMedia(item.featuredVideo ?? brandInfo.defaultVideo)
    }

    const getEventData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status)
            seteventList(result.data)
    }
    return {
        activeMedia,
        eventList,
        viewEventVideo,
        activeEvent,
        setactiveEvent,
    }
}