import { Editor } from '@tinymce/tinymce-react'
import { base } from '../../constant'

export const PIEditor = ({
    value = '',
    initialValue = '',
    onEditorChange,
}) => {
    return <Editor
        apiKey={base.tinyMceApiKey}
        value={value}
        initialValue={initialValue}
        init={base.tinyMceEditorOptions}
        onEditorChange={onEditorChange}
    />
}