export const TableFooter = ({data}) => {
    return <tfoot>
        {data && <tr> {
            data?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>}
    </tfoot>
}