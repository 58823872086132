import { useComponent, useElement, useService } from '../../../hooks'
import { useAdminPinCode } from './hooks'

export const AdminPinCode = () => {
    const {closeModal} = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        openAddModal,
        tableContent,
        activeShownTotal,
        trashPinCode,
        trashCancel,
        showTrashConfirm,
        mode,
        addPinCode,
        activeItem,
        setactiveItem,
    } = useAdminPinCode()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Pin Code <Button onClick={openAddModal} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashPinCode} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal pinCode-modal" id="pinCode-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                    setactiveItem(null)
                    closeModal("pinCode-modal")
                }}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="pinCode-modal-header p20">
                        <div className="heading">{mode} Pin Code</div>
                        {activeItem && <form noValidate onSubmit={addPinCode}> 
                            <div className="form-group mb20">
                                <Input className="input" required name="code" value={activeItem.code} onChange={e => setactiveItem({...activeItem, code: e.target.value}) } />
                                <label>Pin Code</label>
                                <small className="msg text-danger">Please enter a Pin Code</small>
                            </div>
                            <div className="form-group mb20">
                                <Input className="input" required name="area" value={activeItem.area} onChange={e => setactiveItem({...activeItem, area: e.target.value}) } />
                                <label>Area</label>
                                <small className="msg text-danger">Please enter a Area</small>
                            </div>
                            <div className="form-group mb20">
                                <Input className="input" required name="district" value={activeItem.district} onChange={e => setactiveItem({...activeItem, district: e.target.value}) } />
                                <label>District</label>
                                <small className="msg text-danger">Please enter a District</small>
                            </div>
                            <div className="form-group mb20">
                                <Input className="input" required name="state" value={activeItem.state} onChange={e => setactiveItem({...activeItem, state: e.target.value})} />
                                <label>State</label>
                                <small className="msg text-danger">Please enter a State</small>
                            </div>
                            <div className="flex-box relative mb20">
                                <span>Inactive</span>
                                <label className="switch">
                                    <Input type="checkbox" onChange={() => setactiveItem({...activeItem, status: !activeItem.status})} checked={activeItem.status?'checked':false} />
                                    <span className="slider round"></span>
                                </label>
                                <span>Active</span>
                            </div>
                            <div className="buttons">
                                <Button type="submit" title="Click here to Add Pin Code" className="btn btn-primary btn-small">Submit</Button>
                            </div>
                        </form>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
