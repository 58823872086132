import { useElement } from "../../../../hooks"
import { useGarageTotal } from "./hooks"
export const GarageTotal = ({
    data = {},
    action = {}
}) => {
    const Input = useElement('Input')
    const Button = useElement('Button')
    const {
        applyFilters,
        setWidgetTitle,
        title,
        count,
    } = useGarageTotal({
        data,
        action,
    })

    const renderFilters = () => {
        return <form onSubmit={applyFilters}>
            <Input onChange={setWidgetTitle} value={title} placeholder="Widget Title" />
            <Button type='submit' className='btn btn-primary hi-check-circle'></Button>
        </form>
    }

    return <>
        { data.configure ? renderFilters():
            <>
                <i className="widget-icon hi-home"></i>
                {count}
            </>
        }
    </>
}
