import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddEvent = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'event')
    const {
        getUsers
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        isEmpty,
        showAlert,
        slugify,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const urlData = urlToSplitData(getLocationData().pathname)
    const keywordOptions =  []
    const categoryOptions = []
    const tagOptions = []
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState(0)
    const [image, setimage] = useState('[]')
    const [uid, setuid] = useState('')
    const [featuredVideo, setfeaturedVideo] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [assignWorkers, setassignWorkers] = useState([])
    const [status, setstatus] = useState('')
    const [category, setcategory] = useState('')
    const [location, setlocation] = useState('')
    const [tag, settag] = useState('')

    useEffect(() => {
        setTitle('Add Event', 'admin')
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getEventData(urlData[4])
        }
        getUsersData()
    }, [])
    
    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No user added yet!' })
            else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                setassignWorkers(tableData)
            }
        }
    }
    
    const getEventData = async uid => {
        const result = await fetchByAttr({ uid })
        if (result.status) {
            const eventData = result.data[0]
            settag(isEmpty(eventData.tag) ? [] : JSON.parse(eventData.tag))
            setcategory(isEmpty(eventData.category) ? [] : JSON.parse(eventData.category))
            setmetaKeyword(isEmpty(eventData.metaKeyword) ? [] : JSON.parse(eventData.metaKeyword))
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === eventData.robots))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === eventData.status))
            setactiveAuthorIndex(authorOption.findIndex(e => e.value === eventData.status))
            settitle(eventData.title)
            setmode('Edit')
            setslug(eventData.slug)
            setfeaturedVideo(eventData.featuredVideo ?? '')
            setcontent(eventData.content)
            setexcerpt(eventData.excerpt)
            setcss(eventData.css)
            setjs(eventData.js)
            setcanonical(eventData.canonical)
            setrobots(eventData.robots)
            setmetaDescription(eventData.metaDescription)
            setpublishDate(new Date(eventData.publishDate))
            setstatus(eventData.status)
            setuid(eventData.uid)
            setimage(eventData.image)
            setlocation(eventData.location ?? '')
        } else showAlert({ type: 'error', msg: 'No event added yet!' })
    }
    
    const addEvent = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        eventObject = {
            title,
            slug,
            featuredVideo,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            publishDate,
            status: status,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image,
            location: location
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                eventObject.uid = generateUID()
                const result = await save(eventObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Event added successfully!' })
                    navigateTo({route: '/admin/event/list'})
                } else showAlert({ type: 'error', msg: 'Unable to add event!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    eventData: JSON.stringify(eventObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Event updated successfully!' })
                    navigateTo({route: '/admin/event/list'})
                } else showAlert({ type: 'error', msg: 'Unable to update event!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const addTitle = e => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    let pageTitle = mode + ' Event',
    activeRobots = robotsOption[activeRobotsIndex],
    activeStatus = statusOption[activeStatusIndex]
    robotsOption.forEach(e => {
        if (e.value === robots)
            activeRobots = e
    })
    statusOption.forEach(e => {
        if (e.value === status)
            activeStatus = e
    })
    if (canonical === '')
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    return {
        statusOption,
        robotsOption,
        pageTitle,
        addEvent,
        title,
        addTitle,
        slug,
        setslug,
        content,
        setcontent,
        excerpt,
        setexcerpt,
        location,
        setlocation,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        setpublishDate,
        publishDate,
        activeStatus,
        setstatus,
        category,
        setcategory,
        categoryOptions,
        tag,
        settag,
        featuredVideo,
        setfeaturedVideo,
        tagOptions,
        image,
        setimage,
    }
}