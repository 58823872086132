import { useAddBadge } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddBadge = () => {
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        setBadgeTitle,
        title,
        image,
        setimage,
        addBadge,
    } = useAddBadge()
    let pageTitle = 'Add Badge'
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/badge/list' />
            {pageTitle}
        </h1>
        <div className="columns">
            <div className="left">
                <Box title='Title' required>
                    <Input required name="title" value={title} onChange={setBadgeTitle} />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} title='Badge Image' usage={['badge']} />
            </div>
            <div className="right">
                <form noValidate onSubmit={addBadge}>
                    <Box title='Meta'>
                        <Button className="btn-primary btn" type="submit" title="Click here to Add badge">Submit</Button>
                    </Box>
                </form>
            </div>
        </div>
    </div>
}
