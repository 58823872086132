export const PostModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'slug',
        dataType: String
    },
    {
        key: 'content',
        dataType: String
    },
    {
        key: 'excerpt',
        dataType: String
    },
    {
        key: 'css',
        dataType: String
    },
    {
        key: 'js',
        dataType: String
    },
    {
        key: 'canonical',
        dataType: String
    },
    {
        key: 'robots',
        dataType: String
    },
    {
        key: 'metaDescription',
        dataType: String
    },
    {
        key: 'metaKeyword',
        dataType: String
    },
    {
        key: 'author',
        dataType: String
    },
    {
        key: 'publishDate',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'category',
        dataType: String
    },
    {
        key: 'tag',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    }
]