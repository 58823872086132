import { 
    Game,
    Admin,
    Public,
    PreLogin,
    PostLogin,
 } from '../render'

const renders: any = {
    "Game": Game,
    "Admin": Admin,
    "Public": Public,
    "PreLogin": PreLogin,
    "PostLogin": PostLogin
}

export const useRender = (render: string) => {
    return renders[render]
}