import { 
    Business,
    Event,
    Franchise,
    Garage,
    Job,
    Page,
    Post,
    Product,
    Project,
    Service,
 } from '../template'

const templates: any = {
    "Business": Business,
    "Event": Event,
    "Franchise": Franchise,
    "Garage": Garage,
    "Job": Job,
    "Page": Page,
    "Post": Post,
    "Product": Product,
    "Project": Project,
    "Service": Service,
}

export const useTemplate = (template: string) => {
    return templates[template]
}