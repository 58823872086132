import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddBadge = () => {
    const {save} = useService('Data', 'badge')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const [title, settitle] = useState('')
    const [image, setimage] = useState('[]')

    const addBadge = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        badgeObject = {
            title,
            image
        }
        if (image === '[]') {
            errorMsg = 'Please select badge image!'
            error = true
        }
        if (title === '') {
            errorMsg = 'Please enter badge title!'
            error = true
        }
        if (!error) {
            badgeObject.uid = generateUID()
            const result = await save(badgeObject)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Badge added successfully!' })
                navigateTo({route: '/admin/badge/list'})
            } else {
                showAlert({ type: 'error', msg: 'Unable to add badge!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    useEffect(() => {
        setTitle('Add Badge', 'admin')
    }, [])

    const setBadgeTitle = e => settitle(e.target.value)
    return {
        setBadgeTitle,
        title,
        image,
        setimage,
        addBadge,
    }
}