import { useElement, useService } from '../../hooks'
import { usePackagesData } from './hooks'

export const PackagesData = ({
    partialPackage = '',
    startDate = new Date(),
    endDate = new Date(),
    price = '',
    billing = '',
    activeCar,
    activePackage = '',
}) => {
    const {
        toIndianCurrency,
    } = useService('Misc')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const {
        bestFriendPackagePrice,
        friendPackagePrice,
        buddyPackagePrice,
        purchase,
        toggleBillingPeriod,
        billedQuarterly,
    } = usePackagesData({
        startDate,
        endDate,
        price,
        billing,
        activeCar,
        partialPackage,
    })
    return <div className="pricing-table-background">
        {
            activePackage === '' && partialPackage === '' &&
            <>
                <div className="flex-box switch-box">
                    <span>Billed Monthly</span>
                    <label className="switch">
                        <Input type="checkbox" onChange={toggleBillingPeriod} checked={billedQuarterly?'checked':false} />
                        <span className="slider round"></span>
                    </label>
                    <span>Billed Quaterly</span><br />
                </div>
                <p className="tac"><b>Note : </b>10% Off on Quarterly Payments</p>
            </>
        }
        <div className="pricing-table-grid-box mt20 mb20">
            <div className="pricing-detail-box">
                <div className="grid-Box">
                    <div className="upper-part">
                        <h1 className="plan-heading">SERVICES</h1>
                        <p>*4 days weekly off in a month</p>
                    </div>
                    <div className="middle-part">
                        <div className="features"> Preventive Checks <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Car Wash <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> <span>Car Cleaning <span className="subService-name">(Waterless)</span></span> <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Vacuuming <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Air Pressure <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Interior Dusting <i className="hi-arrow-circle-right"></i></div>
                        {activeCar && <div className="features"><b>Pricing</b> <i className="hi-arrow-circle-right"></i></div>}
                    </div>
                </div>
            </div>
            <div className="pricing-table-grid">
                {
                    activePackage === '' && partialPackage === '' &&
                    <>
                        <div className="grid-Box">
                            <div className="upper-part">
                                <h1 className="plan-heading">Buddy</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly</div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Week </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(buddyPackagePrice)}</span> </div></div>}
                            </div>
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('BASIC', 'Buddy')}>Avail Now</Button>
                            </div>
                        </div>
                        <div className="grid-Box grid-box-center">
                            <div className="upper-part">
                                <h1 className="plan-heading">Friend</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly </div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Week </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(friendPackagePrice)}</span> </div></div>}
                            </div>
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => purchase('ADVANCE', 'Friend')}>Avail Now</Button>
                                </div>
                            <div className="most-popular">Most Popular</div>
                        </div>
                        <div className="grid-Box">
                            <div className="upper-part">
                                <h1 className="plan-heading">Best Friend</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(bestFriendPackagePrice)}</span> </div></div>}
                            </div>
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                            </div>
                        </div>
                    </>
                }
                {
                    activePackage === 'Buddy' ?
                    <div className="grid-Box">
                        <div className="upper-part">
                            <h1 className="plan-heading">Buddy</h1>
                        </div>
                        <div className="middle-part">
                            <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i>Monthly</div></div>
                            <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                            <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                            <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                            <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                            <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Week </div></div>
                            {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(buddyPackagePrice)}</span> </div></div>}
                        </div>
                        {
                            activePackage === '' &&
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('BASIC', 'Buddy')}>Avail Now</Button>
                            </div>
                        }
                    </div>
                    :activePackage === 'Friend' || partialPackage === 'PACKAGE__001'?
                    <div className="grid-Box grid-box-center">
                        <div className="upper-part">
                            <h1 className="plan-heading">Friend</h1>
                        </div>
                        <div className="middle-part">
                            <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly </div></div>
                            <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                            <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                            <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                            <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                            <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Week </div></div>
                            {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(friendPackagePrice)}</span> </div></div>}
                        </div>
                        {
                            activePackage === '' &&
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('ADVANCE', 'Friend')}>Avail Now</Button>
                            </div>
                        }
                        <div className="most-popular">Most Popular</div>
                    </div>
                    :activePackage === 'Best Friend' &&
                    <div className="grid-Box">
                        <div className="upper-part">
                            <h1 className="plan-heading">Best Friend</h1>
                        </div>
                        <div className="middle-part">
                            <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                            <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                            <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                            <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                            <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                            <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                            {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(bestFriendPackagePrice)}</span> </div></div>}
                        </div>
                        {
                            activePackage === '' &&
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                            </div>
                        }
                    </div>
                }
                {
                    (partialPackage === 'PACKAGE__002' || partialPackage === 'PACKAGE__001') &&
                    <div className="grid-Box">
                        <div className="upper-part">
                            <h1 className="plan-heading">Best Friend</h1>
                        </div>
                        <div className="middle-part">
                            <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                            <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                            <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                            <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                            <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                            <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                            {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">{toIndianCurrency(bestFriendPackagePrice)}</span> </div></div>}
                        </div>
                        {
                            activePackage === '' &&
                            <div className="bottom-part">
                                <Button className="btn btn-primary btn-box" onClick={() => purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
}
