import { useContext } from "react"
import { NavigatorContext } from "../../../../context"
import { useService } from "../../../../hooks"

export const useUnderDevelopment = () => {
    const {setTitle} = useService('Meta')
    const {navigateTo} = useContext(NavigatorContext)
    setTitle('Website is under development')

    const authenticate = () => {
        if (document.getElementById('authKey').value === 'Pk@9462678919') {
            localStorage.setItem('authUser', 'yes')
            navigateTo({route: '/'})
        }
    }
    return {
        authenticate
    }
}