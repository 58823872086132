import Select from 'react-select'
import { useServiceList } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const ServiceList = () => {
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        trashAction,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectService,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    } = useServiceList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="flex title mb50">
            {
                trashAction ? 'Trashed' : 'All'
            } Services
            {
                !trashAction &&
                <>
                    <Link className="btn btn-primary btn-small ml20" href="/admin/service/add">Add</Link>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button onClick={() => {
                            setisMultipleDelete(true)
                            setshowTrashConfirm(true)
                        }} className="btn btn-outline btn-small">Delete</Button>
                    }
                </>
            }
            </h1>
        <div className="filter-box">
            {!trashAction && <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i, tableData)
                    }}
                    options={filterTags}
                />
            </div>}
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():deleteSelectService()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
