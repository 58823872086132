import { useEffect, useState } from "react"
import { base, timeSlot } from "../../../../constant"
import { useService } from "../../../../hooks"

export const useServices = () => {
    const {
        getLoggedInUserData,
        getActiveSubscription,
    } = useService('User')
    const {
        getActiveCar,
        isEmpty,
        closeModal,
        openCarSelectModal,
        generateUID,
        toIndianCurrency,
        showAlert,
        openModal,
    } = useService('Misc')
    const {setTitle} = useService('Meta')
    const {
        getServiceCategory,
        getService,
    } = useService('Service')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart} = useService('Order')
    const user = getLoggedInUserData()
    const [serviceList, setserviceList] = useState([])
    const [activeService, setactiveService] = useState('')
    const [orderDate, setorderDate] = useState('')
    const [chooseServiceTime, setchooseServiceTime] = useState(false)
    const [activeTimeSlot, setactiveTimeSlot] = useState(timeSlot[0])
    const [selectPick, setselectPick] = useState(false)
    const [showPick, setshowPick] = useState(false)
    const [activeCar, setactiveCar] = useState(getActiveCar())
    const [activeBanner, setactiveBanner] = useState("services-banner.jpg")
    const [pageTitle, setpageTitle] = useState('Detailing Services')
    
    useEffect(() => {
        setTitle(pageTitle)
        getServicesData()
    }, [])

    const getServiceCategoryData = async (categorySlug) => {
        const result = await getServiceCategory()
        if (result.data?.length !== 0) {
            result.data.forEach(e => {
                if(e.catSlug === categorySlug) {
                    setpageTitle(`Car Services - ${e.catName}`)
                    setTitle(e.catName+' | Car Services')
                    setactiveBanner(`${categorySlug}-banner.jpg`)
                }
            })
        }
    }

    const getServicesData = async () => {
        const urlData = urlToSplitData(getLocationData().pathname)
        if(!isEmpty(urlData[2])) {
            getServiceCategoryData(urlData[2])
        }
        const result = await getService({status: 'publish'})
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = []
                result.data.forEach(e => {
                    if (typeof e.trash === "undefined" || !e.trash) {
                        if (isEmpty(urlData[2])) {
                            serviceList.push(e)
                        } else if (e.category && e.category?.search(urlData[2]) !== -1) {
                            serviceList.push(e)
                        }
                    }
                })
                serviceList.sort( compare )
                setserviceList(serviceList)
                const urlHash = getLocationData().hash.replace('#', '')
                if (!isEmpty(urlHash)) {
                    let element = document.querySelector("#"+urlHash)
                    setactiveService(urlHash)
                    try {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    } catch (error) {
                        console.log('error', error)
                    }
                }
            }
        }
    }
    
    const compare = ( a, b, key = 'publishDate' ) => {
        if ( (new Date(a[key])) < (new Date(b[key])) )
            return 1
        if ( (new Date(a[key])) > (new Date(b[key])) )
            return -1
        return 0
    }
    
    const chooseActiveCar = () => {
        closeModal("add-to-cart-modal")
        let prices = '[]', activeServiceData = null, price = 0, found = false
        serviceList.forEach(e => {
            if (activeService === e.slug)
                activeServiceData = e
        })
        prices = JSON.parse(JSON.parse(activeServiceData.prices))
        if (activeCar === null) {
            openCarSelectModal()
            checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (getActiveSubscription() !== null) {
                            c.saleprice = c.saleprice*0.9
                        }
                    }
                    let mrp = Number(c.price)
                    price = Number(c.saleprice)
                    // price += price*Number(activeServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    // c.saleprice = c.saleprice + c.saleprice*Number(activeServiceData.tax)*0.01
                    c.saleprice = Number(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    // c.price = c.price + c.price*Number(activeServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    serviceAddedTemplate(price, activeServiceData, mrp, c.saleprice)
                }
            })
        }
    }
    
    const checkForActiveCar = () => {
        let prices = '[]', activeServiceData = null, price = 0, found = false
        serviceList.forEach(e => {
            if (activeService === e.slug)
                activeServiceData = e
        })
        prices = JSON.parse(JSON.parse(activeServiceData.prices))
        if (activeCar === null) {
            openCarSelectModal()
            checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (getActiveSubscription() !== null)
                            c.saleprice = c.saleprice*0.9
                    }
                    let mrp = Number(c.price)
                    price = Number(c.saleprice)
                    // price += price*Number(activeServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    // c.saleprice = c.saleprice + c.saleprice*Number(activeServiceData.tax)*0.01
                    c.saleprice = Number(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    // c.price = c.price + c.price*Number(activeServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    serviceAddedTemplate(price, activeServiceData, mrp, c.saleprice)
                }
            })
        }
    }
    
    const serviceAddedTemplate = async (derivedPrice, item, mrp, saleprice) => {
        let price = Number(derivedPrice)
        let service = {
            orderDate,
            uid: item.uid,
            activeTimeSlot,
            activeCar,
            title: activeCar.registrationNo ? item.title + ' ['+activeCar.registrationNo+']' : item.title,
            image: JSON.parse(item.image)[0],
            price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: generateUID(),
            tax: item.tax,
            hsn: item.hsn,
            mrp,
            saleprice,
            selectPick,
            taxAmount: Number((price * (Number(item.tax))/100).toFixed(2))
        };
        service.displayPrice = (service.price + service.taxAmount).toFixed(2)
        let productAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(item.image)[0]+'" alt="'+item.title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+item.title+'</p>'+
        '<p class="service-price">'+toIndianCurrency(service.displayPrice)+'</p>'+
        '</div>'+
        '</div>'
        await addToCart(service)
        if (activeService !== 'pick-drop' && selectPick) {
            setactiveService('pick-drop')
            chooseActiveCar()
        }
        else {
            setchooseServiceTime(false)
            setactiveService('')
            setorderDate('')
            setactiveTimeSlot(timeSlot[0])
            setactiveCar('')
            setselectPick(false)
            setshowPick(false)
        }
        showAlert({ type: 'custom', template: productAddedTemplate })
    }
    
    const buyItem = (buyType, item) => {
        if (item.slug !== 'pick-drop') {
            setshowPick(true)
        }
        if (activeCar !== null) {
            setactiveService(item.slug)
            openModal("add-to-cart-modal")
        } else openCarSelectModal()
    }
    
    let today = new Date(),
    tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return {
        activeCar,
        activeService,
        user,
        buyItem,
        setactiveTimeSlot,
        activeTimeSlot,
        pageTitle,
        activeBanner,
        serviceList,
        setorderDate,
        setchooseServiceTime,
        today,
        orderDate,
        chooseServiceTime,
        showPick,
        setselectPick,
        selectPick,
        chooseActiveCar,
    }
}