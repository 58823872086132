export const mainUrl = process.env.REACT_APP_MAIN_URL
export const urlApi = process.env.REACT_APP_API_URL
export const base = {
    googleApiKey: process.env.REACT_APP_BASE_googleApiKey,
    googleApiKeyWebOnly: process.env.REACT_APP_BASE_googleApiKeyWebOnly,
    googleSignInCleintSecret: process.env.REACT_APP_BASE_googleSignInCleintSecret,
    googleSignInCleintId: process.env.REACT_APP_BASE_googleSignInCleintId,
    imageBaseUrl: process.env.REACT_APP_CDN_URL,
    invoiceBaseUrl: process.env.REACT_APP_INVOICE_URL,
    carImageBase: process.env.REACT_APP_CDN_URL,
    jaipurCoordinates: {
        lat: 26.9124,
        lng: 75.7873
    },
    androidApp: process.env.REACT_APP_BASE_androidApp,
    iosApp: process.env.REACT_APP_BASE_iosApp,
    fbAppId: process.env.REACT_APP_BASE_fbAppId,
    tinyMceApiKey: process.env.REACT_APP_BASE_tinyMceApiKey,
    tinyMceEditorOptions: {
        height: 300,
        menubar: false,
        relative_urls : false,
        remove_script_host : false,
        plugins: [
            "advlist lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code wordcount"
        ],
        toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code"
    }
}
export const config = {
    sendMail: urlApi+"utils/sendmail",
    commonPageSize: 50
}
export const socialLinks = {
    fb: process.env.REACT_APP_SOCIAL_LINK_FB,
    insta: process.env.REACT_APP_SOCIAL_LINK_INSTA,
    linkedin: process.env.REACT_APP_SOCIAL_LINK_LINKEDIN,
    google: process.env.REACT_APP_SOCIAL_LINK_GOOGLE,
    twitter: process.env.REACT_APP_SOCIAL_LINK_TWITTER,
    youtube: process.env.REACT_APP_SOCIAL_LINK_YOUTUBE
}
export const dev = {
    brandKey: process.env.REACT_APP_DEV_brandKey,
    api: {
        app_id: process.env.REACT_APP_DEV_api_app_id,
        app_secret: process.env.REACT_APP_DEV_api_app_secret
    }
}
export const brandInfo = {
    name: {
        uppercase: process.env.REACT_APP_BRANDNAME_uppercase,
        lowercase: process.env.REACT_APP_BRANDNAME_lowercase,
        capital: process.env.REACT_APP_BRANDNAME_capital
    },
    aboutText: process.env.REACT_APP_BRANDINFO_aboutInfo,
    logo: require('../../../assets/images/logo-black.png'),
    logoWhite: require('../../../assets/images/logo.png'),
    phone: process.env.REACT_APP_BRANDINFO_phone,
    mail: process.env.REACT_APP_BRANDINFO_mail,
    companyName: process.env.REACT_APP_BRANDINFO_companyName,
    siteName: process.env.REACT_APP_BRANDINFO_siteName,
    GSTIN: process.env.REACT_APP_BRANDINFO_GSTIN,
    CIN: process.env.REACT_APP_BRANDINFO_CIN,
    siteTagline: process.env.REACT_APP_BRANDINFO_siteTagline,
    address: process.env.REACT_APP_BRANDINFO_address,
    addressLink: process.env.REACT_APP_BRANDINFO_addressLink,
    defaultVideo: 'GRyzRe51Ycg?si=XtXzBJ2NfDgD35HH',
}