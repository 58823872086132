import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const usePayment = () => {
    const {
        getLoggedInUserData,
        getUser,
        login,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        emptyCart,
        emptyOrder,
    } = useService('Order')
    const [status, setstatus] = useState(1)

    useEffect(() => {
        setTitle('Payment Status')
        updateUserData()
    }, [])

    const updateUserData= async () => {
        let query = {
            uid: getLoggedInUserData().uid
        }
        const result = await getUser(query)
        if (result.status) {
            await login(result.data[0])
            emptyOrderData()
        }
    }
    
    const emptyOrderData = () => {
        emptyCart()
        emptyOrder()
    }
    
    const urlData = urlToSplitData(getLocationData().pathname)
    return {
        status,
        urlData,
    }
}