import './style.scss'
import { useElement } from '../../hooks'
import { useDetailingServices } from './hooks'
import { base } from '../../constant'

export const DetailingServices = () => {
    const {
        serviceList,
        activeMedia,
        activeService,
        closeModal,
        viewServiceVideo,
    } = useDetailingServices()
    const Slider = useElement('Slider')
    const Button = useElement('Button')
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }
    const renderServiceList = () => {
        return serviceList.map((e, i) => {
            return <div className='detailing-box' key={i}>
                <div className='detailing-service' style={{backgroundImage: `url('${base.imageBaseUrl}${JSON.parse(e.image)[0]}')`}}>
                    <Button className="btn btn-primary" onClick={() => viewServiceVideo(e)}>View Full Video</Button>
                </div>
                <h3 className='tac'>{e.title}</h3>
            </div>
        })
    }
    return <section className="detailing-services-section">
        <div className='jumbo-container'>
            <h2 className="heading-02">Detailing Excellence in Every Frame</h2>
            <Slider {...settings}>
                { renderServiceList() }
            </Slider>
        </div>
        <div className="modal media-modal" id="media-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("media-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        activeService && activeMedia && <iframe className="preview-video" src={'https://www.youtube-nocookie.com/embed/'+activeMedia} title={activeService.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    }
                </div>
            </div>
        </div>
    </section>
}
