import {CSVLink} from 'react-csv'
import Select from 'react-select'
import { useServiceOrders } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const ServiceOrders = () => {
    const {
        closeModal,
        getFormattedDate,
        getFormattedTime,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const {
        trackScrolling,
        filteredList,
        exportedData,
        setstartDate,
        setendDate,
        startDate,
        endDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        serviceWorkAwaitedStatus,
        serviceData,
        servicework,
        workData,
    } = useServiceOrders()
    const exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'
    
    const renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}><Img src={item} alt={item} /></li>
        })
    }
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="flex title">
            Service Orders
            {
                filteredList.length !== 0 &&
                <span className="btn btn-primary btn-small ml20 export-btn">
                    <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
                </span>
            }
        </h1>
        <div className="filter-box">
            <div className="flex date-filter overflow-visible">
                <div className="form-group">
                    <DatePicker
                        selected={startDate}
                        onChange={startDate => setstartDate(startDate)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="From:"
                    />
                </div>
                <div className="form-group">
                    <DatePicker
                        selected={endDate}
                        onChange={endDate => setendDate(endDate)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        className="datePicker-filter"
                        placeholderText="To:"
                    />
                </div>
                <div className="form-group ml10">
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => {
                            setactiveFilter(e)
                            filterItems(e.value, i, tableData)
                        }}
                        options={filterTags}
                    />
                </div>
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <div className="modal" id="car-service-status-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content content medium">
                <Button className="close" onClick={() => closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    {
                        serviceWorkAwaitedStatus ?
                        <>
                        <div className="no-data-icon">
                            <i className="hi-hourglass_empty"></i>
                        </div>
                        <p className="awited-text">Work is still awaited for this service</p></> :
                        <div>
                            {
                                servicework !== null &&
                                <>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Title</label>
                                        <div className="col-9 controls ml20">
                                            {servicework.title}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Service Date</label>
                                        <div className="col-9 controls ml20">
                                            {getFormattedDate(new Date(servicework.serviceDate))}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Start Time</label>
                                        <div className="col-9 controls ml20">
                                            {getFormattedTime(new Date(workData[0].startTime))}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Active Car</label>
                                        <ul className="col-9 controls ml20">
                                            <li className="my-packages-assign-car">
                                                {
                                                    serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                    <Img source='carCDN' src={(JSON.parse(servicework.activeCar).image_path)} alt={(servicework.activeCar).name} />
                                                }
                                                <div className="ml10">
                                                    <p>{serviceData.name + ' ' + serviceData.Segment}</p>
                                                    {
                                                        serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null && <small>{serviceData.registrationNo}</small>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Comments</label>
                                        <div className="col-9 controls ml20">
                                            {workData[0].comments}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Images</label>
                                        <div className="col-9 controls ml20">
                                            <ul className="media-box">
                                                {renderImages(workData[0].carImages || [] )}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}
