import { useEffect, useState } from "react"
import { useService } from "../../../hooks"

export const useRecentPosts = () => {
    const {fetchByAttr} = useService('Data', 'post')
    const [postList, setpostList] = useState([])
    const [newLength, setnewLength] = useState(0)
    const getPostsData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            setpostList(result.data)
            setnewLength(result.data?.length)
        }
    }
    useEffect(() => {
        getPostsData()
    }, [])
    return {
        postList,
        newLength,
    }
}