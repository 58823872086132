import { useContext, useEffect, useState } from "react"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useFranchise = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'franchise')
    const {setTitle} = useService('Meta')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [image, setimage] = useState([])
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [content, setcontent] = useState('')
    const [owner, setowner] = useState('')
    const [contactPerson, setcontactPerson] = useState('')
    const [address, setaddress] = useState('')
    const [addressDetail, setaddressDetail] = useState('')
    const [companyName, setcompanyName] = useState('')
    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState('')
    const [city, setcity] = useState('')
    const [places, setplaces] = useState([])
    const [socialLinks, setsocialLinks] = useState(null)

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getFranchiseData({slug: urlData[2]})
    }, [slug, urlData])

    useEffect(() => {
        handleSelect(address)
    }, [address])

    const handleSelect = async address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
            let places = []
            places.push(latLng)
            if (latLng !== null) {
                setplaces(places)
            }
        })
        .catch(e => console.log('error', e))
    }

    const getFranchiseData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'publish')
                navigateTo({route: '/error404'})
            else {
                const franchiseData = result.data[0]
                const addressDetail = JSON.parse(franchiseData.addressDetail)
                setTitle(franchiseData.title)
                settitle(franchiseData.title)
                setslug(franchiseData.slug)
                setcontent(franchiseData.content)
                setimage(JSON.parse(franchiseData.image))
                setcompanyName(franchiseData.companyName)
                setemail(franchiseData.email)
                setmobile(franchiseData.mobile)
                setaddress(franchiseData.address)
                setowner(JSON.parse(franchiseData.owner))
                setcontactPerson(JSON.parse(franchiseData.contactPerson))
                setaddressDetail(addressDetail)
                setsocialLinks(JSON.parse(franchiseData.socialLinks))
                addressDetail.address_components?.forEach(l => {
                    if (l.types?.includes("administrative_area_level_2")) {
                        setcity(l.long_name)
                    }
                })
            }
        }
    }
    return {
        image,
        title,
        companyName,
        city,
        content,
        addressDetail,
        places,
        address,
        email,
        contactPerson,
        mobile,
        socialLinks,
    }
}