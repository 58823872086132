import { useComponent, useElement } from '../../../hooks'
import { useTestimonialList } from './hooks'

export const TestimonialList = () => {
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        tableContent,
        activeShownTotal,
        deleteChecked,
        trashTestimonial,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    } = useTestimonialList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">
            All Testimonials
            <Link className="btn btn-primary btn-small ml20" href="/admin/testimonial/add">Add</Link>
            {
                (checkedList.length !== 0 || checkedAll) &&
                <Button onClick={() => {
                    setisMultipleDelete(true)
                    setshowTrashConfirm(true)
                }} className="btn btn-outline btn-small">Delete</Button>
            }
        </h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={() => {
            isMultipleDelete?deleteChecked():trashTestimonial()
        }} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
    </div>
}
