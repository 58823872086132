import { useComponent, useElement, useService } from '../../../hooks'
import { useMyPackages } from './hooks'

export const MyPackages = () => {
    const {closeModal} = useService('Misc')
    const Button = useElement('Button')
    const PackagesData = useComponent('PackagesData')
    const Table = useComponent('Table')
    const {
        tableContent,
        activeShownTotal,
        activePackage,
    } = useMyPackages()
    return <>
        <div id="content-box" className="content">
            <h1>My Packages</h1>
            <div className="package-table">
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
        </div>
        <div className="modal" id="get-packages-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-packages-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <PackagesData activePackage={activePackage} />
                </div>
            </div>
        </div>
    </>
}
