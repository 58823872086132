import PlacesAutocomplete from 'react-places-autocomplete'
import Select from 'react-select'
import { base } from '../../../constant'
import { useAddSociety } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddSociety = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const Map = useComponent('Map')
    const {
        mode,
        addSociety,
        title,
        settitle,
        address,
        handleChange,
        handleSelect,
        showSuggestions,
        saveSuggestion,
        addressDetail,
        places,
        locality,
        setlocality,
        city,
        setcity,
        state,
        setstate,
        pinCode,
        setpinCode,
        supervisor,
        setsupervisor,
        supervisorOptions,
        assignWorker,
        selectAssignedWorker,
        assignWorkers,
    } = useAddSociety()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/society/list' />
            {mode + ' Society'}
        </h1>
        <form className="columns" noValidate onSubmit={addSociety}>
            <div className="left">
                <Box title='Title' required>
                    <Input name="title" value={title} onChange={e => settitle(e.target.value)} />
                </Box>
                <Box title='Address' required>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                                <Input
                                    {...getInputProps({
                                        placeholder: 'Search Places . . .',
                                        className: 'location-search-input input',
                                    })}
                                />
                                {
                                    showSuggestions &&
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion, index ) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                            return <div
                                                {...getSuggestionItemProps(suggestion, { className })}
                                                key={index} onClick={() =>saveSuggestion(suggestion)}>{suggestion.description}
                                            </div>
                                        })}
                                    </div>
                                }
                            </>
                        )}
                    </PlacesAutocomplete>
                </Box>
                <div className="mv20">
                    {
                        (addressDetail.address_components?.length || places.length !== 0) &&
                        <>
                            <Map
                                googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+base.googleApiKey}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                center={places[0]}
                                zoom={14}
                                places={places}
                            />
                            <Box title='Locality (Street/Area)' required boxClass='mt20'>
                                <Input name="locality" value={locality} onChange={e => setlocality(e.target.value)} />
                            </Box>
                            <Box title='City' required>
                                <Input name="city" value={city} onChange={e => setcity(e.target.value)} />
                            </Box>
                            <Box title='State' required>
                                <Input name="state" value={state} onChange={e => setstate(e.target.value)} />
                            </Box>
                            <Box title='Pincode' required>
                                <Input name="pinCode" value={pinCode} onChange={e => setpinCode(e.target.value)} />
                            </Box>
                        </>
                    }
                </div>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn-primary btn" type="submit" title="Click here to Add Societies">Submit</Button>
                </Box>
                <Box title='Assign Supervisor'>
                    <Select
                        value={supervisor}
                        defaultValue={supervisor}
                        onChange={e => setsupervisor(e)}
                        options={supervisorOptions}
                    />
                </Box>
                <Box title='Assign Workers'>
                    <Select
                        value={assignWorker}
                        defaultValue={assignWorker}
                        onChange={selectAssignedWorker}
                        options={assignWorkers}
                        isMulti
                    />
                </Box>
            </div>
        </form>
    </div>
}
