import { useContext, useEffect, useMemo, useState } from "react"
import { useService } from "../../../../hooks"
import { searchRegex } from "../../../../constant"
import { NavigatorContext } from "../../../../context"

export const useHeader = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        getLoggedInUserData,
        isUserLoggedIn,
        logout: logoutUser,
    } = useService('User')
    const {
        getActiveCar,
        isDesktop,
        isModuleEnabled,
        getThemeMode,
        setThemeMode,
        toggleCartBox,
        toggleBodyClass,
    } = useService('Misc')
    const {
        getLocationData,
        navigateTo: moveTo,
    } = useService('Router')
    const {getCartData: fetchCartData} = useService('Order')
    const {getProduct} = useService('Product')
    const {getService} = useService('Service')
    const user = getLoggedInUserData()
    const currentUrl = getLocationData().href
    const [temporaryUser, settemporaryUser] = useState(false)
    const [showUserNav, setshowUserNav] = useState(false)
    const [searchList, setsearchList] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [userProfileImage, setuserProfileImage] = useState('')
    const [searchKey, setsearchKey] = useState('')
    const [activeCar, setactiveCar] = useState(getActiveCar())
    const [cityDetail, setcityDetail] = useState(false)
    const [showSideCart, setshowSideCart] = useState(false)
    const [showNavBar, setshowNavBar] = useState(false)
    const [showSearchResult, setshowSearchResult] = useState(false)
    const [cartCount, setcartCount] = useState(0)
    const [activeTopBar, setactiveTopBar] = useState(isDesktop())
    const showCarSelector = useMemo(() => () => {
        return isModuleEnabled(["Car"])
        && !currentUrl.includes('car/edit')
        && !currentUrl.includes('car/add')
        && (
            !isUserLoggedIn()
            || !['manager', 'company', 'branch', 'representative'].includes(user?.role)
        )
    }, [])

    useEffect(() => {
        // toggleThemeMode()
        if (localStorage.getItem('tempLogin') === 'true') {
            settemporaryUser(true)
        }
        getProductsData()
        getCartData()
        if(isDesktop() && isModuleEnabled(["TopNavbarBanner"])) {
            if (localStorage.getItem('topBarBanner') !== 'no')
                toggleBodyClass('pt80')
            else toggleBodyClass('pt80', 'pt0')
        }
    }, [])

    const toggleThemeMode = () => {
        if (getThemeMode() === "theme-light") {
            setThemeMode("theme-dark")
        } else setThemeMode("theme-light")
    }

    const toggleTopNavBar = () => {
        setactiveTopBar(false)
        localStorage.setItem('topBarBanner', 'no')
        toggleBodyClass('pt80', 'pt0')
    }
    
    const sendBackToAdmin = () => {
        localStorage.removeItem('tempUserData')
        localStorage.removeItem('tempLogin')
        moveTo({route: '/admin/dashboard'})
    }
    
    const getCartData = () => {
        let cartData = fetchCartData(),
        cartCount = 0
        cartData.forEach(e => {
            cartCount += e.purchaseQuantity
        })
        setcartCount(cartCount)
    }
    
    const getProductsData = async () => {
        const result = await getProduct({status: 'publish'})
        if (result.status) {
            const products = []
            result.data.forEach(e => {
                e.type = 'product'
                products.push(e)
            })
            getServicesData(products)
        }
    }
    
    const getServicesData = async (productsData) => {
        const result = await getService({status: 'publish'})
        if (result.status) {
            const services = []
            result.data.forEach(e => {
                e.type = 'service'
                services.push(e)
            })
            setsearchList([...productsData, ...services])
        }
    }
    
    const toggleUserNav = () => {
        setshowUserNav(!showUserNav)
    }
    
    const logout = () => {
        setshowUserNav(false)
        logoutUser('/')
    }
    
    const searchProduct = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            let tempSearchKey = e.target.value.toLowerCase()
            setsearchKey(e.target.value)
            let tempTitle = '', tempSku = '', filteredList = []
            searchList.forEach(e => {
                tempTitle = e.title.toLowerCase()
                tempSku = e.sku.toLowerCase()
                if (tempSku.search(tempSearchKey) !== -1 || tempTitle.search(tempSearchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
            if (tempSearchKey === '')
                document.getElementsByTagName('body')[0].classList = ''
            else
                document.getElementsByTagName('body')[0].classList = 'searchbar-open search-result-open'
        }
    }
    
    const getSelectedCar = activeCar => {
        setactiveCar(activeCar)
    }

    const productSearch = () => {

    }
    
    const resetProductSearch = () => {
        setfilteredList([])
        setsearchKey('')
    }

    const toggleCart = () => {
        setshowSideCart(!showSideCart)
        toggleCartBox()
    }
    
    const cityDescription = () => {
        setcityDetail(!cityDetail)
        toggleBodyClass('locationbar-open')
    }
    
    const navBackGround = () => {
        setshowNavBar(!showNavBar)
        toggleBodyClass('nav-open')
    }
    
    const searchResultBackGround = () => {
        setshowSearchResult(false)
        setsearchKey('')
        document.getElementsByTagName('body')[0].classList = ''
    }

    const navigateToLink = (link) => {
        navigateTo({route: link})
        if (!isDesktop()) toggleBodyClass('nav-open')
    }

    return {
        showCarSelector,
        getSelectedCar,
        toggleTopNavBar,
        activeTopBar,
        cityDetail,
        cityDescription,
        cartCount,
        toggleUserNav,
        userProfileImage,
        showUserNav,
        sendBackToAdmin,
        logout,
        productSearch,
        resetProductSearch,
        searchResultBackGround,
        user,
        showSideCart,
        navBackGround,
        toggleCart,
        searchKey,
        searchProduct,
        filteredList,
        settemporaryUser,
        temporaryUser,
        activeCar,
        currentUrl,
        navigateToLink,
    }
}