import { useEffect, useState } from 'react'
import {activeDateFilter as ADF, chartColor, b2bService, fuelOption, config} from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
export const useCompanyDashboard = () => {
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {commonPageSize} = config
    const activeShownTotal = commonPageSize
    const {fetchByAttr: fetchByAttrRecommendations} = useService('Data', 'recommendation')
    const {
        fetchByAttr,
        save,
    } = useService('Data', 'companyOrder')
    const {fetchByAttr: fetchByAttrCompanyData} = useService('Data', 'companyData')
    const {fetchByAttr: fetchByAttrBusinessWork} = useService('Data', 'businessWork')
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getLast7Days,
        getPreviousDate,
        isSameDate,
        openModal,
        closeModal,
        showAlert,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {getCarsStatusCount} = useService('Car')
    const defaultCompanyData = {
        adHocData: {},
        carStatusData: {
            'Active': [0, 0, 0, 0, 0, 0, 0],
            'Inactive': [0, 0, 0, 0, 0, 0, 0]
        },
        data: {
            'Services': [0, 0, 0, 0, 0, 0, 0],
            'Products': [0, 0, 0, 0, 0, 0, 0]
        },
        recommendationData: {
            'Approved': [0, 0, 0, 0, 0, 0, 0],
            'Denied': [0, 0, 0, 0, 0, 0, 0],
            'Completed': [0, 0, 0, 0, 0, 0, 0]
        },
        serviceData: {
            'Tyre Inflation': [0, 0, 0, 0, 0, 0, 0],
            'Waterless Clean': [0, 0, 0, 0, 0, 0, 0],
            'Physical Damage Observed': [0, 0, 0, 0, 0, 0, 0],
            'Physical Damage Not Observed': [0, 0, 0, 0, 0, 0, 0],
            'Cranking Ok': [0, 0, 0, 0, 0, 0, 0],
            'Cranking Not Ok': [0, 0, 0, 0, 0, 0, 0],
            'Gold Wash': [0, 0, 0, 0, 0, 0, 0],
            'Health Checkup / Inspection': [0, 0, 0, 0, 0, 0, 0],
            'Battery Charging': [0, 0, 0, 0, 0, 0, 0],
            'Puncture Repair': [0, 0, 0, 0, 0, 0, 0]
        }
    }
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState([])
    const [completed, setcompleted] = useState([])
    const [activeBranches, setactiveBranches] = useState([])
    const [branches, setbranches] = useState([])
    const [weekArray, setweekArray] = useState(getLast7Days())
    const [companyData, setcompanyData] = useState(defaultCompanyData)
    const [activeRecommendation, setactiveRecommendation] = useState(null)
    const [denied, setdenied] = useState([])
    const [approved, setapproved] = useState([])
    const [todayDenied, settodayDenied] = useState(30)
    const [todayApproved, settodayApproved] = useState(20)
    const [filteredList, setfilteredList] = useState([])
    const [companyDashboardData, setcompanyDashboardData] = useState(false)
    const [recommendData, setrecommendData] = useState([])
    const [companyOrders, setcompanyOrders] = useState([])
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[0])
    const [fromFilterDate, setfromFilterDate] = useState(getPreviousDate(2))
    const [toFilterDate, settoFilterDate] = useState(getPreviousDate(1))
    const [carsData, setcarsData] = useState({
        inactiveCars: 0,
        activeCars: 0
    })
    
    useEffect(() => {
        setTitle('Dashboard')
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getCompanyData()
            getCompanyOrders()
            getCompanyRecommendation()
            getCarsData()
        }
    }, [])

    const getBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getCarsData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await getCarsStatusCount(query)
        if (result.status)
            setcarsData(result.data)
    }
    
    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getCompanyData(activeBranchIds)
        getCompanyOrders(activeBranchIds)
        getCompanyRecommendation(activeBranchIds)
        getCarsData(activeBranchIds)
    }
    
    const getCompanyRecommendation = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttrRecommendations(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setrecommendData([])
            } else {
                let recommendData = []
                result.data.reverse().forEach(e => {
                    e.carDetails = JSON.parse(e.carDetails)
                    e.carImages = JSON.parse(e.carImages)
                    e.recommendData = JSON.parse(e.recommendData)
                    recommendData.push(e)
                })
                setrecommendData(recommendData)
                setfilteredList(recommendData)
            }
        }
    }
    
    const getCompanyData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid,
            statusDate: { $in: weekArray },
            dataType: 'car'
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        let result = await fetchByAttrCompanyData(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setcompanyOrders(null)
            } else {
                result.data.forEach(e => {
                    weekArray.forEach((d, di) => {
                        if (isSameDate(Number(e.actionDate), d)) {
                            if (e.status === 'active')
                                companyData.companyData['Active'][di]++
                            if (e.status === 'inactive')
                                companyData.companyData['Inactive'][di]++
                        }
                    })
                })
            }
        }
    }
    
    const getCompanyOrders = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        let companyData = defaultCompanyData
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setcompanyOrders(null)
            } else {
                let companyOrders = []
                result.data.reverse().forEach(e => {
                    if (e.carDetails && e.carDetails !== "undefined" && isValidJson(e.carDetails) && e.orderData && isValidJson(e.orderData)) {
                        e.carDetails = JSON.parse(e.carDetails)
                        e.carImages = JSON.parse(e.carImages)
                        e.orderData = isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                        if (['approved', 'assigned'].includes(e.status)) {
                            weekArray.forEach((d, di) => {
                                if (isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Approved'][di]++
                            })
                            approved.push(e.uid)
                        } else if (e.status === 'denied') {
                            weekArray.forEach((d, di) => {
                                if (isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Denied'][di]++
                            })
                            denied.push(e.uid)
                        } else if (e.status === 'completed') {
                            weekArray.forEach((d, di) => {
                                if (isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Completed'][di]++
                            })
                            if (e.type === 'product') {
                                weekArray.forEach((d, di) => {
                                    if (isSameDate(Number(e.actionDate), d)) {
                                        let c = e.orderData[0]
                                        if (companyData.adHocData[c.title ? c.title : c.label]) {
                                            companyData.adHocData[c.title ? c.title : c.label][di]++
                                        } else {
                                            companyData.adHocData[c.title ? c.title : c.label] = [0,0,0,0,0,0,0]
                                            companyData.adHocData[c.title ? c.title : c.label][di] = 1
                                        }
                                        companyData.data['Products'][di]++
                                    }
                                })
                            } else {
                                weekArray.forEach((d, di) => {
                                    if (isSameDate(Number(e.actionDate), d)) {
                                        let c = e.orderData[0]
                                        if (companyData.adHocData[c.title ? c.title : c.label]) {
                                            companyData.adHocData[c.title ? c.title : c.label][di]++
                                        } else {
                                            companyData.adHocData[c.title ? c.title : c.label] = [0,0,0,0,0,0,0]
                                            companyData.adHocData[c.title ? c.title : c.label][di] = 1
                                        }
                                        companyData.data['Services'][di]++
                                    }
                                })
                            }
                            completed.push(e.uid)
                        }
                    }
                    companyOrders.push(e)
                })
                setcompanyOrders(companyOrders)
                setfilteredList(companyOrders)
                setapproved(approved)
                setdenied(denied)
                getCompanyWorkData(companyData, branches)
            }
        }
    }
    
    const getCompanyWorkData = async (companyData, branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttrBusinessWork(query)
        if (result.status) {
            if (result.data?.length !== 0) {
                weekArray.forEach((d, di) => {
                result.data.reverse().forEach(e => {
                        if (isSameDate(Number(e.uid), d)) {
                            b2bService.forEach(b => {
                                companyData.serviceData[b.value][di]++
                            })
                        }
                    })
                })
            }
            setcompanyData(companyData)
        }
    }
    
    const browsePurchase = item => {
        setactiveRecommendation(item)
        openModal('recommendation-modal')
    }
    
    const approvePurchase = async item => {
        let purchaseData = {}
        approved.push(item.uid)
        purchaseData.recommendData = []
        purchaseData.comments = item.comments
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(item.carDetails)
        purchaseData.carImages = JSON.stringify(item.carImages)
        purchaseData.ownerId = item.ownerId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = item.uid
        purchaseData.status = 'approved'
        const result = await save(purchaseData)
        if (result.status) {
            closeModal('recommendation-modal')
            getCompanyOrders()
            setapproved(approved)
            settodayApproved(todayApproved+1)
            setactiveRecommendation(null)
            showAlert({ type: 'success', msg: 'Recommendation has been approved and an order is generated for the same!' })
        }
    }
    
    const denyPurchase = async item => {
        let purchaseData = {}
        denied.push(item.uid)
        purchaseData.recommendData = []
        purchaseData.comments = item.comments
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(item.carDetails)
        purchaseData.carImages = JSON.stringify(item.carImages)
        purchaseData.ownerId = item.ownerId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = item.uid
        purchaseData.status = 'denied'
        const result = await save(purchaseData)
        if (result.status) {
            closeModal('recommendation-modal')
            getCompanyOrders()
            setdenied(denied)
            settodayDenied(todayDenied+1)
            setactiveRecommendation(null)
            showAlert({ type: 'error', msg: 'Recommendation is denied' })
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let car = item.carDetails
            let carData = car.carData
            item?.recommendData?.forEach(e => {
                e.carImages = item.carImages
                e.carDetails = item.carDetails
                e.comments = item.comments
                e.ownerId = item.ownerId
                e.uid = typeof e.uid === 'string' ? e.uid : String(e.uid)
                if (!approved.includes(e.uid) && !denied.includes(e.uid) && !completed.includes(e.uid)) {
                    let el = {
                        carData: <div className='flex'>
                            {
                                carData.image_path?
                                <Img source='carCDN' src={carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {(car.registrationNo).toUpperCase()}<br />
                                {carData.name+' '+carData.Segment}
                            </div>
                        </div>,
                        cartitle: <div className='flex'>
                            {e.image && <Img src={JSON.parse(e.image)[0]} alt={e.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                            {
                                e.title ?
                                <span>{ e.title}{e.fuelQuantity && e.fuelType?.value && ' ('+e.fuelQuantity+' lt. '+e.fuelType.value+')'}</span>
                                : e.value
                            }
                        </div>,
                        action: <div className="action-bar">
                            <Actor type='view' onClick={() => browsePurchase(e)} />
                            {
                                ['company', 'representative'].includes(user.role) && <>
                                    <Actor type='check' onClick={() => approvePurchase(e)} />
                                    <Actor type='close' onClick={() => denyPurchase(e)} />
                                </>
                            }
                        </div>
                    }
                    data.push(el)
                }
            })
        })
        return data
    }

    const selectBranches = e => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    let {activeCars, inactiveCars} = carsData
    let adHocDataSet = []
    let i = 0;
    Object.entries(companyData.adHocData).forEach((el, v) => {
        adHocDataSet.push(
        {
            data: el[1],
            fill: true,
            borderColor:chartColor[i],
            backgroundColor: chartColor[i],
            label: el[0]
        })
        i++
    })
    let adHocData = {
        labels: weekArray,
        datasets: adHocDataSet
    }
    let carStatusData = {
        labels: weekArray,
        datasets: [
            {
                data: companyData['carStatusData']['Active'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Active'
            },
            {
                data: companyData['carStatusData']['Inactive'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Inactive'
            }
        ]
    },
    data = {
        labels: weekArray,
        datasets: [
            {
                data: companyData['data']['Services'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Services'
            },
            {
                data: companyData['data']['Products'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Products'
            }
        ]
    },
    recommendationData = {
        labels: weekArray,
        datasets: [
            {
                data: companyData['recommendationData']['Approved'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Approved'
            },
            {
                data: companyData['recommendationData']['Denied'],
                fill: true,
                backgroundColor: chartColor[4],
                label: 'Denied'
            },
            {
                data: companyData['recommendationData']['Completed'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Completed'
            }
        ]
    },
    serviceData = {
        labels: weekArray,
        datasets: [
            {
                data: companyData['serviceData']['Tyre Inflation'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Tyre Inflation'
            },
            {
                data: companyData['serviceData']['Waterless Clean'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Waterless Clean'
            },
            {
                data: companyData['serviceData']['Physical Damage Observed'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Physical Damage Observed'
            },
            {
                data: companyData['serviceData']['Physical Damage Not Observed'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Physical Damage Not Observed'
            },
            {
                data: companyData['serviceData']['Cranking Ok'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Cranking Ok'
            },
            {
                data: companyData['serviceData']['Cranking Not Ok'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Cranking Not Ok'
            },
            {
                data: companyData['serviceData']['Gold Wash'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Gold Wash'
            },
            {
                data: companyData['serviceData']['Health Checkup / Inspection'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Health Checkup / Inspection'
            },
            {
                data: companyData['serviceData']['Battery Charging'],
                fill: true,
                backgroundColor: chartColor[0],
                label: 'Battery Charging'
            },
            {
                data: companyData['serviceData']['Puncture Repair'],
                fill: true,
                backgroundColor: chartColor[1],
                label: 'Puncture Repair'
            }
        ]
    }
    let options = {
        scales: {
            yAxes: [{
                ticks: {
                beginAtZero: true
                }
            }]
        },
        responsive: true
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'product_Service'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(recommendData)
        })
        let exportedData = []
        if(companyData.serviceData && Array.isArray(companyData.serviceData)) {
            companyData.serviceData.forEach(e => {
                let ex = {
                    ownerId: e.ownerId,
                    uid: e.uid,
                    registrationNo: e.registrationNo,
                    registrationDate: e.registrationDate,
                    chassisNo: e.chassisNo,
                    engineNo: e.engineNo,
                    ownerName: e.ownerName,
                    vehicleClass: e.vehicleClass,
                    maker: e.maker,
                    fitness: e.fitness,
                    mvTax: e.mvTax,
                    insurance: e.insurance,
                    pucc: e.pucc,
                    fuelType: e.fuelType?.label ?? fuelOption[0].label,
                    emission: e.emission,
                    rcStatus: e.rcStatus,
                    financed: e.financed,
                    rcImage: e.rcImage,
                    status: e.status ?? 'active',
                    shoot360: e.shoot360 ?? false,
                    shoot360Url: e.shoot360Url,
                    modelKey: e.modelKey
                }
                exportedData.push(ex)
            })
        }
        setexportedData(exportedData)
    }, [filteredList])
    return {
        activeRecommendation,
        user,
        approvePurchase,
        denyPurchase,
        companyData,
        exportedData,
        branches,
        activeBranches,
        selectBranches,
        activeDateFilter,
        setfromFilterDate,
        fromFilterDate,
        toFilterDate,
        settoFilterDate,
        companyDashboardData,
        activeCars,
        inactiveCars,
        carStatusData,
        options,
        serviceData,
        data,
        recommendationData,
        tableContent,
        activeShownTotal,
        adHocData,
    }
}