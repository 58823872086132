import { useContext, useEffect, useState } from 'react'
import { activeDateFilter as ADF, carStatusOption, searchRegex } from '../../../../constant'
import { useService } from '../../../../hooks'
import { AppDataContext, NavigatorContext } from '../../../../context'
export const useCarList = () => {
    const {save: saveLog} = useService('Data', 'log')
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        closeModal,
        openModal,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {navigateTo} = useContext(NavigatorContext)
    const {getKeysList} = useService('Order')
    const {
        getCars,
        deleteCar,
        updateCar,
    } = useService('Car')
    const {save} = useService('Data', 'companyOrder')
    const {getMicroService} = useService('MicroService')
    const commonPageSize = 20
    const user = getLoggedInUserData()
    const [searchKey, setsearchKey] = useState('')
    const [activeItem, setactiveItem] = useState(null)
    const [preApprovedMicroServices, setpreApprovedMicroServices] = useState([])
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[0])
    const [activeBranches, setactiveBranches] = useState([])
    const [branches, setbranches] = useState([])
    const [activeCar, setactiveCar] = useState(null)
    const [activeCarStatus, setactiveCarStatus] = useState(carStatusOption[1].value)
    const [comments, setcomments] = useState('')
    const [shoot360, setshoot360] = useState(false)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [keysData, setkeysData] = useState([])
    const [status, setstatus] = useState(carStatusOption[0])
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [userData, setuserData] = useState(user)
    const [orgId, setorgId] = useState('')

    useEffect(() => {
        setTitle('My Cars List')
        let orgId = false
        if (['representative'].includes(user?.role)) {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (['branch'].includes(user?.role)) {
            orgId = user.uid
            getCarsData()
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getCarsData()
        }
        getKeys()
        if (['manager', 'company', 'branch', 'representative'].includes(user?.role)) {
            getPreApprovedMicroServices(orgId)
        }
        document.addEventListener('scroll', trackScrolling)
        setorgId(orgId)
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getKeys = async () => {
        const result = await getKeysList()
        if (result.status)
            setkeysData(result.data.reverse())
    }
    
    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getCarsData(activeBranchIds)
    }
    
    const getPreApprovedMicroServices = async v => {
        const query = {
            isAutoApproved: true
        },
        result = await getMicroService(query)
        if (result.status) {
            setpreApprovedMicroServices(result.data)
        }
    }
    
    const getBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getCarsData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        query.ownerId = {
            $in: [query.ownerId, Number(query.ownerId)]
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await getCars(query)
        if (result.status) {
            settableData(result.data)
            setfilteredList(result.data)
        }
    }
    
    const trashCar = async uid => {
        const result = await deleteCar({ uid })
        if (result.status) {
            getCarsData()
            showAlert({ type: 'success', msg: 'Car deleted successfully!' })
        } else showAlert({ type: 'error', msg: 'Unable to delete car!' })
    }
    
    const viewCar = uid => {
        navigateTo({route: '/car/view/'+uid})
    }
    
    const updateCarData = async (props = false) => {
        if (props && props.activeCar) {
            activeCar = props.activeCar
            activeCarStatus = props.activeCarStatus
            comments = 'Return'
        }
        let query = {
            uid: activeCar.uid
        }
        if (activeCar.statusComments && isValidJson(activeCar.statusComments))
            activeCar.statusComments = JSON.parse(activeCar.statusComments)
        else
            activeCar.statusComments = []

        activeCar.statusComments.push({uid: generateUID(), comments: comments.value})
        let carObject = {
            status: activeCarStatus,
            statusComments: JSON.stringify(activeCar.statusComments)
        },
        data = {
            query: JSON.stringify(query),
            carData: JSON.stringify(carObject)
        }
        const result = await updateCar(data)
        if (result.status) {
            let logData = {
                log: carObject.name+' updated by '+userData.role+' with website',
                timing: new Date().getTime(),
                type: 'info',
                userId: carObject.uid,
                uid: generateUID()
            }
            saveLog(logData)
            showAlert({ type: 'success', msg: 'Car status updated successfully!' })
            closeModal('confirm-modal')
            setactiveItem(null)
            getCarsData()
        } else showAlert({ type: 'error', msg: 'Unable to update car!' })
    }
    
    const updateCarStatus = (carObject, status) => {
        if (!['company', 'representative'].includes(user.role)) {
            if (status === 'active')
                updateCarData({ activeCar: carObject, activeCarStatus: status })
            else {
                setactiveCar(carObject)
                setactiveCarStatus(status)
                openModal('confirm-modal')
            }
        }
    }
    
    const carUpdateCancel = () => {
        setactiveCar(null)
        setactiveCarStatus('')
        closeModal('confirm-modal')
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    
    const purchaseMicroService = async (item, car) => {
        item.uid = generateUID()
        delete car.qrCode
        let purchaseData = {}
        purchaseData.recommendData = []
        purchaseData.comments = ''
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(car)
        purchaseData.carImages = JSON.stringify([])
        purchaseData.ownerId = user.role === 'company' ? user.uid : orgId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = generateUID()
        purchaseData.status = 'approved'
        const result = await save(purchaseData)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Service is booked and an order is generated for the same!' })
        }
    }
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const selectCompany = e => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    const filterItems = e => {
        let check360 = true
        if (shoot360) {
            check360 = e.shoot360 && e.shoot360Url && e.shoot360Url !== '' && e.shoot360Url !== 'false'
        } else {
            check360 = !(e.shoot360 && e.shoot360Url && e.shoot360Url !== '' && e.shoot360Url !== 'false')
        }
        return (status.value === 'all' || (status.value === 'active'
                    ?(e.status === 'active' || e.status || typeof e.status === "undefined")
                    :(e.status === false || e.status === 'inactive'))) // Temp Filter
        // check360 &&
        // (status.value === 'all' || status.value === e.status)
    }
    return {
        purchaseMicroService,
        userData,
        keysData,
        activeShownTotal,
        filterItems,
        updateCarStatus,
        trashCar,
        preApprovedMicroServices,
        user,
        activeBranches,
        selectCompany,
        branches,
        status,
        setstatus,
        setshoot360,
        shoot360,
        searchKey,
        search,
        resetSearch,
        filteredList,
        comments,
        setcomments,
        updateCarData,
        carUpdateCancel,
    }
}