import { useEditUsersCarList } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const EditUsersCarList = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const {
        trackScrolling,
        addCar,
        registrationNo,
        ownerName,
        vehicleClass,
        fuelType,
        maker,
        setshoot360,
        shoot360,
        shoot360Url,
        setshoot360Url,
        registrationDate,
        setregistrationDate,
        chassisNo,
        setchassisNo,
        engineNo,
        setengineNo,
        fitness,
        setfitness,
        mvTax,
        setmvTax,
        insurance,
        setinsurance,
        pucc,
        setpucc,
        emission,
        setemission,
        rcStatus,
        setrcStatus,
        financed,
        setfinanced,
    } = useEditUsersCarList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1>
            <BackIcon backLink='/admin/car/list' />
            Edit Car
        </h1>
        <form  className="columns" noValidate onSubmit={addCar}>
            <div className="left">
                <div className="form-group box">
                    <div>
                        <label className="col-3 control-label p0">Registration No <sup>*</sup></label>
                        <span>{registrationNo}</span>
                    </div>
                    <div>
                        <label className="col-3 control-label p0">Owner Name </label>
                        <span>{ownerName}</span>
                    </div>
                    <div>
                        <label className="col-3 control-label p0">Vehicle Class </label>
                        <span>{vehicleClass}</span>
                    </div>
                    <div>
                        <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                        <span>{ fuelType && fuelType !== "" && fuelType !== null? fuelType.label: '' }</span>
                    </div>
                    <div>
                        <label className="col-3 control-label p0">Maker / Model</label>
                        <span>{maker}</span>
                    </div>
                </div>
                <hr />
                <div className="form-group box">
                    <div className="flex-box switch-box">
                        <label className="switch">
                            <Input type="checkbox" onChange={() => setshoot360(!shoot360)} checked={shoot360?'checked':false} />
                            <span className="slider round"></span>
                        </label>
                        <span>360&deg; Shoot Completed</span>
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">360&deg; Shoot Url</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="360&deg; Shoot Url" required name="shoot360Url" value={shoot360Url || ''} onChange={e => setshoot360Url(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Registration Date</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="registration date" required name="registrationDate" value={registrationDate || ''} onChange={e => setregistrationDate(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Chassis No</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="chassis no" required name="chassisNo" value={chassisNo || ''} onChange={e => setchassisNo(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Engine No</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="engine no" required name="engineNo" value={engineNo || ''} onChange={e => setengineNo(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Fitness/REGN Upto</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="fitness/REGN upto" required name="fitness" value={fitness || ''} onChange={e => setfitness(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">MV Tax upto</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="MV Tax upto" required name="mvTax" value={mvTax || ''} onChange={e => setmvTax(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Insurance Upto</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="insurance Upto" required name="insurance" value={insurance || ''} onChange={e => setinsurance(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">PUCC Upto</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="PUCC upto" required name="pucc" value={pucc || ''} onChange={e => setpucc(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Emission norms</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="emission norms" required name="emission" value={emission || ''} onChange={e => setemission(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">RC Status</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="RC status" required name="rcStatus" value={rcStatus || ''} onChange={e => setrcStatus(e.target.value)} />
                    </div>
                </div>
                <div className="form-group box">
                    <label className="col-3 control-label p0">Financed</label>
                    <div className="col-9 controls">
                        <Input className="input" placeholder="financed" required name="financed" value={financed || ''} onChange={e => setfinanced(e.target.value)} />
                    </div>
                </div>
            </div>
            <Box title="Submit">
                <Button className="btn btn-primary btn-small addcarbtn" type="submit">Submit</Button>
            </Box>
        </form>
    </div>
}
