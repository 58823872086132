import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {CSVLink} from 'react-csv'
import { useCompanyWorkDetailDashboard } from './hooks';
import { useComponent, useElement } from '../../../hooks';

export const CompanyWorkDetailDashboard = () => {
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const exportedFileName = 'exported_daily_stock_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    const animatedComponents = makeAnimated();
    const {
        user,
        activeShownTotal,
        tableContent,
        searchPost,
        searchPostKey,
        toFilterDate,
        settoFilterDate,
        fromFilterDate,
        setfromFilterDate,
        branches,
        exportedData,
        activeBranches,
        selectCompany,
    } = useCompanyWorkDetailDashboard()
    return <>
        <div className="right-side-profile-content">
            <div className="header-title-box">
                <div className='overflow-visible'>
                    <div className='flex'>
                        <h1 className="title">
                            Daily Work Detail Report
                            <p className='company-title'>{user.name}</p>
                        </h1>
                        <span className="mlauto">
                            <CSVLink data={exportedData} filename={exportedFileName} className="btn btn-primary btn-small export-btn" target="_blank">Export</CSVLink>
                        </span>
                    </div>
                    {/* <div className="ml20 mt10 select-filter">
                        <Select
                            value={activeDateFilter}
                            defaultValue={activeDateFilter}
                            onChange={activeDateFilter => {
                                setactiveDateFilter(activeDateFilter)
                                getDashboardData(activeDateFilter, activeBranches)
                            }}
                            options={activeDateFilter}
                        />
                    </div> */}
                </div>
                {
                    // activeDateFilter?.value === 'specificDuration' &&
                    <div className="select-filter">
                        <ul className='mb20 ml-20'>
                            {
                                ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                                <li>
                                    <label>Select Branches</label>
                                    <Select
                                        isClearable
                                        closeMenuOnSelect={false}
                                        component={animatedComponents}
                                        placeholder='Select Company'
                                        defaultValue={activeBranches}
                                        onChange={selectCompany}
                                        options={branches}
                                        isMulti
                                    />
                                </li>
                                }
                            <li>
                                <label>From Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={fromFilterDate => setfromFilterDate(fromFilterDate)}
                                    className="date-picker"
                                    selected={fromFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="From Date"
                                />
                            </li>
                            <li>
                                <label>To Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={toFilterDate => settoFilterDate(toFilterDate)}
                                    className="date-picker"
                                    selected={toFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="To Date"
                                />
                            </li>
                            <li className='filter-box flex column'>
                                <label>Car Registration No.</label>
                                <Input required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={searchPost} className="form-control" />
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
        </div>
    </>
}
