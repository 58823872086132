import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddTestimonial = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'testimonial')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [content, setcontent] = useState('')
    const [uid, setuid] = useState('')
    const [mode, setmode] = useState('Add')
    const [name, setname] = useState('')
    const [image, setimage] = useState('[]')
    
    const getTestimonialData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            let testimonialData = result.data[0]
            setname(testimonialData.name)
            setmode('Edit')
            setcontent(testimonialData.content)
            setimage(testimonialData.image)
            setuid(testimonialData.uid)
        } else showAlert({ type: 'error', msg: 'No testimonial added yet!' })
    }
    
    const addTestimonial = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        testimonialObject = {
            content,
            name,
            image
        }
        if (content === '') {
            errorMsg = 'Please enter testimonial content!'
            error = true
        }
        if (name === '') {
            errorMsg = 'Please enter user name!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                testimonialObject.uid = generateUID()
                const result = await save(testimonialObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Testimonial added successfully!' })
                    navigateTo({route: '/admin/testimonial/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add testimonial!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    testimonialData: JSON.stringify(testimonialObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Testimonial updated successfully!' })
                    navigateTo({route: '/admin/testimonial/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update testimonial!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    useEffect(() => {
        setTitle('Add Testimonial', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[3] === 'edit') {
            getTestimonialData({uid: urlData[4]})
        }
    }, [])

    let title = mode + ' Testimonial'
    return {
        title,
        name,
        setname,
        content,
        setcontent,
        addTestimonial,
        image,
        setimage,
    }
}