import Select from 'react-select'
import { useAddManager } from './hooks'
import { useElement } from '../../../hooks'

export const AddManager = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const {
        addManager,
        fuelOption,
        mode,
        registrationNo,
        setregistrationNo,
        ownerName,
        setownerName,
        vehicleClass,
        setvehicleClass,
        fuelType,
        handleChange,
        maker,
        setmaker,
    } = useAddManager()
    return <>
        <div className="right-side-profile-content">
            <div className="profile-sec wrapper">
                <div className="form-editable-section">
                    <div className="form-box">
                        <form className="account-detail flex" noValidate onSubmit={addManager}>
                            <div className="col-9 carSelectDetails">
                                <h1 className="capitalize">
                                    <BackIcon backLink='/manager/list' />
                                    {mode} Manager
                                </h1>
                                <>
                                    <div className="form-group">
                                        <label className="col-3 control-label p0">Registration No <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input className="input uppercase" placeholder="registration no" required name="registrationNo" value={registrationNo || ''} onChange={e => setregistrationNo(e.target.value)} />
                                            <small className="help-text ashgray">e.g. RJ14 AB 1234 --OR-- RNX AB 1234</small>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label p0">Owner Name: </label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="ownerName name" required name="ownerName" value={ownerName || ''} onChange={e => setownerName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label p0">Vehicle Class</label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="vehicle class" disabled name="vehicleClass" value={vehicleClass || ''} onChange={e => setvehicleClass(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Select
                                                value={fuelType}
                                                onChange={handleChange}
                                                options={fuelOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label p0">Maker / Model</label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="maker / model" disabled name="maker" value={maker || ''} onChange={e => setmaker(e.target.value)} />
                                        </div>
                                    </div>
                                    {mode !== 'view' && <Button className="btn btn-primary btn-small addcarbtn" type="submit">Submit</Button>}
                                </>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
}
