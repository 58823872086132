import { useComponent, useElement } from '../../../hooks'
import { useKeyListAdmin } from './hooks'

export const KeyListAdmin = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const {
       trackScrolling,
       searchKey,
       search,
       resetSearch,
       tableContent,
       activeShownTotal,
    } = useKeyListAdmin()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">
            All Cars Keys
        </h1>
        <div className="filter-box">
            <div className="filters">
            </div>
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
    </div>
}
