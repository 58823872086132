import { useCallback, useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useFranchiseWithUs = () => {
    const {save} = useService('Data', 'franchiseRequest')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        getFranchiseRequest,
        showAlert,
    } = useService('Misc')
    const {navigateTo} = useService('Router')
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState('')
    const [message, setmessage] = useState('')
    const [pinCode, setpinCode] = useState('')
    const [city, setcity] = useState('')

    useEffect(() => {
        setTitle('Become Partner')
    }, [])

    const scrollToApplyForFranchise = useCallback(() => {
        document.getElementById('apply-for-franchise').scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    const sendFranchiseRequest = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        franchiseObject = {
            name,
            pinCode,
            email,
            mobile,
            message,
            city,
            uid: generateUID(),
            status: 'raised'
        }
        if (name === '') {
            errorMsg = 'Please enter your Full Name'
            error = true
        }
        if (email === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        }
        if (mobile === '') {
            errorMsg = 'Please enter your Mobile Number'
            error = true
        }
        if (pinCode === '') {
            errorMsg = 'Please enter your Subject'
            error = true
        }
        if (city === '') {
            errorMsg = 'Please enter your Subject'
            error = true
        }
        if (message.length < 20) {
            errorMsg = 'Please enter other details in altleast 20 letters.'
            error = true
        }
        if (!error) {
            await save(franchiseObject)
            const result = await getFranchiseRequest(franchiseObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'Your franchise request has been submitted. We\'ll connect you back in 24 hours.' })
                setname('')
                setemail('')
                setmessage('')
                setmobile('')
                setcity('')
                setpinCode('')
            } else showAlert({ type: 'error', msg: 'Unable to send franchise request.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        sendFranchiseRequest,
        name,
        setname,
        email,
        setemail,
        mobile,
        setmobile,
        city,
        setcity,
        pinCode,
        setpinCode,
        message,
        setmessage,
        scrollToApplyForFranchise,
    }
}