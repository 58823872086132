import { useContext, useEffect, useState } from "react";
import { config, activeDateFilter as ADF} from '../../../../constant'
import { useElement, useService } from "../../../../hooks";
import { AppDataContext } from "../../../../context";

export const useCompanyManagers = () => {
    const Img = useElement('Img')
    const {commonPageSize} = config
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        getBranchManagers,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
    } = useService('Misc')
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[0])
    const [activeBranches, setactiveBranches] = useState([])
    const [branches, setbranches] = useState([])
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [checkedAll, setcheckedAll] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [searchList, setsearchList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [activeFilter, setactiveFilter] = useState(null)

    useEffect(() => {
        setTitle('Company Managers')
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getCompanyBranchesList(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getCompanyBranchesList(orgId)
        } else {
            getBranchManagersData()
        }
    }, [])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getBranchManagersData(activeBranchIds)
    }
    
    const getCompanyBranchesList = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getBranchManagersData = async (branches) => {
        let query = {
            parentId: user.uid
        }
        if (branches) {
            query.parentId = {
                $in: branches
            }
        }
        const result = await getBranchManagers(query)
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                tableData.push(e)
            })
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No managers added yet!' })
            } else {
                settableData(tableData)
                setfilteredList(tableData)
            }
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(item => {
            let e = {
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                image: item.image?
                    <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>
            }
            data.push(e)
        })
        return data
    }

    const selectCompany = e => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Email',
                    key: 'email'
                },
                {
                    label: 'Mobile',
                    key: 'mobile'
                },
                {
                    label: 'Image',
                    key: 'image'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        user,
        branches,
        activeBranches,
        selectCompany,
        tableContent,
        activeShownTotal,
    }
}