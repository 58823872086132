import { base, brandInfo } from '../../constant'
import { useElement, useService } from '../../hooks'

export const DownloadTheApp = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    const {isModuleEnabled} = useService('Misc')
    return isModuleEnabled(["MobileApp"]) && <li className="appImgDiv">
        <h3>Download the App</h3>
        <ul className="flex">
            <li className="mr20"><Link href={base.iosApp} rel="noopener noreferrer" target="blank"> <Img source='internal' src={require('../../../assets/images/apple.png')} alt={'Download '+ brandInfo.name.capital+ 'App for iPhone'} /></Link></li>
            <li><Link href={base.androidApp} rel="noopener noreferrer" target="blank"><Img source='internal' src={require('../../../assets/images/android.png')} alt={'Download '+ brandInfo.name.capital+ 'App for Android'} /></Link></li>
        </ul>
    </li>
}