import Select from 'react-select'
import { useFranchiseRequest } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const FranchiseRequestAdmin = () => {
    const {
        getFormattedDate,
    } = useService('Misc')
    const Button = useElement('Button')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        tableContent,
        activeShownTotal,
        trashFranchiseRequest,
        trashCancel,
        showTrashConfirm,
        closeFranchiseRequestModal,
        name,
        email,
        mobile,
        uid,
        city,
        pinCode,
        message,
        activeItem,
        addNote,
        activeStatus,
        setstatus,
        franchiseRequestOptions,
        note,
        setnote,
    } = useFranchiseRequest()
    
    const renderNotes = franchiseRequestNotes => {
        return franchiseRequestNotes?.map(( item, index ) => {
            return <li rel="102" className="note system-note"key={index}>
                <div className="note-content">
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">{item.status}</p>
            </li>
        })
    }
    
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex mb50">Franchise Requests</h1>
        <div className="filter-box">
            <div className="filters relative">
            <label className="filter-label-add">Status: </label>
                <Select
                    value={activeFilter}
                    defaultValue={activeFilter}
                    onChange={(e, i) => {
                        setactiveFilter(e)
                        filterItems(e.value, i, tableData)
                    }}
                    options={filterTags}
                />
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashFranchiseRequest} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal franchiseRequest-modal" id="franchiseRequest-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={closeFranchiseRequestModal}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body">
                    <div className="franchiseRequest-modal-header">
                        <div className="modal-header">
                            <div className="heading">View Franchise Request</div>
                            <div className="form-group">
                                <label>Franchise Request Id</label>
                                <p>#{uid}</p>
                            </div>
                            <div className="form-group">
                                <label>Name</label>
                                <p>{name}</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <p>{email}</p>
                            </div>
                            <div className="form-group">
                                <label>Mobile Number</label>
                                <p>{mobile}</p>
                            </div>
                            <div className="form-group">
                                <label>Filing Date</label>
                                <p>{getFormattedDate(uid)}</p>
                            </div>
                            <div className="form-group">
                                <label>City</label>
                                <p>{city}</p>
                            </div>
                            <div className="form-group">
                                <label>Pin Code</label>
                                <p>{pinCode}</p>
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <p>{message}</p>
                            </div>
                            {
                                activeItem?.franchiseRequestNotes &&
                                <Box title='Notes'>
                                    <ul className="notes">
                                        { renderNotes(activeItem?.franchiseRequestNotes||[]) }
                                    </ul>
                                </Box>
                            }
                            <Box title='Add Notes'>
                                <form onSubmit={addNote}>
                                    <Select
                                        value={activeStatus}
                                        defaultValue={activeStatus}
                                        onChange={e => setstatus(e.value)}
                                        options={franchiseRequestOptions}
                                    />
                                    <textarea className="mv20" placeholder="Type the note here" required value={note} name="content" onChange={e => setnote(e.target.value)}></textarea>
                                    <Button type="submit" className="btn btn-primary">Add</Button>
                                </form>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
