import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useProjectList = () => {
    const {fetchByAttr} = useService('Data', 'project')
    const commonPageSize = 5
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const [projectList, setprojectList] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Project')
        getProjectsData()
        document.addEventListener('scroll', trackScrolling)
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getProjectsData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            let projectData = result.data.reverse()
            setprojectList(projectData)
            setfilteredList(projectData)
        }
    }
    return {
        activeShownTotal,
        projectList,
    }
}