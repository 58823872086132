import { useEffect, useState } from "react"

export const useModal = ({
    showModal,
    onCancel,
    onSuccess,
}) => {
    const [show, setshow] = useState(showModal),
    onClose = () => {},
    onOpen = () => {}

    const onCancelButtonClick = () => {
        onCancel()
        setshow(false)
    }

    const onSuccessButtonClick = () => {
        onSuccess()
        setshow(false)
    }

    useEffect(() => {
        if (show)
            onOpen()
    }, [])

    useEffect(() => {
        setshow(showModal)
    },[showModal])

    useEffect(() => {
        if (!show)
            onClose()
    },[show])
    return {
        onCancelButtonClick,
        onSuccessButtonClick,
    }
}