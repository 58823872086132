import { useBox } from "./hooks"

export const Box = ({
    children = '',
    title = '',
    required = false,
    bodyClass = '',
    boxClass = '',
    overflown = true
}) => {
    let boxBodyClass = "body "+bodyClass
    boxBodyClass += overflown?" overflown" : ''
    const { toggleClose } = useBox()

    return <div className={`box ${boxClass}`}>
        <h4 className="head" onClick={toggleClose}>{title}{required && <sup>*</sup>}</h4>
        <div className={boxBodyClass}>
            {children}
        </div>
    </div>
}
