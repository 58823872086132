import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useSideCart = () => {
    const {
        getCartData: getLocalCartData,
        removeFromCart,
    } = useService('Order')
    const {navigateTo} = useContext(NavigatorContext)
    const {toggleBodyClass} = useService('Misc')
    const [cartData, setcartData] = useState([])
    const [cartTotal, setcartTotal] = useState(0)
    useEffect(() => {
        getCartData()
    }, [])
    const getCartData = () => {
        let cartData = getLocalCartData(),
        cartTotal = 0
        cartData.forEach(e => {
            if (!e.taxAmount) {
                e.taxAmount = Number((e.price * Number(e.tax)/(100+Number(e.tax))).toFixed(2))
            }
            cartTotal += Number(e.displayPrice) * Number(e.purchaseQuantity)
        })
        localStorage.setItem('cartData', JSON.stringify(cartData))
        setcartData(cartData)
        setcartTotal(cartTotal)
    }

    const removeProduct = async item => {
        await removeFromCart(item)
        getCartData()
    }

    const navigateToCart = () => {
        navigateTo({route: '/cart'})
        toggleBodyClass('cart-open')
    }

    return {
        removeProduct,
        cartData,
        cartTotal,
        navigateToCart,
    }
}