export const carRoutes = [
    {
        "pathName": "/admin/key/list",
        "module": ["Car"],
        "component": "KeyListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/carModel/list",
        "module": ["Car"],
        "component": "CarModelList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/carModel/add",
        "module": ["Car"],
        "component": "AddCarModel",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/carModel/edit",
        "module": ["Car"],
        "component": "AddCarModel",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/carModel/manufacturer/list",
        "module": ["Car"],
        "component": "CarModelManufacturerList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car/edit",
        "module": ["Car"],
        "component": "EditUsersCarList",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/import/car",
        "module": ["Car"],
        "component": "ImportCars",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/import/pinCode",
        "module": ["PinCode"],
        "component": "ImportPinCode",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car/list",
        "module": ["Car"],
        "component": "CarListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/car/list",
        "module": ["Car"],
        "component": "CarListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/carModel/list",
        "module": ["Car"],
        "component": "CarModelList",
        "type": "admin",
        "mode": "static"
    }
]