export const productRoutes = [
    {
        "pathName": "/admin/product/list",
        "module": ["Ecommerce"],
        "component": "ProductList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/product/add",
        "module": ["Ecommerce"],
        "component": "AddProduct",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/import/product",
        "module": ["Ecommerce"],
        "component": "ImportProducts",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/product/category",
        "module": ["Ecommerce"],
        "component": "ProductCategory",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/product/brands",
        "module": ["Ecommerce"],
        "component": "ProductBrands",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/product/edit",
        "module": ["Ecommerce"],
        "component": "AddProduct",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/product/list",
        "module": ["Ecommerce"],
        "component": "ProductList",
        "type": "admin",
        "mode": "static"
    }
]