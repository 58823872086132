import { useState } from 'react'
import { Arena } from './Arena'
import { Board } from './Board'
import { PlayerHome } from './PlayerHome'
const initPlayers = require('./players.json')
export const Ludo = () => {
    const [players, setPlayers] = useState(initPlayers)
    return <div className="game-box">
       <div className='board'>
            {
                players.map(<PlayerHome />)
            }
       </div>
    </div>
}