import { useService } from '../../../hooks';
import './style.scss';
export const ErrorPage = () => {
    const {setTitle} = useService('Meta')
    setTitle('Error 404')
    return <div className="body-background">
        <div className="error-page">
            <h1>404</h1>
            <p className="oops-text">Oops!</p>
            <p>Page you're looking for is not found</p>
        </div>
    </div>
}