import { useContext, useEffect, useState } from 'react'
import { config } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext } from '../../../../context'

export const useFranchiseRequest = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchAll,
        remove,
        update,
    } = useService('Data', 'franchiseRequest')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        isEmpty,
        closeModal,
        openModal,
        getFormattedDate,
    } = useService('Misc')
    const filterTags = require('../filterTags.json')
    const franchiseRequestOptions = filterTags
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [tableData, settableData] = useState([])
    const [uid, setuid] = useState('')
    const [note, setnote] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [mobile, setmobile] = useState('')
    const [city, setcity] = useState('')
    const [pinCode, setpinCode] = useState('')
    const [status, setstatus] = useState('')
    const [name, setname] = useState('')
    const [franchiseRequestNotes, setfranchiseRequestNotes] = useState([])
    const [activeItem, setactiveItem] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Franchise Requests', 'admin')
        setstatus(franchiseRequestOptions[activeStatusIndex].value)
        getFranchiseRequestsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getFranchiseRequestsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No franchise Requests made yet!' })
            } else {
                const tableData = result.data.reverse()
                settableData(tableData)
                let activefilterValue = filterTags.find(e => Number(e.id) === activeFilterIndex+1).value ?? 'all'
                filterItems(activefilterValue, activeFilterIndex, tableData)
                setactiveStatusIndex(franchiseRequestOptions.findIndex(e => e.value === tableData.status))
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashFranchiseRequest = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getFranchiseRequestsData()
            showAlert({ type: 'success', msg: 'FranchiseRequest deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete franchiseRequest!' })
    }
    const addNote = async (e) => {
        e.preventDefault()
        const query = {uid: activeItem.uid}
        activeItem.status = status
        let franchiseRequestNotes = isEmpty(activeItem.franchiseRequestNotes) ? [] : activeItem.franchiseRequestNotes
        franchiseRequestNotes?.push({
            status,
            note,
            timing: new Date().getTime()
        })
        activeItem.franchiseRequestNotes = franchiseRequestNotes
        delete activeItem._id
        let data = {
            query: JSON.stringify(query),
            franchiseRequestData: JSON.stringify(activeItem)
        }
        const result = await update(data)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Franchise Request updated successfully!' })
            setnote('')
            setstatus('')
            getFranchiseRequestsData(query)
            closeModal("franchiseRequest-modal")
        } else showAlert({ type: 'error', msg: 'Unable to update Franchise Request!' })
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }

    const closeFranchiseRequestModal = () => {
        setname('')
        setemail('')
        setmessage('')
        setuid('')
        setmobile('')
        setcity('')
        setpinCode('')
        closeModal("franchiseRequest-modal")
    }

    const viewItem = (item) => {
        setuid(item.uid)
        setemail(item.email)
        setmessage(item.message)
        setname(item.name)
        setcity(item.city)
        setmobile(item.mobile)
        setpinCode(item.pinCode)
        setactiveItem(item)
        openModal("franchiseRequest-modal")
    }

    const trashItem = item => {
        setactiveItem(item)
        trashFranchiseRequest()
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                uid: '#'+item.uid,
                name: item.name,
                mobile: item.mobile,
                date: getFormattedDate(item.uid),
                city: item.city,
                badge: !isEmpty(item.status) && <Badge item={item.status} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor onClick={() => viewItem(item)} type='view' />
                    <Actor onClick={() => trashItem(item)} type='trash' />
                </div>
            }
            data.push(e)
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Id',
                    key: 'id'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Mobile Number',
                    key: 'mobile'
                },
                {
                    label: 'Filing Date',
                    key: 'uid'
                },
                {
                    label: 'City',
                    key: 'city'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList])

    let activeStatus = franchiseRequestOptions[activeStatusIndex]
    franchiseRequestOptions.forEach(e => {
        if (e.value === status)
            activeStatus = e
    })
    return {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        tableContent,
        activeShownTotal,
        trashFranchiseRequest,
        trashCancel,
        showTrashConfirm,
        closeFranchiseRequestModal,
        name,
        email,
        mobile,
        uid,
        city,
        pinCode,
        message,
        activeItem,
        addNote,
        activeStatus,
        setstatus,
        franchiseRequestOptions,
        note,
        setnote,
    }
}