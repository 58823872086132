import Select from 'react-select'
import { useMicroServiceCategory } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const MicroServiceCategory = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashCategory,
        categoryCancel,
        showTrashConfirm,
        setcatName,
        setuid,
        addServiceCategory,
        catName,
        addCategory,
        catSlug,
        setcatSlug,
        parentCat,
        setparentCat,
        categoryOptions,
    } = useMicroServiceCategory()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Category <Button onClick={() => {
            setmode('Add')
            openModal("category-modal")
        }} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashCategory} trashCancelAction={categoryCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal category-modal" id="category-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                setcatName('')
                setcatSlug('')
                setuid('')
                closeModal("category-modal")
            }}>
            <i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="category-modal-header">
                        <div className="modal-header">
                                <div className="heading">Add</div>
                                <form noValidate onSubmit={addServiceCategory}> 
                                    <div className="form-group mb40">
                                        <Input className="input" placeholder="Please enter a Category Name" required name="catName" value={catName}
                                        onChange={addCategory} />
                                        <label>Category</label>
                                        <small className="msg text-danger">Please enter a Category</small>
                                    </div>
                                    <div className="form-group mb40">
                                            <Input className="input" placeholder="Please enter slug" required name="catSlug" value={catSlug} onChange={e => setcatSlug(e.target.value)} />
                                            <label>Slug</label>
                                            <small className="msg text-danger">Please enter slug</small>
                                    </div>
                                    <div className="form-group mb40">
                                            <Select
                                                value={parentCat}
                                                onChange={e => setparentCat(e)}
                                                options={categoryOptions}
                                            />
                                            <label>Parent Category</label>
                                            <small className="msg text-danger">Please select Parent Category</small>
                                    </div>
                                    <div className="buttons">
                                            <Button type="submit" title="Click here to Add Category" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
