import {brandInfo, mainUrl} from '../../../constant'
import { useBlog } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'
import { NavigatorContext } from '../../../context'
import { useContext } from 'react'

export const Blog = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {getMonthName} = useService('Misc')
    const Banner = useWidget('Banner')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Share = useComponent('Share')
    const BlogSideBar = useComponent('BlogSideBar')
    const {
        activeShownTotal,
        postList,
    } = useBlog()
    
    const renderBlogList = blogFiles => {
        return blogFiles.slice(0, activeShownTotal).map(( item, index ) => {
            return item.status === 'publish' && <div className="blog-single-item" key={index}>
                <div>
                    <div>
                        <div className="thumb-area-wrap">
                            <div className="thumb-area">
                                <div className="thumb">
                                    {
                                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                        <Img src={'product-placeholder.png'} alt={item.title} />:
                                        <Img className="pointer" onClick={() => navigateTo({route: '/post/'+item.slug})} src={JSON.parse(item.image)[0]} alt={item.title} />
                                    }
                                </div>
                                <div className="date">
                                    <span className="day">{new Date(item.publishDate).getDate()}</span>
                                    <span className="month">{getMonthName(new Date(item.publishDate).getMonth())}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="content-box" className="content">
                            <h4 className="title"><Link href={"/post/"+item.slug}>{item.title}</Link></h4>
                            <div className="post-meta">
                                <ul className="list-wrap">
                                    <li>
                                        <p className="author">{brandInfo.name.capital}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="pv30">
                                <Html content={item.excerpt} />
                            </div>
                            <div className="read-more-area">
                                <div className="read-more">
                                    <Link href={"/post/"+item.slug}>Read More</Link>
                                </div>
                                <Share shareUrl={mainUrl+"/post/"+item.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    return <>
        <Banner title='Blog' image="accessories-8.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt40 pb40">
                <div className="wrapper">
                    <div className="col-8" id="list-wrapper">
                        { renderBlogList(postList || []) }
                    </div>
                    <div className="col-4">
                        <BlogSideBar />
                    </div>
                </div>
            </div>
        </div>
    </>
}
