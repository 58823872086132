import { base } from '../../../constant'
import { useElement, useService, useWidget } from '../../../hooks'
import { useOurEvents } from './hooks'
import './style.scss'

export const OurEvents = () => {
    const {closeModal} = useService('Misc')
    const Banner = useWidget('Banner')
    const Link = useElement('Link')
    const Slider = useElement('Slider')
    const Button = useElement('Button')
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }
    const {
        activeMedia,
        eventList,
        viewEventVideo,
        activeEvent,
        setactiveEvent,
    } = useOurEvents()

    const renderEventList = () => {
        return eventList.map((e, i) => {
            return <div className='event-box' key={i}>
                <div className='event-service' style={{backgroundImage: `url('${base.imageBaseUrl}${JSON.parse(e.image)[0]}')`}}>
                    <Button className="btn btn-primary" onClick={() => viewEventVideo(e)}>View Full Video</Button>
                </div>
                <h3 className='tac'>{e.title}</h3>
                <p className='tac'>
                    <Link href={'/event/'+e.slug}>View Event</Link>
                </p>
            </div>
        })
    }

    return <>
        <Banner align="right" title="A SNEAK AT OUR WORK" image="event-banner.jpg" />
        <div className='jumbo-container mt60 mb40'>
            <h2 className="heading-02">Recent Events</h2>
            <Slider {...settings}>
                { renderEventList() }
            </Slider>
            <h2 className="heading-02">Image Gallery</h2>
            <h2 className="heading-02">Video Gallery</h2>
            <div className="modal media-modal" id="media-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("media-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        activeMedia && activeMedia && <iframe className="preview-video" src={'https://www.youtube-nocookie.com/embed/'+activeMedia} title={activeMedia.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    }
                </div>
            </div>
        </div>
        </div>
    </>
}
