import { brandInfo } from '../../constant'
import './style.scss';
import { useChatBot } from './hooks';
import { useElement } from '../../hooks';

export const ChatBot = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Link = useElement('Link')
    const {
        inputQuery,
        chatbotToggle,
        chatData,
        askQuery,
        activeQuery,
    } = useChatBot()
    
    const renderChat = () => {
        return chatData.map(( item, index ) => {
            return <div className="chat-container" key={index}>
                <div className={"msg "+item.msgType}>
                    <span>{item.msg}</span>
                </div>
            </div>
        })
    }

    return <>
        <div className="chatboy">
            <Link target="blank" className="whats-app" href={`https://api.whatsapp.com/send?phone=91${brandInfo.phone}&amp;text=Hello%20${brandInfo.name.capital}!`} rel="noreferrer">
                <i className="hi-whatsapp"></i>
            </Link>
        </div>
        <div className="chatbot" id="chatbot">
            <div className="chat-header" onClick={chatbotToggle}>
                <i className="hi-chat msg-icon"></i>
                <div className="title">{brandInfo.name.capital} Support</div>
                <div className="close"><i className="hi-close"></i></div>
            </div>
            <div className="chatbot-wrapper">
                { renderChat() }
            </div>
            <form className="msg-input" onSubmit={askQuery}>
                <Input placeholder="Type a message here" required name="activeQuery" value={activeQuery} onChange={inputQuery} />
                <Button className="send-msg" type="submit">
                    <i className="hi-send"></i>
                </Button>
            </form>
        </div>
    </>
}
