import './style.scss';
import { useTestimonialWeb } from './hooks';
import { useElement } from '../../hooks';
import { UserImage } from '../UserImage';
export const TestimonialWeb = () => {
    const Slider = useElement('Slider')
    const {
        testimonialList,
    } = useTestimonialWeb()
    const renderTestimonialList = testimonialFiles => {
        return testimonialFiles.map(( item, index ) => {
            return <div className="testimonial" key={index}>
                <UserImage user={{image: item.image, name: item.title}} />
                <div className="items">
                    <h3>Happy Client Feedback</h3>
                    <p>{item.content}</p>
                    <h5 className="honor-name">{item.name}</h5>
                </div>
            </div>
        })
    }
    
    let setting = {
        rows: 1,
        swipeToSlide: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoPlaySpeed: 3000,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return <>
        {
            testimonialList.length !== 0 &&
            <div className='pb50 mb60 testimonial-slider'>
                <div className="jumbo-container">
                    <section className="testimonial">
                        <div className="jumbo-container">
                            <Slider {...setting} >
                                { renderTestimonialList(testimonialList || []) }
                            </Slider>
                        </div>
                    </section>
                </div>
            </div>
        }
    </>
}
