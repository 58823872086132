import { useContext, useEffect, useState } from "react"
import { emailOption, garageoption, robotsOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddGarage = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'garage')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        isEmpty,
        generateUID,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const keywordOptions =  [],
    categoryOptions = [],
    tagOptions = []
    const [activeRobotsIndex, setactiveRobotsIndex] = useState(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [activeEmailIndex, setactiveEmailIndex] = useState(0)
    const [image, setimage] = useState('[]')
    const [uid, setuid] = useState('')
    const [renderSlug, setrenderSlug] = useState(true)
    const [mode, setmode] = useState('Add')
    const [title, settitle] = useState('')
    const [slug, setslug] = useState('')
    const [address, setaddress] = useState('')
    const [businessinformation, setbusinessinformation] = useState("")
    const [css, setcss] = useState('')
    const [js, setjs] = useState('')
    const [canonical, setcanonical] = useState('')
    const [robots, setrobots] = useState('')
    const [metaDescription, setmetaDescription] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [email, setemail] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const [yearEstablished, setyearEstablished] = useState(new Date())
    const [assignWorkers, setassignWorkers] = useState([])
    const [status, setstatus] = useState('')
    const [category, setcategory] = useState('')
    const [tag, settag] = useState('')
    const urlData = urlToSplitData(getLocationData().pathname)

    useEffect(() => {
        setTitle('Add Garage', 'admin')
        setstatus(garageoption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getGarageData({uid: urlData[4]})
        }
        getUsersData()
    }, [])

    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                setassignWorkers(tableData)
            }
        }
    }
    
    const getGarageData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const garageData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === garageData.robots))
            setactiveEmailIndex(emailOption.findIndex(e => e.value === garageData.email))
            setactiveStatusIndex(garageoption.findIndex(e => e.value === garageData.status))
            setmetaKeyword(isEmpty(garageData.metaKeyword) ? [] : garageData.metaKeyword)
            settag(isEmpty(garageData.tag) ? [] : garageData.tag)
            setcategory(isEmpty(garageData.category) ? [] : garageData.category)
            settitle(garageData.title)
            setmode('Edit')
            setslug(garageData.slug)
            setaddress(garageData.address)
            setbusinessinformation(garageData.businessinformation)
            setcss(garageData.css)
            setjs(garageData.js)
            setcanonical(garageData.canonical)
            setrobots(garageData.robots)
            setmetaDescription(garageData.metaDescription)
            setemail(garageData.email)
            setphoneNumber(garageData.phoneNumber)
            setyearEstablished(new Date(garageData.yearEstablished))
            setstatus(garageData.status)
            setuid(garageData.uid)
            setimage(garageData.image)
        } else showAlert({ type: 'error', msg: 'No garage added yet!' })
    }
    
    const addGarage = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        garageObject = {
            title,
            slug,
            address,
            businessinformation,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            email,
            phoneNumber,
            yearEstablished,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (businessinformation === '') {
            errorMsg = 'Please enter the business information'
            error = true
        } else if (address === '') {
            errorMsg = 'Please enter the garage address'
            error = true
        } else if (email === '') {
            errorMsg = 'Please select the email address'
            error = true
        } else if (phoneNumber === '') {
            errorMsg = 'Please enter your phone number'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                garageObject.uid = generateUID()
                const result = await save(garageObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Garage added successfully!' })
                    navigateTo({route: '/admin/garage/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to add garage!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    garageData: JSON.stringify(garageObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Garage updated successfully!' })
                    navigateTo({route: '/admin/garage/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to update garage!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const pageTitle = mode + ' Garage'
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let activeRobots = robotsOption[activeRobotsIndex],
    activeEmail = emailOption[activeEmailIndex],
    activeStatus = garageoption[activeStatusIndex]
    if (mode === 'Edit') {
        garageoption.forEach(e => {
            if (e.value === status) {
                activeStatus = e
            }
        })
        robotsOption.forEach(e => {
            if (e.value === robots) {
                activeRobots = e
            }
        })
        assignWorkers.forEach(e => {
            if (e.value === email) {
                activeEmail = e
            }
        })
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    const addTitle = e => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }
    return {
        pageTitle,
        addGarage,
        title,
        addTitle,
        slug,
        setslug,
        address,
        setaddress,
        businessinformation,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        image,
        setimage,
        tagOptions,
        settag,
        tag,
        categoryOptions,
        setcategory,
        category,
        setstatus,
        activeStatus,
        status,
        yearEstablished,
        setyearEstablished,
        setphoneNumber,
        phoneNumber,
        setemail,
        email,
        setbusinessinformation,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
    }
}