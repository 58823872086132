import { useComponent, useElement, useService } from '../../../hooks'
import { useMySubscription } from './hooks'

export const MySubscription = () => {
    const {closeModal} = useService('Misc')
    const Button = useElement('Button')
    const SubscriptionData = useComponent('SubscriptionData')
    const Table = useComponent('Table')
    const {
        tableContent,
        activeShownTotal,
    } = useMySubscription()
    return <>
        <div id="content-box" className="content">
            <h1>My Subscription</h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
        </div>
        <div className="modal" id="get-subscription-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <SubscriptionData /> 
                </div>
            </div>
        </div>
    </>
}
