import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext, NavigatorContext } from "../../../../context"

export const useTestimonialList = () => {
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const {
        save,
        fetchAll,
        remove,
    } = useService('Data', 'testimonial')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    const [checkedAll, setcheckedAll] = useState(false)

    useEffect(() => {
        setTitle('Testimonials List', 'admin')
        getTestimonialsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const cloneTestimonial = async item => {
        let testimonialObject = {
            ...item,
            name: item.name+'-copy',
            uid: generateUID()
        }
        const result = await save(testimonialObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Testimonial clone successfully!' })
            getTestimonialsData()
        } else showAlert({ type: 'error', msg: 'Unable to clone testimonial!' })
    }
    
    const deleteSelectedTestimonial = () => {
        deleteTestimonial(activeItem.uid)
    }
    
    const deleteTestimonial = async (uid) => {
        const result = await remove({ uid })
        if (result.status) {
            deleteSelectedTestimonial()
            showAlert({ type: 'success', msg: 'Testimonial deleted successfully!' })
            setactiveItem(null)
            getTestimonialsData()
        } else showAlert({ type: 'error', msg: 'Unable to delete app!' })
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            deleteTestimonial(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const getTestimonialsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse();
            settableData(tableData)
            setfilteredList(tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No testimonial added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No testimonial added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashTestimonial = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getTestimonialsData()
            showAlert({ type: 'success', msg: 'Testimonial deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete testimonial!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(item => {
            let e = {
                checkAction: <span className="check-all">
                    <span onClick={() => setcheckedAll(!checkedAll)}>
                        {
                        checkedAll ?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>,
                </span>,
                image: item.image?
                    <Img alt={item.content} src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                content: item.content,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/testimonial/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => cloneTestimonial(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label:'Image',
                    key: 'image'
                },
                {
                    label:'Content',
                    key: 'content'
                },
                {
                    label:'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(tableData)
        })
    }, [tableData])
    return {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        tableContent,
        activeShownTotal,
        deleteChecked,
        trashTestimonial,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}