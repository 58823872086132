export const SettingModel = [
    {
        key: 'key',
        dataType: String
    },
    {
        key: 'value',
        dataType: String
    },
    {
        key: 'userId',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]