import {
    AddApp,
    AddBadge,
    AddCampaign,
    AddCarModel,
    AddEvent,
    AddFranchise,
    AddGarage,
    AddJob,
    AddMicroService,
    AddNotification,
    AddOffer,
    AddPage,
    AddPost,
    AddProduct,
    AddProject,
    AddService,
    AddSociety,
    AddTaxClass,
    AddTestimonial,
    AddUser,
    AdminDashboard,
    AdminPinCode,
    AdminProjectList,
    AppBanners,
    AppList,
    BadgeList,
    CampaignList,
    CarListAdmin,
    CarModelList,
    CarModelManufacturerList,
    AdminCity,
    CompanyWorkListAdmin,
    ComplaintAdmin,
    EditUsersCarList,
    EventsList,
    AdminFAQ,
    FeedbackOrders,
    FranchiseList,
    FranchiseRequestAdmin,
    GarageList,
    GenericChatBot,
    ImportB2BStockData,
    ImportB2BWorkData,
    ImportCars,
    ImportPinCode,
    ImportProducts,
    InvoiceListAdmin,
    JobsList,
    KeyListAdmin,
    LogList,
    Media,
    MicroServiceCategory,
    MicroServiceList,
    ModuleList,
    AdminNotifications,
    OfferList,
    OrderDetail,
    Orders,
    PackageDetail,
    PackageWorkListAdmin,
    PageList,
    PostList,
    ProductBrands,
    ProductCategory,
    ProductList,
    PackageOrders,
    ProductOrders,
    QueriesChatBot,
    Report,
    Robots,
    SendMail,
    ServiceCategory,
    ServiceList,
    ServiceOrders,
    ServiceWorkListAdmin,
    Settings,
    Societies,
    AdminState,
    TaxClassList,
    TestimonialList,
    Users,
    WebBanners,
    WorkListAdmin
} from '../page/admin'

import {
    OurEvents,
    About,
    AddAddress,
    AddCar,
    AddManager,
    Blog,
    CarList,
    Cart,
    Chat,
    Checkout,
    CompanyDashboard,
    CompanyManagers,
    CompanyProfile,
    CompanyStockDetailDashboard,
    CompanyWorkDashboard,
    CompanyWorkDetailDashboard,
    Complaint,
    Contact,
    DashBoard,
    ErrorPage,
    Faq,
    Feedback,
    FranchiseWithUs,
    Home,
    Login,
    Ludo,
    ManageAddress,
    MicroServices,
    MyOrderDetail,
    MyOrders,
    MyPackageDetail,
    MyPackages,
    MySubscription,
    Notifications,
    Offers,
    OrderTracking,
    Otp,
    Packages,
    Payment,
    Profile,
    ProjectList,
    Register,
    Run,
    Services,
    Shop,
    SnakeNLadder,
    StoreLocator,
    Subscription,
    Thankyou,
    UnderDevelopment,
    ViewCar,
} from '../page/front'

const pages = {
    admin: {
        AddApp,
        AddBadge,
        AddCampaign,
        AddCarModel,
        AddEvent,
        AddFranchise,
        AddGarage,
        AddJob,
        AddMicroService,
        AddNotification,
        AddOffer,
        AddPage,
        AddPost,
        AddProduct,
        AddProject,
        AddService,
        AddSociety,
        AddTaxClass,
        AddTestimonial,
        AddUser,
        AdminDashboard,
        AdminPinCode,
        AdminProjectList,
        AppBanners,
        AppList,
        BadgeList,
        CampaignList,
        CarListAdmin,
        CarModelList,
        CarModelManufacturerList,
        AdminCity,
        CompanyWorkListAdmin,
        ComplaintAdmin,
        EditUsersCarList,
        EventsList,
        AdminFAQ,
        FeedbackOrders,
        FranchiseList,
        FranchiseRequestAdmin,
        GarageList,
        GenericChatBot,
        ImportB2BStockData,
        ImportB2BWorkData,
        ImportCars,
        ImportPinCode,
        ImportProducts,
        InvoiceListAdmin,
        JobsList,
        KeyListAdmin,
        LogList,
        Media,
        MicroServiceCategory,
        MicroServiceList,
        ModuleList,
        AdminNotifications,
        OfferList,
        OrderDetail,
        Orders,
        PackageDetail,
        PackageWorkListAdmin,
        PageList,
        PostList,
        ProductBrands,
        ProductCategory,
        ProductList,
        PackageOrders,
        ProductOrders,
        QueriesChatBot,
        Report,
        Robots,
        SendMail,
        ServiceCategory,
        ServiceList,
        ServiceOrders,
        ServiceWorkListAdmin,
        Settings,
        Societies,
        AdminState,
        TaxClassList,
        TestimonialList,
        Users,
        WebBanners,
        WorkListAdmin,
    },
    front: {
        OurEvents,
        About,
        AddAddress,
        AddCar,
        AddManager,
        Blog,
        CarList,
        Cart,
        Chat,
        Checkout,
        CompanyDashboard,
        CompanyManagers,
        CompanyProfile,
        CompanyStockDetailDashboard,
        CompanyWorkDashboard,
        CompanyWorkDetailDashboard,
        Complaint,
        Contact,
        DashBoard,
        ErrorPage,
        Faq,
        Feedback,
        FranchiseWithUs,
        Home,
        Login,
        Ludo,
        ManageAddress,
        MicroServices,
        MyOrderDetail,
        MyOrders,
        MyPackageDetail,
        MyPackages,
        MySubscription,
        Notifications,
        Offers,
        OrderTracking,
        Otp,
        Packages,
        Payment,
        Profile,
        ProjectList,
        Register,
        Run,
        Services,
        Shop,
        SnakeNLadder,
        StoreLocator,
        Subscription,
        Thankyou,
        UnderDevelopment,
        ViewCar,
    }
}

export const usePage = (page: string, type: string = 'front') => {
    return pages[type][page]
}