import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useSubFooter = () => {
    const {getService} = useService('Service')
    const [service, setservice] = useState([])
    const getServiceData = async () => {
        const result = await getService({isFeatured: 'true'})
        if(result.status) {
            let serviceData = []
            result.data?.forEach(e => {
                serviceData.push({
                    url: '/service/'+e.slug,
                    label: e.title
                })
            })
            setservice(serviceData)
        }
    }
    useEffect(() => {
        getServiceData()
    }, [])
    return {
        service
    }
}