import {brandInfo} from '../../constant'
import './style.scss';
import { useRecentPosts } from './hooks';
import { useElement, useService } from '../../hooks';
export const RecentPosts = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Slider = useElement('Slider')
    const {getFormattedDate} = useService('Misc')
    const {
        postList,
        newLength,
    } = useRecentPosts()
    const renderPostList = postFiles => {
        return postFiles.map(( item, index ) => {
            return !item.trash && <li className={index % 2 === 1? "box-banner-design" : "box-banner-design whitebox"} key={index}>
                <div className="tab-link-content mt-4">
                    <div className="inner-content">
                    <Link href={"/post/" + item.slug}><Img src={JSON.parse(item.image)[0]} alt={item.title} /></Link>
                        <div className="tab-title-content fl-font-style-semi-bolt"><Link href={"/post/" + item.slug}>{item.title}</Link>
                            <div className="name-date">
                                <p className="author-name"><i className="icon flaticon-avatar"></i>{brandInfo.name.capital}</p>
                                <p className="publish-date"><i className="icon flaticon-small-calendar"></i>{getFormattedDate(new Date(item.publishDate))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        })
    }
    
    const newsSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: newLength === 2 ? 2: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                setting: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 991,
                setting: {
                    arrows: false,
                    slidesToShow: 2
                }
            }
        ]
    }

    return <section className="latest-news style-02 mb60-mbs40">
        <div className="jumbo-container">
            <div className="section-title">
                <h2 className="heading-02">Recent Posts</h2>
            </div>
            <div className="col-12">
                <div className="latest-news-slider-wrap">
                    <div className="latest-news-slider-active row">
                        <div className="header-item blog-item">
                            <ul>
                                <Slider {...newsSettings}>
                                    {renderPostList(postList || [] )}
                                </Slider>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
