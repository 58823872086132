import { brandInfo } from '../../../constant'
import { useComponent, useService, useWidget } from '../../../hooks'

export const Subscription = () => {
    const Banner = useWidget('Banner')
    const SubscriptionData = useComponent('SubscriptionData')
    const {getActiveSubscription} = useService('User')
    const {setTitle} = useService('Meta')
    setTitle('User Subscriptions')

    return <>
        <Banner title="Subscriptions" image="header-car-back.jpg" />
        <div className="pricing-page subscription">
            <div className="jumbo-container">
                <div className="heading-section-pricing">
                    <h1 className="heading-table-price">{brandInfo.name.uppercase} CLUB</h1>
                    <p>Only applicable on adHoc service</p>
                    <SubscriptionData activeSubscription={getActiveSubscription()} />
                </div>
            </div>
        </div>
    </>
}
