import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import 'chart.js/auto';
import {CSVLink} from 'react-csv'
import { Bar, Line } from 'react-chartjs-2'
import { useCompanyDashboard } from './hooks';
import { useComponent, useElement, useLocale, useService } from '../../../hooks';
export const CompanyDashboard = () => {
    const {t} = useLocale()
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const exportedFileName = 'exported_weekly_order_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    const {
        activeRecommendation,
        user,
        approvePurchase,
        denyPurchase,
        companyData,
        exportedData,
        branches,
        activeBranches,
        selectBranches,
        activeDateFilter,
        setfromFilterDate,
        fromFilterDate,
        toFilterDate,
        settoFilterDate,
        companyDashboardData,
        activeCars,
        inactiveCars,
        carStatusData,
        options,
        serviceData,
        data,
        recommendationData,
        tableContent,
        activeShownTotal,
        adHocData,
    } = useCompanyDashboard()
    const animatedComponents = makeAnimated();

    return <>
        <div className="right-side-profile-content">
            <div className="header-title-box">
                <div className='flex overflow-visible'>
                    <h1 className="title">
                        {t('dashboard')}
                        <p className='company-title'>{user.name}</p>
                    </h1>
                    {
                        companyData.serviceData.length !== 0 &&
                        <span className="btn btn-primary btn-small ml20 export-btn">
                            <CSVLink data={exportedData} filename={exportedFileName} className="export-btn" target="_blank">Export</CSVLink>
                        </span>
                    }
                    {
                        ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                        <div className='mt20'>
                            <Select
                                isClearable
                                closeMenuOnSelect={false}
                                component={animatedComponents}
                                placeholder='Select Branch(s)'
                                defaultValue={activeBranches}
                                onChange={selectBranches}
                                options={branches}
                                isMulti
                            />
                        </div>
                    }
                    {/* <div className="ml20 mt10 select-filter">
                        <Select
                            value={activeDateFilter}
                            defaultValue={activeDateFilter}
                            onChange={activeDateFilter => {
                                setactiveDateFilter(activeDateFilter)
                                getDashboardData(activeDateFilter, activeBranches)
                            }}
                            options={activeDateFilter}
                        />
                    </div> */}
                </div>
                {
                    activeDateFilter.value === 'specificDuration' &&
                    <div className="select-filter">
                        <ul>
                            <li>
                                <label>From Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={fromFilterDate => setfromFilterDate(fromFilterDate)}
                                    className="date-picker"
                                    selected={fromFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="From Date"
                                />
                            </li>
                            <li>
                                <label>To Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={toFilterDate => settoFilterDate(toFilterDate)}
                                    className="date-picker"
                                    selected={toFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="To Date"
                                />
                            </li>
                        </ul>
                    </div>
                }
            </div>
            {companyDashboardData && <div>
                <div className='grid-area-section col-1-2'>
                    <div className="box box-1 blank">
                        <h4 className="head flex">
                            <span className='mrauto gray'>No. of Active cars</span>
                            <span className='mlauto bold success'>{activeCars}</span>
                        </h4>
                        <h4 className="head flex">
                            <span className='mrauto gray'>No. of Inactive cars</span>
                            <span className='mlauto bold warning'>{inactiveCars}</span>
                        </h4>
                    </div>
                    <Box title='No. of Cars' boxClass='box-2'>
                        <Bar data={carStatusData} options={options} />
                    </Box>
                </div>
                <div className="grid-area-section">
                    <Box title='Daily Services' boxClass='box-2'>
                        <Bar data={serviceData} options={options} />
                    </Box>
                </div>
                <div className="grid-area-section">
                    <Box title='Ad-hoc Services / Products'>
                        <div onClick={() => openModal('ad-hoc-graph-modal')}>
                            <Bar data={data} options={options} />
                        </div>
                    </Box>
                </div>
                <div className="grid-area-section">
                    <Box title='Recommendations Status'>
                        <Bar data={recommendationData} options={options} />
                    </Box>
                </div>
                <div className="grid-area-section">
                    <Box title='Recommendations'>
                        <Table
                            data={tableContent}
                            pageSize={activeShownTotal}
                        />
                    </Box>
                </div>
            </div>}
        </div>
        <div className="modal" id="ad-hoc-graph-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal('ad-hoc-graph-modal')}><i className="hi-close"></i></Button>
                <Line data={adHocData} options={options} />
            </div>
        </div>
        <div className="modal" id="recommendation-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal('recommendation-modal')}><i className="hi-close"></i></Button>
                {activeRecommendation && <div className="modal-body p20">
                    <h3>{['service', 'microService'].includes(activeRecommendation.type)?'Service':'Product'} Details</h3>
                    <div className='product-box'>
                        {activeRecommendation.image && <Img className={"mbauto"} src={JSON.parse(activeRecommendation.image)[0]} alt={activeRecommendation.title} style={{ width:'100%', borderRadius: 5, marginRight: 5, maxHeight: 240 }} />}
                        <div className='description pl20'>
                            <h4>{
                                activeRecommendation.title ?
                                <>{
                                    activeRecommendation.title ?
                                    <span>{ activeRecommendation.title}{activeRecommendation.fuelQuantity && activeRecommendation.fuelType?.value && ' ('+activeRecommendation.fuelQuantity+' lt. '+activeRecommendation.fuelType?.value+') '}</span>
                                    : activeRecommendation.value
                                }</>
                                : activeRecommendation.value
                            }</h4>
                            {activeRecommendation.title && <div className="content-detail pb40" dangerouslySetInnerHTML={{_html: activeRecommendation.excerpt}}></div>}
                        </div>
                    </div>
                    <div className='clearfix'></div>
                    <hr />
                    <h3>Car Details</h3>
                    <div className='flex'>
                        {
                            activeRecommendation.carDetails.carData.image_path?
                            <Img source='carCDN' src={activeRecommendation.carDetails.carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                            <p>No Image</p>
                        }
                        <div className='fs12'>
                            {activeRecommendation.carDetails.registrationNo.toUpperCase()}<br />
                            {activeRecommendation.carDetails.carData.name+' '+activeRecommendation.carDetails.carData.Segment}
                        </div>
                    </div>
                    <hr />
                    <h3>Recent Car Images</h3>
                    <ul className='car-images'>
                        {
                            activeRecommendation.carImages.map((e, i) => <li key={i}>
                                <Img hasZoom src={e} alt={activeRecommendation.title} style={{ maxWidth: 120, borderRadius: 5, }} />
                            </li>)
                        }
                    </ul>
                    <hr />
                    <h3>Service Guy Comments</h3>
                    <p>{activeRecommendation.comments}</p>
                    <hr />
                    { !['company', 'representative'].includes(user.role) && <div className="flex btn-box-order">
                        <Button className="mb0 btn btn-success btn-box" onClick={() => approvePurchase(activeRecommendation)}>Approve</Button>
                        <Button className="mb0 btn btn-box btn-error mlauto" onClick={() => denyPurchase(activeRecommendation)}>Deny</Button>
                    </div>}
                </div>}
            </div>
        </div>
    </>
}
