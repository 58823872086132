import { useEffect, useState } from "react"
import { base } from "../../../constant"

export const useImg = ({
    src,
    source,
}) => {
    const imageBase = !source || source === 'CDN' ? base.imageBaseUrl : source === 'carCDN'? base.carImageBase: ''
    const [zoom, setzoom] = useState(false)
    const [imageSrc, setimageSrc] = useState(imageBase+'/blank.png')

    useEffect(() => {
        setimageSrc(imageBase+src)
    }, [src])

    return {
        zoom,
        setzoom,
        imageSrc,
    }
}