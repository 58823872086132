import { useComponent } from '../../../hooks'
import { useFeedbackOrders } from './hooks'

export const FeedbackOrders = () => {
    const Table = useComponent('Table')
    const {
        trackScrolling,
        tableContent,
        activeShownTotal,
    } = useFeedbackOrders()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Feedbacks</h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
    </div>
}
