export const ProjectModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'slug',
        dataType: String
    },
    {
        key: 'description',
        dataType: String
    },
    {
        key: 'url',
        dataType: String
    },
    {
        key: 'canonical',
        dataType: String
    },
    {
        key: 'robots',
        dataType: String
    },
    {
        key: 'metaDescription',
        dataType: String
    },
    {
        key: 'metaKeyword',
        dataType: String
    },
    {
        key: 'releaseDate',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'tag',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    }
]