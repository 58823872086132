import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { useMyOrders } from './hooks';
import { useComponent, useElement, useService } from '../../../hooks';

export const MyOrders = () => {
    const {closeModal} = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Html = useElement('Html')
    const Table = useComponent('Table')
    const {
        user,
        branches,
        activeBranches,
        selectActiveBranches,
        orderData,
        tableRecommendationContent,
        tableOrderContent,
        activeShownTotal,
        activeRecommendation,
    } = useMyOrders()
    const animatedComponents = makeAnimated();
    return <>
        <div id="content-box" className="content">
            <div className='flex overflow-visible'>
                <h1 className="nowrap mr20">My Orders</h1>
                {
                    ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                    <Select
                        isClearable
                        closeMenuOnSelect={false}
                        component={animatedComponents}
                        placeholder='Select Company'
                        defaultValue={activeBranches}
                        onChange={selectActiveBranches}
                        options={branches}
                        isMulti
                    />
                }
            </div>
            <div className="order-table">
                <Table
                    data={orderData ? tableRecommendationContent : tableOrderContent}
                    pageSize={activeShownTotal}
                />
            </div>
        </div>
        <div className="modal" id="recommendation-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content content medium">
                <Button className="close" onClick={() => closeModal('recommendation-modal')}><i className="hi-close"></i></Button>
                {activeRecommendation && <div className="modal-body p20">
                    <h3>{['service', 'microService'].includes(activeRecommendation.type)?'Service':'Product'} Details</h3>
                    <div className='product-box flex'>
                    {activeRecommendation.image && <Img className={"mbauto"} src={JSON.parse(activeRecommendation.image)[0]} alt={activeRecommendation.title} style={{ width:'100%', borderRadius: 5, marginRight: 5, maxHeight: 240 }} />}
                        <div className='description pl20'>
                            <h4>{
                                activeRecommendation.title ?
                                <>{
                                    activeRecommendation.title ?
                                    <span>{ activeRecommendation.title}{activeRecommendation.fuelQuantity && activeRecommendation.fuelType?.value && ' ('+activeRecommendation.fuelQuantity+' lt. '+activeRecommendation.fuelType?.value+')'}</span>
                                    : activeRecommendation.value
                                }</>
                                : activeRecommendation.value
                            }</h4>
                            {activeRecommendation.title && <Html className="content-detail pb40" content={activeRecommendation.excerpt} />}
                        </div>
                    </div>
                    <hr />
                    <h3>Car Details</h3>
                    <div className='flex'>
                        {
                            activeRecommendation.carDetails.carData.image_path?
                            <Img source='carCDN' src={activeRecommendation.carDetails.carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                            <p>No Image</p>
                        }
                        <div className='fs12'>
                            {activeRecommendation.carDetails.registrationNo.toUpperCase()}<br />
                            {activeRecommendation.carDetails.carData.name+' '+activeRecommendation.carDetails.carData.Segment}
                        </div>
                    </div>
                    <h3>Recent Car Images</h3>
                    <ul className='car-images'>
                        {
                            activeRecommendation.carImages.map((e, i) => {
                                let img = (typeof e === 'object') ? 'data:image/jpg;base64,'+e.data : e
                                return <Img hasZoom key={i} src={img} alt={activeRecommendation.title} style={{ maxWidth: 120, borderRadius: 5 }} />
                        })
                        }
                    </ul>
                    <hr />
                    <h3>Service Guy Comments</h3>
                    <p>{activeRecommendation.comments}</p>
                </div>}
            </div>
        </div>
    </>
}
