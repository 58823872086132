export const carRoutes = [
    {
        "pathName": "/car/list",
        "module": ["Car"],
        "component": "CarList",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/car/add",
        "module": ["Car"],
        "component": "AddCar",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/car/edit",
        "module": ["Car"],
        "component": "AddCar",
        "type": "postLogin",
        "mode": "dynamic"
    },
    {
        "pathName": "/car/view",
        "module": ["Car"],
        "component": "ViewCar",
        "type": "postLogin",
        "mode": "dynamic"
    }
]