import { useEffect, useState } from "react"
import { useService } from "../../../hooks"

export const useSubscriptionData = ({
    activeSubscription
}) => {
    const {
        generateUID,
        showAlert,
        closeModal,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getCartData,
        addToCart,
    } = useService('Order')
    const [purchaseGoldSubscription, setpurchaseGoldSubscription] = useState(true)
    const [purchaseSilverSubscription, setpurchaseSilverSubscription] = useState(true)
    const [silverInCart, setsilverInCart] = useState(false)
    const [goldInCart, setgoldInCart] = useState(false)

    useEffect(() => {
        let cartData = JSON.stringify(getCartData())
        if (cartData.includes('Silver Subscription')) {
            setpurchaseSilverSubscription(false)
            setsilverInCart(true)
        }
        if (cartData.includes('Gold Subscription')) {
            setpurchaseGoldSubscription(false)
            setgoldInCart(true)
        }
    }, [])

    const purchase = purchaseType => {
        let price = 0,
        id = 0,
        startDate = new Date(),
        endDate = new Date()
        startDate.setDate(new Date().getDate() + 1)
        endDate.setDate(endDate.getDate() + 30)
        if (purchaseType === 'Silver') {
            price = 99
            id = 1
        } else {
            price = 249
            id = 3
        }
        price = String(price)
        let subscription = {
            uid: 'SUBSCRIPTION__00' + id,
            title: purchaseType+ ' Subscription',
            price,
            displayPrice: price,
            purchaseQuantity: 1,
            type: 'subscription',
            tempUid: generateUID(),
            mrp: price,
            saleprice: price,
            tax: '18',
            hsn: '',
            sku: '',
            startDate,
            endDate
        }
        addToCart(subscription)
        showAlert({ type: 'success', msg: purchaseType+ ' Subscription added to cart successfully' })
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[1] === 'service') {
            closeModal("get-subscription-modal")
        }
        if (purchaseType === 'Silver') {
            setpurchaseSilverSubscription(false)
            setsilverInCart(true)
            setpurchaseGoldSubscription(true)
            setgoldInCart(false)
        } else {
            setpurchaseGoldSubscription(false)
            setgoldInCart(true)
            setpurchaseSilverSubscription(true)
            setsilverInCart(false)
        }
    }
    return {
        purchaseGoldSubscription,
        purchaseSilverSubscription,
        silverInCart,
        goldInCart,
        purchase,
    }
}