export const CompanyDataModel = [
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'statusDate',
        dataType: String
    },
    {
        key: 'dataType',
        dataType: String
    },
    {
        key: 'data',
        dataType: Object
    },
    {
        key: 'ownerId',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]