import { useState } from "react"
 
import { createContext } from "react";

export const NavigatorContext = createContext()

export const Navigator = ({ children }) => {
    const [router, setrouter] = useState(null)
    const [activeRoute, setactiveRoute] = useState(window.location.pathname)
    const navigateTo = ({differentOrigin = false, route, external = false, newTab = false, data = null, title = ''}) => {
        if (newTab || external) {
            window.open(route, '_blank')
        } else if(differentOrigin) {
            window.location.replace(route)
        }
        else {
            setactiveRoute(route)
            window.history.pushState(data, title, route)
            window.scrollTo(0, 0)
        }
        // else {
        //     const nextTitle = '';
        //     const nextState = { additionalInformation: 'Updated the URL with JS' };
        
        //     // This will create a new entry in the browser's history, without reloading
        //     window.history.replaceState(nextState, nextTitle, route);
        // }
    }
    const navigator = {
        setactiveRoute,
        activeRoute,
        router,
        setrouter,
        navigateTo,
    }
    return <NavigatorContext.Provider value={navigator}>
        {children}
    </NavigatorContext.Provider>
}