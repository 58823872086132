import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { carStatusOption, inactiveCarOption } from '../../../constant'
import './style.scss'
import { useCarList } from './hooks';
import { useElement, useService } from '../../../hooks';
import { NavigatorContext } from '../../../context';
import { useContext } from 'react';

export const CarList = props => {
    const {isValidJson} = useService('Validation')
    const {navigateTo} = useContext(NavigatorContext)
    const Img = useElement('Img')
    const Badge = useElement('Badge')
    const Link = useElement('Link')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const {
        purchaseMicroService,
        userData,
        keysData,
        activeShownTotal,
        filterItems,
        updateCarStatus,
        trashCar,
        preApprovedMicroServices,
        user,
        activeBranches,
        selectCompany,
        branches,
        status,
        setstatus,
        setshoot360,
        shoot360,
        searchKey,
        search,
        resetSearch,
        filteredList,
        comments,
        setcomments,
        updateCarData,
        carUpdateCancel,
    } = useCarList()
    const animatedComponents = makeAnimated();
    
    const renderPreApprovedMicroServices = (car) => {
        return preApprovedMicroServices.map((e, i) => {
            return <li key={i}>
                <span>{e.title}</span>
                <Button className='btn btn-primary btn-small' onClick={() => purchaseMicroService(e, car)}>Book</Button>
            </li>
        })
    }
    
    const renderCarList = carFiles => {
        return carFiles.slice(0, activeShownTotal).map(( item, index ) => {
            let carQRData = JSON.stringify({
                user: userData.uid,
                car: item.uid
            }), keySubmitted = false
            keysData.forEach(e => {
                if (e.carId === item.uid && e.status === 'collected')
                    keySubmitted = true
            })
            return filterItems(item) && <div className="form-box mt30 mb0" key= {index}>
                <div className="account-detail service-single-item carlistdetail">
                    <div className="qr-code-box"></div>
                    <div className="col-9">
                        <div className="service-content">
                            <div className="title-wrap">
                                {
                                    keySubmitted &&
                                    <i className="key-collect hi-key"></i>
                                }
                                <div className='image-box'>
                                    {item.carData && isValidJson(item.carData) && <Img source='carCDN' className="car-image" src={JSON.parse(item.carData).image_path} alt={item.maker+'('+item.vehicleClass+')'} />}
                                    {item.shoot360Url && item.shoot360Url !== '' && item.shoot360Url !== 'false' &&
                                        <span onClick={() => {
                                            if (item.shoot360Url && item.shoot360Url !== '' && item.shoot360Url !== 'false')
                                                navigateTo({route: item.shoot360Url, newTab: true})
                                        }}><Img className="shoot-icon" src={'360-degree-icon.png'} alt={item.maker+'('+item.vehicleClass+')'} title="360 Shoot Completed" />
                                        </span>
                                    }
                                </div>
                                <div>
                                    <h3 className="title p0">{item.maker}</h3>
                                    <b className='fs18'>{item.registrationNo}</b>
                                </div>
                                {['manager', 'company', 'branch', 'representative'].includes(user?.role) && <div className="ml20 flex-box switch-box">
                                    <div title='Change Car Status' className='pointer mbauto' onClick={() => updateCarStatus(item, typeof item.status === "undefined" || item.status === 'active'?'inactive':'active')}><Badge item={typeof item.status === "undefined" || item.status === 'active' ?'active':'inactive'} /></div>
                                </div>}
                            </div>
                            <div className="item-number" ref={el => (item.qrCode = el)}>
                                <ReactToPrint
                                    trigger={() => <Button className='print-btn'>Print</Button>}
                                    content={() => item.qrCode}
                                />
                                <QRCode value={carQRData} size={100} />
                                <div className="QR-help">
                                    <i className="hi-info1"></i>
                                    <span className="tooltip-qr-help">
                                        This QR code enables our team to fetch your car & package details in order to give you contactless service.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            { ['company', 'representative'].includes(user.role) && <Link className="btn btn-outline btn-box" href={"/car/edit/"+item.uid}>Edit</Link>}
                            <Button className="btn btn-primary btn-box ml20" onClick={() => trashCar(item.uid)}>Remove</Button>
                            {/* <Button className="btn btn-primary btn-box ml20" onClick={() => viewCar(item.uid)}>More</Button> */}
                        </div>
                    </div>
                    {preApprovedMicroServices.length !== 0 && ['manager', 'company', 'branch', 'representative'].includes(user?.role) && <div className='pre-approved-services-box'>
                        <h4>Book Services</h4>
                        <ul className='pre-approved-services'>
                            {renderPreApprovedMicroServices(item)}
                        </ul>
                    </div>}
                </div>
            </div>
        })
    }

    return <>
        <div className="right-side-profile-content">
            <div className='flex overflow-visible'>
                <h1 className="nowrap mr20">My Cars
                    {
                        !['company', 'representative'].includes(user.role) &&
                        <Link className="btn btn-primary btn-small carbtn" href="/car/add">Add</Link>
                    }
                </h1>
                {
                    ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                    <Select
                        isClearable
                        closeMenuOnSelect={false}
                        component={animatedComponents}
                        placeholder='Select Company'
                        defaultValue={activeBranches}
                        onChange={selectCompany}
                        options={branches}
                        isMulti
                    />
                }
            </div>
            <div className="profile-sec wrapper">
                <div className="form-editable-section" id="list-wrapper">
                    <div className="filter-box">
                        <div className="filters relative">
                            <Select
                                value={status}
                                defaultValue={status}
                                onChange={e => setstatus(e)}
                                options={carStatusOption}
                            />
                        </div>
                        <div className='filters mrauto'>
                            <div className="flex-box switch-box">
                                <label className="switch">
                                    <Input type="checkbox" onChange={() => setshoot360(!shoot360)} checked={shoot360?'checked':false} />
                                    <span className="slider round m0"></span>
                                </label>
                                <span><Img className="shoot-icon" src={'360-degree-icon.png'} title="360&deg; Shoot Completed" /> Done</span>
                            </div>
                        </div>
                        <div className="search-box">
                            <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                            <i onClick={resetSearch} className="hi-close"></i>
                        </div>
                    </div>
                    { renderCarList(filteredList || []) }
                </div>
            </div>
        </div>
        <div className="modal" id="confirm-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={carUpdateCancel}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <i className='hi-info1'></i>
                    <span className="store-name">Please specify the reason to inactive car.</span>
                    <Select
                        value={comments}
                        defaultValue={comments}
                        onChange={e => setcomments(e)}
                        options={inactiveCarOption}
                    />
                    <div className="flex btn-box-order mt60">
                        <Button className="btn btn-primary btn-box mb0" onClick={updateCarData}>Save</Button>
                        <Button className="mlauto btn btn-box btn-outline mb0" onClick={carUpdateCancel}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}
