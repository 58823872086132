import { useContext, useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { config, createColumns } from "../../../../constant";
import { useService } from "../../../../hooks";
import { AppDataContext } from "../../../../context";

export const useImportB2BStockData = () => {
    const {
        save,
        update,
    } = useService('Data', 'b2bDailyStockData')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        getFormattedDate,
        generateUID,
    } = useService('Misc')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [file, setfile] = useState({})
    const [data, setdata] = useState([])
    const [cols, setcols] = useState([])
    const [excelWorkList, setexcelWorkList] = useState([])
    const [fileImported, setfileImported] = useState(false)
    const [fileSelected, setfileSelected] = useState(false)
    const [importStockData, setimportStockData] = useState([])
    const [tableData, settableData] = useState([])
    const [showTable, setshowTable] = useState(false)
    const [importDone, setimportDone] = useState(false)
    const [resultWorkList, setresultWorkList] = useState([])
    const [filteredList, setfilteredList] = useState([])
    
    const trackScrolling = () => {
        setTitle('Import Daily B2b Stock Data', 'admin')
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }

    const handleChange = e => {
        const files = e.target.files;
        if (files && files[0]) {
            setfile(files[0])
            setfileSelected(true)
        }
    }
    
    const handleFile = () => {
        showPreloader()
        setshowTable(true)
        let excelWorkList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = e => {
            let bstr = e.target.result,
            wb = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates: true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = utils.sheet_to_json(ws, {raw: false, rawNumbers: true})
            setdata(data)
            setcols(createColumns(ws['!ref']))
            data.forEach(e => {
                if (e['Vehicle No'] !== '') {
                    e['Date'] = String(getFormattedDate(new Date(e['Date'])))
                    e['Yard name'] = String(e['Yard name'])
                    e.ownerId = e['Yard name']
                    e.registrationNo = e['Vehicle no']
                    excelWorkList.push(e)
                }
            })
            setexcelWorkList(excelWorkList)
            setfileImported(true)
            hidePreloader()
        }
        if (rABS) {
            reader.readAsBinaryString(file)
        } else {
            reader.readAsArrayBuffer(file)
        }
    }
    
    const processExcel = () => {
        setshowTable(false)
        let mode = 'add', activeItem = null
        excelWorkList.forEach((e, i) => {
            tableData.forEach(item => {
                if (String(e.uid) === item.uid) {
                    mode = 'edit'
                    activeItem = item;
                }
            })
            setTimeout(() => {
                addStock(e, mode, activeItem, i)
            }, i * 10);
        })
    }
    
    const addStock = async (item, mode, oldCar = null, i) => {
        let success = false
        if(typeof(item.ownerId) === Number) {
            item.ownerId = String(item.ownerId)
        }
        let b2bDailyStockDataObject = item
        try {
            if (mode === 'add') {
                b2bDailyStockDataObject.uid = generateUID()
                const result = await save(b2bDailyStockDataObject)
                if (result.status) {
                    success = true
                    setimportStockData(result)
                }
            } else {
                if(typeof(oldCar.uid) === Number) {
                    oldCar.uid = String(oldCar.uid)
                }
                let data = {
                    query: JSON.stringify({uid: oldCar.uid}),
                    b2bDailyStockDataData: JSON.stringify(b2bDailyStockDataObject)
                }
                const result = await update(data)
                if (result.status) {
                    success = true
                    setimportStockData(result)
                }
            }
            if (excelWorkList.length - 1 === i) {
                setshowTable(false)
                setimportDone(true)
            }
            resultWorkList.push({...item, ...{ success }})
            setresultWorkList(resultWorkList)
        } catch (error) {
            console.log('error', error);
        }
    }
    
    const getTableData = filterData => {
        let data = []
        try {
            filterData.forEach(( item, i ) => {
                let e = {
                    'Sr. No.' : i + 1,
                    'Date': item['Date'],
                    'Yard name': item['Yard name'],
                    'Vehicle no': item['Vehicle no'].toUpperCase(),
                    'Vehicle model': item['Vehicle model'],
                    'Inventory Status': item['Inventory Status']
                }
                data.push(e)
            })
        } catch(e) {
            console.log('error', e)
        }
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'Sr. No.'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Yard name',
                    key: 'Yard name'
                },
                {
                    label: 'Vehicle no',
                    key: 'Vehicle no'
                },
                {
                    label: 'Vehicle model',
                    key: 'Vehicle model'
                },
                {
                    label: 'Inventory Status',
                    key: 'Inventory Status'
                }
            ],
            content: getTableData(excelWorkList)
        })
    }, [excelWorkList])
    return {
        trackScrolling,
        fileSelected,
        fileImported,
        processExcel,
        excelWorkList,
        handleFile,
        handleChange,
        showTable,
        tableContent,
        activeShownTotal,
        importDone,
        resultWorkList,
    }
}