export const addressRoutes = [
    {
        "pathName": "/address/list",
        "module": ["User"],
        "component": "ManageAddress",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/address/add",
        "module": ["User"],
        "component": "AddAddress",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/address/edit",
        "module": ["User"],
        "component": "AddAddress",
        "type": "postLogin",
        "mode": "dynamic"
    }
]