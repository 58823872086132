export class RouterService {
    navigateTo = ({route, newTab = false, data = null, title = ''}) => {
        if (newTab) window.open(route, '_blank');
        else this.getLocationData().href = route
    }
    getLocationData = () => {
        let currentUrl = window.location
        return currentUrl
    }
    URLToArray = (url: string) => {
        let request = {}, pairs = url.substring(url.indexOf('?') + 1).split('&')
        for (var i = 0; i < pairs.length; i++) {
            if (!pairs[i])
            continue
            let pair = pairs[i].split('=')
            // @ts-ignore
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }
        return request
    }
    urlToSplitData = (url: string) => {
        return url.split('/')
    }
    ArrayToURL = (array: Array<any>) => {
      var pairs = []
        for (var key in array)
        if (array.hasOwnProperty(key))
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(array[key]))
        return pairs.join('&')
    }

    cacheClean = () => {
        window.location.reload()
    }
}