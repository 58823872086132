export const SubscriptionModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'slug',
        dataType: String
    },
    {
        key: 'numberOfDays',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'discount',
        dataType: String
    }
]